/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum ActionsType {
  ActivityHistory = 'Activity_History',
  AddNotes = 'Add_Notes',
  AddTimelogs = 'Add_Timelogs',
  ArchiveMembers = 'Archive_Members',
  ArchiveProjects = 'Archive_Projects',
  BillingReport = 'Billing_Report',
  CreateAssignments = 'Create_Assignments',
  CreateClients = 'Create_Clients',
  CreateCompensation = 'Create_Compensation',
  CreateCostRates = 'Create_Cost_Rates',
  CreateExpenses = 'Create_Expenses',
  CreateLeaves = 'Create_Leaves',
  CreateMembers = 'Create_Members',
  CreateOrganizations = 'Create_Organizations',
  CreateProjectRatecards = 'Create_Project_Ratecards',
  CreateProjects = 'Create_Projects',
  CreateScenarios = 'Create_Scenarios',
  CreateUser = 'Create_User',
  CreateVendor = 'Create_Vendor',
  CreateXeroInvoice = 'Create_Xero_Invoice',
  DeactivateAdmins = 'Deactivate_Admins',
  DeactivateUsers = 'Deactivate_Users',
  DeleteAssignments = 'Delete_Assignments',
  DeleteClients = 'Delete_Clients',
  DeleteCompensation = 'Delete_Compensation',
  DeleteCostRates = 'Delete_Cost_Rates',
  DeleteDocuments = 'Delete_Documents',
  DeleteExpenses = 'Delete_Expenses',
  DeleteInvitations = 'Delete_Invitations',
  DeleteLeaves = 'Delete_Leaves',
  DeleteMemberSkills = 'Delete_Member_Skills',
  DeleteNotes = 'Delete_Notes',
  DeleteProjectRatecards = 'Delete_Project_Ratecards',
  DeleteScenarios = 'Delete_Scenarios',
  DeleteTimelogs = 'Delete_Timelogs',
  DeleteVendor = 'Delete_Vendor',
  EditActiveProjects = 'Edit_Active_Projects',
  EditAdmins = 'Edit_Admins',
  EditArchivedMembers = 'Edit_Archived_Members',
  EditArchivedProjects = 'Edit_Archived_Projects',
  EditAssignments = 'Edit_Assignments',
  EditClients = 'Edit_Clients',
  EditCompensation = 'Edit_Compensation',
  EditCostRates = 'Edit_Cost_Rates',
  EditExpenses = 'Edit_Expenses',
  EditLeaves = 'Edit_Leaves',
  EditMemberSkills = 'Edit_Member_Skills',
  EditMembers = 'Edit_Members',
  EditNotes = 'Edit_Notes',
  EditOrganizations = 'Edit_Organizations',
  EditProjectRatecards = 'Edit_Project_Ratecards',
  EditScenarios = 'Edit_Scenarios',
  EditTimelogs = 'Edit_Timelogs',
  EditUsers = 'Edit_Users',
  EditVendor = 'Edit_Vendor',
  ExportClientReport = 'Export_Client_Report',
  ExportDpmReport = 'Export_DPM_Report',
  InviteUsers = 'Invite_Users',
  ManageRequest = 'Manage_Request',
  Notifications = 'Notifications',
  OtherSettings = 'Other_Settings',
  ProjectCommission = 'Project_Commission',
  ProjectMilestones = 'Project_Milestones',
  RefreshInvitations = 'Refresh_Invitations',
  RefreshScenarios = 'Refresh_Scenarios',
  RemoveUser = 'Remove_User',
  Requests = 'Requests',
  RestoreAdmins = 'Restore_Admins',
  RestoreMembers = 'Restore_Members',
  RestoreProjects = 'Restore_Projects',
  RestoreUsers = 'Restore_Users',
  ShareProject = 'Share_Project',
  SubmitAssignmentRequest = 'Submit_Assignment_Request',
  SubmitClientRequest = 'Submit_Client_Request',
  SubmitMemberRequest = 'Submit_Member_Request',
  SubmitMemberSkillRequest = 'Submit_Member_Skill_Request',
  SubmitProjectRequest = 'Submit_Project_Request',
  UploadDocuments = 'Upload_Documents',
  ViewActiveMembers = 'View_Active_Members',
  ViewActiveProjects = 'View_Active_Projects',
  ViewArchivedMembers = 'View_Archived_Members',
  ViewArchivedProjects = 'View_Archived_Projects',
  ViewBenchReport = 'View_Bench_Report',
  ViewClients = 'View_Clients',
  ViewCompensation = 'View_Compensation',
  ViewCostRates = 'View_Cost_Rates',
  ViewDocuments = 'View_Documents',
  ViewDocumentsMenu = 'View_Documents_Menu',
  ViewEmployeePortal = 'View_Employee_Portal',
  ViewExpenses = 'View_Expenses',
  ViewInsightsMenu = 'View_Insights_Menu',
  ViewInsightsUtilization = 'View_Insights_Utilization',
  ViewInvitations = 'View_Invitations',
  ViewLeaves = 'View_Leaves',
  ViewMemberAssignmentCost = 'View_Member_Assignment_Cost',
  ViewMemberAssignmentRevenue = 'View_Member_Assignment_Revenue',
  ViewMemberAssignments = 'View_Member_Assignments',
  ViewNotes = 'View_Notes',
  ViewOrganizations = 'View_Organizations',
  ViewProfitabilityGraph = 'View_Profitability_Graph',
  ViewProjectAssignments = 'View_Project_Assignments',
  ViewProjectCosts = 'View_Project_Costs',
  ViewProjectMembers = 'View_Project_Members',
  ViewProjectMenu = 'View_Project_Menu',
  ViewProjectRatecards = 'View_Project_Ratecards',
  ViewResourcePlanningMembers = 'View_Resource_Planning_Members',
  ViewResourcePlanningMenu = 'View_Resource_Planning_Menu',
  ViewResourcePlanningProjects = 'View_Resource_Planning_Projects',
  ViewRevenue = 'View_Revenue',
  ViewRevenueChart = 'View_Revenue_Chart',
  ViewScenarios = 'View_Scenarios',
  ViewScenariosMenu = 'View_Scenarios_Menu',
  ViewSettingsMenu = 'View_Settings_Menu',
  ViewSkills = 'View_Skills',
  ViewTeamDynamicsGraph = 'View_Team_Dynamics_Graph',
  ViewTeamMenu = 'View_Team_Menu',
  ViewTimelogs = 'View_Timelogs',
  ViewUsers = 'View_Users',
  ViewUtilizationGraph = 'View_Utilization_Graph'
}

export type ActivityHistory = {
  __typename?: 'ActivityHistory';
  action: ActivityHistoryAction;
  changes?: Maybe<Scalars['JSON']>;
  companyId: Scalars['ID'];
  createdByMemberColor?: Maybe<Scalars['String']>;
  createdByMemberName?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  data: Scalars['JSON'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  integration?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['ID']>;
  type: AvailableDomains;
  updated_at: Scalars['DateTime'];
};

export enum ActivityHistoryAction {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type AdvancePayment = {
  __typename?: 'AdvancePayment';
  amount: Scalars['Int'];
  created_at: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyId: Scalars['ID'];
  date: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paidTo: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Assignment = {
  __typename?: 'Assignment';
  allocationTimeAmount: Scalars['Float'];
  bill_amount_calculation_type: AssignmentCalculationType;
  billable: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  member: Member;
  memberId: Scalars['ID'];
  project: Project;
  projectId: Scalars['ID'];
  role: Role;
  seniority?: Maybe<Scalars['String']>;
  seniorityId?: Maybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
  timeTracking: Scalars['Boolean'];
};

export enum AssignmentCalculationType {
  Allocation = 'allocation',
  TimeLogs = 'timeLogs'
}

export type AssignmentMember = {
  __typename?: 'AssignmentMember';
  capacity?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  employment_type: EmploymentType;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  member_leave: Array<Leave>;
  reportingTo?: Maybe<Scalars['ID']>;
};

export enum AvailableDomains {
  Assignment = 'assignment',
  Compensation = 'compensation',
  Contract = 'contract',
  Expense = 'expense',
  ExternalRate = 'external_rate',
  Member = 'member',
  MemberInternalRate = 'member_internal_rate',
  MemberLeave = 'member_leave',
  Project = 'project',
  ProjectCommission = 'project_commission',
  ProjectDocument = 'project_document',
  ProjectMilestone = 'project_milestone'
}

export type AvailableIntegration = {
  __typename?: 'AvailableIntegration';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<IntegrationOption>;
  status: IntegrationStatus;
};

export type Balance = {
  __typename?: 'Balance';
  currency?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type BalanceMember = {
  __typename?: 'BalanceMember';
  accrualMonth?: Maybe<Scalars['String']>;
  balance: Scalars['Float'];
  color: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  limit: Scalars['Float'];
  memberId: Scalars['ID'];
  period?: Maybe<Scalars['String']>;
};

export enum BenchGroupBy {
  Department = 'department',
  None = 'none'
}

export type BenchMember = {
  __typename?: 'BenchMember';
  compensation: Scalars['Float'];
  cost: Scalars['Float'];
  jobTitle?: Maybe<Scalars['String']>;
  memberColor: Scalars['String'];
  memberId: Scalars['ID'];
  memberName?: Maybe<Scalars['String']>;
  nonscheduledDays: Scalars['Float'];
  nonscheduledHours: Scalars['Float'];
  period: Scalars['String'];
  specialization?: Maybe<Scalars['String']>;
  totalCost: Scalars['Float'];
};

export type BenchReport = {
  __typename?: 'BenchReport';
  members: Array<BenchMember>;
  nameOfPeriod: Scalars['String'];
  totals: Array<BenchReportTotal>;
};

export type BenchReportTotal = {
  __typename?: 'BenchReportTotal';
  groupBy: BenchGroupBy;
  groupByValue?: Maybe<Scalars['String']>;
  totalSum: Scalars['Float'];
  totalType: BenchReportType;
};

export enum BenchReportType {
  BenchCost = 'benchCost',
  BenchHours = 'benchHours',
  TeamCount = 'teamCount'
}

export enum BillableDaysNotation {
  Fte = 'fte',
  Hours = 'hours',
  Letter = 'letter'
}

export type BillableLeaveRule = {
  __typename?: 'BillableLeaveRule';
  accrualDate?: Maybe<Scalars['String']>;
  accrualType: BillableLeaveRuleAccrualType;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  leaveTypes: Array<LeaveType>;
  limit: Scalars['Float'];
  projectId: Scalars['ID'];
};

export enum BillableLeaveRuleAccrualType {
  AssignmentStartMonth = 'assignmentStartMonth',
  Monthly = 'monthly'
}

export type BillableLeaveRuleMonthBalance = {
  __typename?: 'BillableLeaveRuleMonthBalance';
  accrualAmount: Scalars['Float'];
  balance: Scalars['Float'];
  billedLeaves: Scalars['Float'];
  month: Scalars['String'];
};

export type BillingRecord = {
  __typename?: 'BillingRecord';
  billed_end_date: Scalars['DateTime'];
  billed_start_date: Scalars['DateTime'];
  billingRecordOther?: Maybe<BillingRecordOther>;
  billing_date?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  project_id: Scalars['ID'];
  status: BillingRecordStatus;
  updated_at: Scalars['DateTime'];
};

export type BillingRecordOther = {
  __typename?: 'BillingRecordOther';
  amount: Scalars['Float'];
  billing_record_id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['ID']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

export enum BillingRecordStatus {
  Approved = 'approved',
  InvoiceCreated = 'invoiceCreated',
  Pending = 'pending'
}

export type Budget = {
  __typename?: 'Budget';
  actual: Scalars['Int'];
  budgetAccesses: Array<BudgetAccess>;
  budgeted: Scalars['Int'];
  categories: Array<BudgetCategories>;
  companyId: Scalars['ID'];
  createdBy: Scalars['ID'];
  created_at: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyId: Scalars['ID'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type BudgetAccess = {
  __typename?: 'BudgetAccess';
  budgetId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type BudgetCategories = {
  __typename?: 'BudgetCategories';
  budgetId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  expenseCategoryId: Scalars['ID'];
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type BudgetPlan = {
  __typename?: 'BudgetPlan';
  amount: Scalars['Int'];
  budget: Budget;
  budgetId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  expenseCategoryId: Scalars['ID'];
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type Client = {
  __typename?: 'Client';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type Comment = {
  __typename?: 'Comment';
  companyId: Scalars['ID'];
  createdByMemberColor: Scalars['String'];
  createdByMemberName: Scalars['String'];
  created_at: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: CommentDomains;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  note: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export enum CommentDomains {
  Report = 'report'
}

export type Company = {
  __typename?: 'Company';
  availableLicenses: Scalars['Int'];
  created_at: Scalars['DateTime'];
  default_cost_rate_unit: RateUnit;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  primaryCurrencyId: Scalars['ID'];
  primary_currency: Scalars['String'];
  totalLicenses: Scalars['Int'];
  updated_at: Scalars['DateTime'];
};

export type CompanyIntegration = {
  __typename?: 'CompanyIntegration';
  companyId: Scalars['ID'];
  connectedBy: Scalars['ID'];
  connectedByMemberColor: Scalars['String'];
  connectedByMemberName: Scalars['String'];
  created_at: Scalars['DateTime'];
  dateFormat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Scalars['String'];
  integrationId: Scalars['ID'];
  integrationOptions: Array<CompanyIntegrationSync>;
  status: IntegrationStatus;
  updated_at: Scalars['DateTime'];
};

export type CompanyIntegrationSync = {
  __typename?: 'CompanyIntegrationSync';
  companyIntegrationId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationOption: Scalars['String'];
  integrationOptionId: Scalars['ID'];
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastSyncStatus?: Maybe<SyncStatus>;
  syncBy?: Maybe<Scalars['ID']>;
  syncByMember?: Maybe<Scalars['String']>;
  syncOption?: Maybe<IntegrationOptionSyncValues>;
  updated_at: Scalars['DateTime'];
};

export type CompanyLite = {
  __typename?: 'CompanyLite';
  created_at: Scalars['DateTime'];
  default_cost_rate_unit: RateUnit;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  primaryCurrencyId: Scalars['ID'];
  totalLicenses: Scalars['Int'];
  updated_at: Scalars['DateTime'];
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  company: CompanyLite;
  createdAt: Scalars['DateTime'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member: Member;
  memberId: Scalars['ID'];
  projectsAccessCount: Scalars['Int'];
  reportingToCount: Scalars['Int'];
  status: CompanyUserStatus;
  type: MemberType;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
};


export type CompanyUserProjectsAccessCountArgs = {
  withAccessCount?: Maybe<Scalars['Boolean']>;
};

export type CompanyUserLite = {
  __typename?: 'CompanyUserLite';
  company: CompanyLite;
  createdAt: Scalars['DateTime'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member: MemberLite;
  memberId: Scalars['ID'];
  reportingToCount: Scalars['Int'];
  status: CompanyUserStatus;
  type: MemberType;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
};

export enum CompanyUserStatus {
  Active = 'active',
  Deactivated = 'deactivated'
}

export type Compensation = {
  __typename?: 'Compensation';
  amount: Scalars['Int'];
  assignmentId?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyId: Scalars['ID'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  type: Scalars['ID'];
  typeName: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type CompensationType = {
  __typename?: 'CompensationType';
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type Contract = {
  __typename?: 'Contract';
  activeAssignments: Scalars['Int'];
  archived_at?: Maybe<Scalars['DateTime']>;
  assignments: Array<Assignment>;
  billableLeaves: Scalars['Boolean'];
  cost_budget_amount?: Maybe<Scalars['Int']>;
  created_at: Scalars['DateTime'];
  currentCommission?: Maybe<Scalars['Float']>;
  end_date: Scalars['DateTime'];
  feeCurrencyId?: Maybe<Scalars['ID']>;
  fee_amount?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  projectId: Scalars['ID'];
  start_date: Scalars['DateTime'];
  type: ProjectType;
  unit: RateUnit;
  updated_at: Scalars['DateTime'];
};


export type ContractAssignmentsArgs = {
  filters?: Maybe<IAssignmentFilters>;
};


export type ContractCurrentCommissionArgs = {
  withCommission: Scalars['Boolean'];
};

export type ContractListData = {
  __typename?: 'ContractListData';
  activeAssignments: Scalars['Int'];
  contractId: Scalars['ID'];
  currentCommission?: Maybe<Scalars['Float']>;
};

export type CostRate = {
  __typename?: 'CostRate';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member_id: Scalars['ID'];
  rateCurrencyId: Scalars['ID'];
  rate_amount: Scalars['Int'];
  rate_currency: Scalars['String'];
  start_date: Scalars['DateTime'];
  unit: RateUnit;
  updated_at: Scalars['DateTime'];
};

export type CronRunHistory = {
  __typename?: 'CronRunHistory';
  companyId?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  runDate: Scalars['DateTime'];
  status: SyncStatus;
  systemCron: Scalars['Boolean'];
  type: CronRunType;
};

export enum CronRunType {
  Emails = 'emails',
  ExchangeRates = 'exchangeRates',
  Integration = 'integration'
}

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


export enum DefaultColumnsName {
  Allocation = 'Allocation',
  BillableLeavesBalance = 'BillableLeavesBalance',
  Name = 'Name',
  Role = 'Role',
  Seniority = 'Seniority'
}

export type Document = {
  __typename?: 'Document';
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  folder?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mimetype?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Int'];
  updated_at: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum EditableMemberType {
  Admin = 'admin',
  Employee = 'employee',
  ResourceManager = 'resourceManager',
  User = 'user'
}

export enum EmploymentType {
  Contractor = 'contractor',
  Employee = 'employee'
}

export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  baseCurrency: Currency;
  baseCurrencyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  exchangeCurrency: Currency;
  exchangeCurrencyId: Scalars['ID'];
  exchangeRate: Scalars['Float'];
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Int'];
  billableAmount?: Maybe<Scalars['Int']>;
  billableCurrency?: Maybe<Scalars['String']>;
  billableCurrencyId?: Maybe<Scalars['ID']>;
  billingRecordId?: Maybe<Scalars['ID']>;
  budget?: Maybe<Budget>;
  budgetId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyId: Scalars['ID'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  member: Member;
  memberId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']>;
  reportId?: Maybe<Scalars['ID']>;
  type: Scalars['ID'];
  typeName: Scalars['String'];
};

export type ExpenseDocument = {
  __typename?: 'ExpenseDocument';
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  document: Document;
  documentId: Scalars['ID'];
  expenseId: Scalars['ID'];
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type ExpenseType = {
  __typename?: 'ExpenseType';
  budgetPlans: Array<BudgetPlan>;
  company_id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export enum ExportTarget {
  File = 'file',
  Google = 'google'
}

export type ExternalIdMap = {
  __typename?: 'ExternalIdMap';
  availableIntegrationId: Scalars['ID'];
  externalId: Scalars['ID'];
  idType: Scalars['String'];
  integrationId: Scalars['ID'];
  internalId: Scalars['ID'];
  recordStatus?: Maybe<Scalars['String']>;
};

export type ExternalIntegration = {
  __typename?: 'ExternalIntegration';
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  token?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTime'];
};

export type ExternalRate = {
  __typename?: 'ExternalRate';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  rate_amount: Scalars['Int'];
  role: Role;
  roleId: Scalars['ID'];
  seniority?: Maybe<Scalars['String']>;
  seniorityId?: Maybe<Scalars['ID']>;
  start_date: Scalars['DateTime'];
  unit: RateUnit;
  updated_at: Scalars['DateTime'];
};

export type FileFolder = {
  __typename?: 'FileFolder';
  companyId: Scalars['ID'];
  createdBy: Scalars['ID'];
  createdByMemberColor?: Maybe<Scalars['String']>;
  createdByMemberName?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parentFolder?: Maybe<Scalars['ID']>;
  projectColor: Scalars['String'];
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type FolderContent = {
  __typename?: 'FolderContent';
  fileFolder: Array<FileFolder>;
  foldersPosition?: Maybe<Array<FolderPosition>>;
  projectDocument: Array<ProjectDocument>;
};

export type FolderPosition = {
  __typename?: 'FolderPosition';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export enum GroupBy {
  Client = 'client',
  Pm = 'pm'
}


export type HistoryFilter = {
  actorId?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  integration?: Maybe<Scalars['Boolean']>;
  memberId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  type?: Maybe<AvailableDomains>;
};

export type Holiday = {
  __typename?: 'Holiday';
  companyId: Scalars['ID'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IAdvancePayment = {
  amount: Scalars['Int'];
  currencyId: Scalars['ID'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  paidTo: Scalars['ID'];
};

export type IAssignment = {
  allocationTimeAmount: Scalars['Int'];
  billable: Scalars['Boolean'];
  calculationType?: Maybe<AssignmentCalculationType>;
  endDate: Scalars['DateTime'];
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  seniorityId?: Maybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
  timeTracking?: Maybe<Scalars['Boolean']>;
};

export type IAssignmentFilters = {
  billable?: Maybe<Scalars['Boolean']>;
  billingType?: Maybe<AssignmentCalculationType>;
  isActive?: Maybe<Scalars['Boolean']>;
  paginationInterval?: Maybe<IPaginationInterval>;
};

export type IAssignmentQuery = {
  contractId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type IBenchReportData = {
  groupBy: BenchGroupBy;
  interval: IPaginationInterval;
  reportType: BenchReportType;
};

export type IBillableLeaveRule = {
  accrualDate?: Maybe<Scalars['String']>;
  accrualType: BillableLeaveRuleAccrualType;
  leaveTypes: Array<Scalars['ID']>;
  limit: Scalars['Float'];
};

export type IBillableLeaveRuleBalance = {
  billableLeaveRuleId?: Maybe<Scalars['ID']>;
  interval?: Maybe<IPaginationDashboard>;
  projectId: Scalars['ID'];
};

export type IBillableLeaveRuleMonthBalance = {
  billableLeaveRuleId: Scalars['ID'];
  interval: IPaginationInterval;
};

export type IBillingFilters = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type IBillingRecord = {
  amount: Scalars['Int'];
  billed_end_date: Scalars['DateTime'];
  billed_start_date: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  project_id: Scalars['ID'];
  status?: Maybe<BillingRecordStatus>;
};

export type IBudget = {
  currencyId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type IBudgetAccess = {
  budgetId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type IBudgetPlan = {
  amount: Scalars['Int'];
  budgetId: Scalars['ID'];
  expenseCategoryId: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  name: Scalars['String'];
};

export type IClient = {
  name: Scalars['String'];
};

export type IColumnOrder = {
  column: Scalars['String'];
  orderingIndex: Scalars['Int'];
};

export type ICompany = {
  default_cost_rate_unit?: Maybe<RateUnit>;
  name: Scalars['String'];
  primaryCurrencyId: Scalars['ID'];
  totalLicenses: Scalars['Int'];
};

export type ICompanyIntegration = {
  code: Scalars['String'];
  dateFormat?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  integrationId: Scalars['ID'];
  integrationOptions: Array<IIntegrationSyncOption>;
};

export type ICompensation = {
  amount: Scalars['Int'];
  assignmentId?: Maybe<Scalars['ID']>;
  currencyId: Scalars['ID'];
  date: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  type: Scalars['ID'];
};

export type ICompensationType = {
  name: Scalars['String'];
};

export type IContract = {
  billableLeaves?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
  costBudgetAmount?: Maybe<Scalars['Int']>;
  end_date: Scalars['DateTime'];
  feeCurrencyId?: Maybe<Scalars['ID']>;
  fee_amount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  start_date: Scalars['DateTime'];
  type: ProjectType;
  unit: RateUnit;
};

export type ICostRate = {
  end_date?: Maybe<Scalars['DateTime']>;
  rateCurrencyId: Scalars['ID'];
  rate_amount: Scalars['Int'];
  start_date: Scalars['DateTime'];
  unit: RateUnit;
};

export type ICreateBudgetCategories = {
  budgetId: Scalars['ID'];
  expenseCategoryIds: Array<Scalars['ID']>;
};

export type ICreateComment = {
  entityId: Scalars['String'];
  entityType: CommentDomains;
  note: Scalars['String'];
};

export type ICreateExpenseDocument = {
  expenseId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type ICreateProjectDocument = {
  file: Scalars['Upload'];
  folder?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type ICreateRequest = {
  changes?: Maybe<Scalars['JSON']>;
  data: Scalars['JSON'];
  note?: Maybe<Scalars['String']>;
};

export type ICreateScenario = {
  allocatedLeaves?: Maybe<Scalars['Int']>;
  bookedLeaves?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  fetchAssignments: Scalars['Boolean'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
};

export type ICreateScenarioMemberEntity = {
  allocationPerCent: Scalars['Float'];
  endDate: Scalars['String'];
  leavesPercent: Scalars['Float'];
  scenarioMemberId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type ICreateScenarioMembers = {
  allocationTimeAmount: Scalars['Float'];
  endDate: Scalars['DateTime'];
  externalRateAmount: Scalars['Float'];
  externalRateUnit: RateUnit;
  internalRateAmount?: Maybe<Scalars['Float']>;
  internalRateUnit?: Maybe<RateUnit>;
  memberId?: Maybe<Scalars['ID']>;
  roleId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type ICreateSelfAssessment = {
  skillCategories: Array<Scalars['ID']>;
  specializationId: Scalars['ID'];
};

export type ICreateSelfAssessmentProject = {
  project: Scalars['String'];
  responsibilities: Scalars['String'];
  role: Scalars['String'];
  selfAssessmentId: Scalars['ID'];
  techStack: Scalars['String'];
};

export type ICreateUser = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  type: MemberType;
};

export type IEditAssignments = {
  allocationTimeAmount?: Maybe<Scalars['Int']>;
  billable?: Maybe<Scalars['Boolean']>;
  calculationType?: Maybe<AssignmentCalculationType>;
  endDate?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['ID']>;
  seniorityId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
};

export type IEditExternalRateSeniority = {
  newSeniorityId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  seniorityId?: Maybe<Scalars['ID']>;
};

export type IEditMemberSkill = {
  level?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

export type IEditProjectMembership = {
  accessLevel?: Maybe<ProjectAccess>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type IEditReport = {
  notes?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['String']>;
  status?: Maybe<ReportStatusType>;
  title?: Maybe<Scalars['String']>;
};

export type IEditScenario = {
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type IEditScenarioMembers = {
  roleId: Scalars['ID'];
};

export type IEditSelfAssessment = {
  skillCategories: Array<Scalars['ID']>;
};

export type IEditSelfAssessmentProject = {
  project: Scalars['String'];
  responsibilities: Scalars['String'];
  role: Scalars['String'];
  techStack: Scalars['String'];
};

export type IExpense = {
  amount: Scalars['Int'];
  billableAmount?: Maybe<Scalars['Int']>;
  billableCurrencyId?: Maybe<Scalars['ID']>;
  billingRecordId?: Maybe<Scalars['ID']>;
  budgetId?: Maybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  isBillable: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  type: Scalars['ID'];
};

export type IExpenseType = {
  name: Scalars['String'];
};

export type IExportProject = {
  end: Scalars['String'];
  start?: Maybe<Scalars['String']>;
};

export type IExternalRate = {
  end_date?: Maybe<Scalars['String']>;
  rate_amount: Scalars['Int'];
  start_date: Scalars['String'];
  unit: RateUnit;
};

export type IFileFolder = {
  name: Scalars['String'];
  rootFolder?: Maybe<Scalars['ID']>;
};

export type IFilterContractData = {
  externalRatesAccess?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<ProjectType>>;
};

export type IFilterData = {
  employmentType?: Maybe<EmploymentType>;
  project?: Maybe<Array<Scalars['ID']>>;
  reportingTo?: Maybe<Array<Scalars['ID']>>;
  seniority?: Maybe<Array<Scalars['ID']>>;
  specialization?: Maybe<Array<Scalars['ID']>>;
};

export type IFilterProjectData = {
  client?: Maybe<Array<Scalars['ID']>>;
  externalRatesAccess?: Maybe<Scalars['Boolean']>;
  pm?: Maybe<Array<Scalars['ID']>>;
  type?: Maybe<Array<ProjectType>>;
};

export type IFilterResourcePlanningMembers = {
  employmentType?: Maybe<Array<EmploymentType>>;
  seniority?: Maybe<Array<Scalars['ID']>>;
  skill?: Maybe<Array<Scalars['ID']>>;
  specialization?: Maybe<Array<Scalars['ID']>>;
};

export type IForecastSettings = {
  allocatedLeaves?: Maybe<Scalars['Int']>;
  bookedLeaves?: Maybe<Scalars['Boolean']>;
  commission?: Maybe<Scalars['Float']>;
};

export type IHoliday = {
  date: Scalars['DateTime'];
  name: Scalars['String'];
};

export type IImportData = {
  file: Scalars['Upload'];
  type: ImportType;
};

export type IInsightColumnOrder = {
  columnsOrder: Array<IColumnOrder>;
  reportType: ReportType;
};

export type IIntegrationSyncOption = {
  integrationOptionId: Scalars['ID'];
  syncOption?: Maybe<Scalars['ID']>;
};

export type ILeave = {
  endDate: Scalars['DateTime'];
  leaveTypeId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type ILeaveType = {
  name: Scalars['String'];
  paid: Scalars['Boolean'];
};

export type ILeaveTypesDisplay = {
  color?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  types: Array<IReportLeaveTypes>;
};

export type IMember = {
  capacity?: Maybe<Scalars['Float']>;
  costRateManagers?: Maybe<Array<Scalars['ID']>>;
  email: Scalars['String'];
  employment_type: EmploymentType;
  exit_date?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  job_title?: Maybe<Scalars['String']>;
  join_date?: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  member_role?: Maybe<Array<Scalars['ID']>>;
  reportingTo?: Maybe<Scalars['ID']>;
  seniority_id?: Maybe<Scalars['ID']>;
  specialization_id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type IMemberLeaveRuleMonthBalance = {
  accrualDate: Scalars['String'];
  balance: Scalars['Float'];
  billableLeaveRuleId: Scalars['ID'];
  memberId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type IMemberProjectMembership = {
  endDate?: Maybe<Scalars['String']>;
  projectsMembership: Array<IProjectMemberAccess>;
  startDate?: Maybe<Scalars['String']>;
};

export type IMemberSeniority = {
  name: Scalars['String'];
};

export type IMemberSkill = {
  member_id: Scalars['ID'];
  skillIds: Array<Scalars['ID']>;
};

export type IMembersPm = {
  membersId: Array<Scalars['ID']>;
  pmId: Scalars['ID'];
};

export type IPagination = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type IPaginationAssignment = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type IPaginationBudget = {
  ongoing?: Maybe<Scalars['DateTime']>;
  past?: Maybe<Scalars['DateTime']>;
};

export type IPaginationDashboard = {
  end: Scalars['String'];
  start?: Maybe<Scalars['String']>;
};

export type IPaginationInterval = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export type IPaginationTimeLogs = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export type IPayroll = {
  end: Scalars['DateTime'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export type IPayrollEntity = {
  net_pay: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  processed: Scalars['Boolean'];
};

export type IPayrollEntityAddition = {
  amount: Scalars['Int'];
  component: Scalars['String'];
  currencyId: Scalars['ID'];
  payrollEntityId: Scalars['ID'];
  recurring: Scalars['Boolean'];
  type: PayrollEntityType;
};

export type IProject = {
  billableLeaves?: Maybe<Scalars['Boolean']>;
  client: Scalars['ID'];
  color?: Maybe<Scalars['HexColorCode']>;
  commission?: Maybe<Scalars['Float']>;
  costBudgetAmount?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['DateTime']>;
  feeCurrencyId?: Maybe<Scalars['ID']>;
  fee_amount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  pm: Scalars['ID'];
  pmAccessEnd?: Maybe<Scalars['DateTime']>;
  pmAccessStart?: Maybe<Scalars['DateTime']>;
  pmFinAccess: ProjectAccess;
  projectFinancialManagersIds?: Maybe<Array<Scalars['ID']>>;
  start_date?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ProjectType>;
  unit?: Maybe<RateUnit>;
};

export type IProjectCommission = {
  commission: Scalars['Float'];
  effectiveFrom: Scalars['String'];
};

export type IProjectDocumentFilters = {
  filterByProject?: Maybe<Array<Scalars['ID']>>;
  filterByTag?: Maybe<Array<Scalars['ID']>>;
  orderDesc?: Maybe<SortOrder>;
};

export type IProjectDocumentTags = {
  projectDocumentId: Scalars['ID'];
  tags: Array<Scalars['ID']>;
};

export type IProjectMemberAccess = {
  accessLevel: ProjectAccess;
  projectId: Scalars['ID'];
};

export type IProjectMembership = {
  accessLevel: ProjectAccess;
  endDate?: Maybe<Scalars['String']>;
  membersId: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
  startDate?: Maybe<Scalars['String']>;
};

export type IProjectMilestone = {
  amount: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  paymentDate: Scalars['String'];
  title: Scalars['String'];
};

export type IProjectNote = {
  notes: Scalars['String'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
};

export type IProjectRoleOrder = {
  projectId: Scalars['ID'];
  rolesOrder: Array<IRoleOrder>;
};

export type IQueryExpenses = {
  budgetId?: Maybe<Scalars['ID']>;
  interval?: Maybe<IPaginationInterval>;
  isBillable?: Maybe<Scalars['Boolean']>;
  onlyRecentTen?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type IQueryMembership = {
  memberId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type IQueryTimeLogs = {
  assignmentId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type IRemoveBudgetCategories = {
  budgetId: Scalars['ID'];
  expenseCategoryId: Scalars['ID'];
};

export type IReport = {
  end: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  projectType: ProjectType;
  selectedItems: Array<Scalars['ID']>;
  start: Scalars['String'];
  title: Scalars['String'];
};

export type IReportBillableDays = {
  excludedDays: Scalars['JSON'];
  reportMemberId: Scalars['ID'];
};

export type IReportColumns = {
  customName?: Maybe<Scalars['String']>;
  defaultName: DefaultColumnsName;
  order: Scalars['Int'];
};

export type IReportCustomization = {
  billableDaysNotation: BillableDaysNotation;
  leaveTypesDisplay?: Maybe<Array<ILeaveTypesDisplay>>;
  leavesNotation: LeavesNotation;
  reportColumns: Array<IReportColumns>;
};

export type IReportLeaveTypes = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IReportMemberLogs = {
  includedLogs: Array<Scalars['ID']>;
};

export type IRequest = {
  note?: Maybe<Scalars['String']>;
  status: RequestStatusType;
};

export type IRole = {
  name: Scalars['String'];
};

export type IRoleOrder = {
  orderingIndex: Scalars['Int'];
  roleId: Scalars['ID'];
};

export type IScenarioMembersEntity = {
  allocationPerCent?: Maybe<Scalars['Float']>;
  externalRateAmount?: Maybe<Scalars['Float']>;
  externalRateUnit?: Maybe<RateUnit>;
  internalRateAmount?: Maybe<Scalars['Float']>;
  internalRateUnit?: Maybe<RateUnit>;
  leavesPercent?: Maybe<Scalars['Float']>;
  nextMonths: Scalars['Boolean'];
};

export type ISelfAssessmentSkill = {
  assessment: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  selfAssessmentId: Scalars['ID'];
  skillId: Scalars['ID'];
};

export type ISkill = {
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
};

export type ISkillCategory = {
  name: Scalars['String'];
};

export type ISorting = {
  key: SortBy;
  orderDesc: SortOrder;
};

export type ISpecialization = {
  name: Scalars['String'];
};

export type ISpecializationSkill = {
  skillCategoryIds: Array<Scalars['ID']>;
  specializationId: Scalars['ID'];
};

export type ITag = {
  color: Scalars['HexColorCode'];
  name: Scalars['String'];
};

export type ITimeLog = {
  assignment_id: Scalars['ID'];
  date: Scalars['String'];
  minutes: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  overtime?: Maybe<Scalars['Boolean']>;
  overtimeMultiplier?: Maybe<Scalars['Float']>;
};

export type ITimeLogPeriod = {
  assignment_id: Scalars['ID'];
  endDate: Scalars['String'];
  nonWorkingDays: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  overtime?: Maybe<Scalars['Boolean']>;
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  perDay?: Maybe<Scalars['Int']>;
  startDate: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

export type IVendor = {
  name: Scalars['String'];
};

export type ImportData = {
  __typename?: 'ImportData';
  file?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ImportFilter = {
  companyId: Scalars['ID'];
  type?: Maybe<ImportType>;
};

export type ImportHistory = {
  __typename?: 'ImportHistory';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  records: Array<ImportRecord>;
  recordsCount: Scalars['Int'];
  type: ImportType;
};

export type ImportRecord = Assignment | Member | Project;

export enum ImportType {
  Assignment = 'assignment',
  Member = 'member',
  Project = 'project'
}

export type InsightColumnOrder = {
  __typename?: 'InsightColumnOrder';
  column: Scalars['String'];
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  orderingIndex: Scalars['Int'];
  reportType: ReportType;
  updated_at: Scalars['DateTime'];
};

export type InsightFilters = {
  employmentType?: Maybe<EmploymentType>;
  excludeMembers?: Maybe<Array<Scalars['ID']>>;
  seniority?: Maybe<Array<Scalars['ID']>>;
  specialization?: Maybe<Array<Scalars['ID']>>;
};

export type IntegrationOption = {
  __typename?: 'IntegrationOption';
  availableIntegrationId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  syncOptions: Array<IntegrationOptionSyncValues>;
};

export type IntegrationOptionSyncValues = {
  __typename?: 'IntegrationOptionSyncValues';
  id: Scalars['ID'];
  integrationOptionId: Scalars['ID'];
  name: Scalars['String'];
};

export enum IntegrationStatus {
  Connected = 'connected',
  Disconnected = 'disconnected'
}

export type Invitation = {
  __typename?: 'Invitation';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  status: MemberInvitationStatus;
  updated_at: Scalars['DateTime'];
};


export type Leave = {
  __typename?: 'Leave';
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  leaveType?: Maybe<LeaveType>;
  memberFirstName?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  memberLastName?: Maybe<Scalars['String']>;
  notes: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type LeaveType = {
  __typename?: 'LeaveType';
  color: Scalars['String'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  paid: Scalars['Boolean'];
};

export enum LeavesNotation {
  Combine = 'combine',
  Differentiate = 'differentiate'
}

export type LoginResult = {
  __typename?: 'LoginResult';
  me: Array<CompanyUserLite>;
  token: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  advancePayments: Array<AdvancePayment>;
  archived_at?: Maybe<Scalars['DateTime']>;
  assignments: Array<Assignment>;
  avatar_url?: Maybe<Scalars['String']>;
  balance: Array<Balance>;
  capacity?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['HexColorCode']>;
  company: CompanyLite;
  companyUserId?: Maybe<Scalars['ID']>;
  costRateManagers: Array<Member>;
  costRateManagersIds: Array<Scalars['ID']>;
  cost_rate?: Maybe<Array<CostRate>>;
  created_at: Scalars['DateTime'];
  email: Scalars['String'];
  employment_type: EmploymentType;
  exit_date?: Maybe<Scalars['DateTime']>;
  expenses: Array<Expense>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_pending: Scalars['Boolean'];
  job_title?: Maybe<Scalars['String']>;
  join_date?: Maybe<Scalars['DateTime']>;
  last_name?: Maybe<Scalars['String']>;
  leaves: Array<Leave>;
  memberSpecialization?: Maybe<Scalars['String']>;
  memberSpecializationId?: Maybe<Scalars['ID']>;
  member_role: Array<Role>;
  member_seniority?: Maybe<MemberSeniority>;
  reportingTo?: Maybe<MemberLite>;
  reportingToId?: Maybe<Scalars['ID']>;
  skills: Array<MemberSkill>;
  status: MemberStatus;
  updated_at: Scalars['DateTime'];
  vendor?: Maybe<Vendor>;
};


export type MemberAssignmentsArgs = {
  filters?: Maybe<IAssignmentFilters>;
};

export type MemberBalanceAccrualMonth = {
  __typename?: 'MemberBalanceAccrualMonth';
  accrualDate: Scalars['DateTime'];
  billableLeaveRuleId: Scalars['ID'];
  createdBy: Scalars['ID'];
  created_at: Scalars['DateTime'];
  editedBy?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type MemberIntegrationStatus = {
  __typename?: 'MemberIntegrationStatus';
  integrationName: Scalars['String'];
  status: MemberStatus;
};

export type MemberIntegrationStatuses = {
  __typename?: 'MemberIntegrationStatuses';
  memberId: Scalars['ID'];
  statuses: Array<MemberIntegrationStatus>;
};

export enum MemberInvitationStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Created = 'created'
}

export type MemberLeaveRuleAccrualAmount = {
  __typename?: 'MemberLeaveRuleAccrualAmount';
  accrualAmount: Scalars['Float'];
  accrualDate: Scalars['DateTime'];
  billableLeaveRuleId: Scalars['ID'];
  createdBy: Scalars['ID'];
  created_at: Scalars['DateTime'];
  editedBy?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

export type MemberListData = {
  __typename?: 'MemberListData';
  activeAssignments: Scalars['Int'];
  currentSalary?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
};

export type MemberLite = {
  __typename?: 'MemberLite';
  archived_at?: Maybe<Scalars['DateTime']>;
  avatar_url?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['HexColorCode']>;
  company: CompanyLite;
  companyUserId?: Maybe<Scalars['ID']>;
  costRateManagersIds: Array<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  email: Scalars['String'];
  employment_type: EmploymentType;
  exit_date?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_pending: Scalars['Boolean'];
  job_title?: Maybe<Scalars['String']>;
  join_date?: Maybe<Scalars['DateTime']>;
  last_name?: Maybe<Scalars['String']>;
  reportingToId?: Maybe<Scalars['ID']>;
  status: MemberStatus;
  updated_at: Scalars['DateTime'];
};

export type MemberProjectDistribution = {
  __typename?: 'MemberProjectDistribution';
  percentage: Scalars['Float'];
  projectColor: Scalars['String'];
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  totalHours: Scalars['Float'];
};

export type MemberSeniority = {
  __typename?: 'MemberSeniority';
  company_id: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type MemberSkill = {
  __typename?: 'MemberSkill';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
  member_id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  skill: Skill;
  skill_id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export enum MemberStatus {
  Active = 'active',
  Archived = 'archived',
  Disabled = 'disabled',
  NoAccount = 'no_account'
}

export enum MemberType {
  Admin = 'admin',
  Employee = 'employee',
  None = 'none',
  Owner = 'owner',
  ResourceManager = 'resourceManager',
  SuperAdmin = 'superAdmin',
  User = 'user'
}

export enum MembersHoursReportTabs {
  Expenses = 'Expenses',
  Hours = 'Hours',
  Margins = 'Margins'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: LoginResult;
  archiveContract: Contract;
  archiveMember: Member;
  archiveProject: Project;
  archiveScenario: Scenario;
  changeInsightColumnOrder: Array<InsightColumnOrder>;
  changePasswordAuthenticated: Array<CompanyUser>;
  changePasswordFromEmail: LoginResult;
  changeProjectRoleOrder: Array<ProjectRoleOrder>;
  createAdvancePayment: AdvancePayment;
  createAssignment: Assignment;
  createBillableLeaveRule: BillableLeaveRule;
  createBillingRecord: BillingRecord;
  createBudget: Budget;
  createBudgetAccess: BudgetAccess;
  createBudgetCategories: Array<BudgetCategories>;
  createBudgetPlan: BudgetPlan;
  createClient: Client;
  createComment: Comment;
  createCompany: Company;
  createCompensation: Compensation;
  createCompensationType: CompensationType;
  createContract: Contract;
  createCostRate: CostRate;
  createExpense: Expense;
  createExpenseDocument: ExpenseDocument;
  createExpenseType: ExpenseType;
  createExternalRate: ExternalRate;
  createFileFolder: FileFolder;
  createHoliday: Holiday;
  createIntegrationConnection: CompanyIntegration;
  createLeave: Leave;
  createLeaveType: LeaveType;
  createMember: Member;
  createMemberProjectsMembership: Array<ProjectMembership>;
  createPayroll: Payroll;
  createPayrollEntity: PayrollEntity;
  createPayrollEntityAddition: PayrollEntityAddition;
  createProject: Project;
  createProjectCommission: ProjectCommission;
  createProjectDocument: ProjectDocument;
  createProjectMembership: Array<ProjectMembership>;
  createProjectMilestone: ProjectMilestone;
  createProjectNote: ProjectNote;
  createReport: Report;
  createRequest: Request;
  createRole: Role;
  createScenario: Scenario;
  createScenarioMemberEntity: ScenarioMembersEntity;
  createScenarioMembers: ScenarioMembers;
  createSelfAssessment: Array<SelfAssessmentSkill>;
  createSelfAssessmentProject: SelfAssessmentProject;
  createSeniority: MemberSeniority;
  createSkill: Skill;
  createSkillCategory: SkillCategory;
  createSpecialization: Specialization;
  createSpecializationSkill: Array<SpecializationSkill>;
  createTag: Tag;
  createTimeLog: TimeLog;
  createTimeLogPeriod: Array<TimeLog>;
  createUser: CompanyUser;
  createVendor: Vendor;
  deactivateUser: CompanyUser;
  deleteAdvancePayment: AdvancePayment;
  deleteAssignment: Array<Assignment>;
  deleteBillableLeaveRule: BillableLeaveRule;
  deleteBillingRecord: BillingRecord;
  deleteBudget: Budget;
  deleteBudgetAccess: BudgetAccess;
  deleteBudgetCategories: BudgetCategories;
  deleteBudgetPlan: BudgetPlan;
  deleteClient: Client;
  deleteComment: Comment;
  deleteCompanyIntegration: CompanyIntegration;
  deleteCompensation: Compensation;
  deleteCompensationType: CompensationType;
  deleteCostRate: CostRate;
  deleteExpense: Expense;
  deleteExpenseDocument: ExpenseDocument;
  deleteExpenseType: ExpenseType;
  deleteExternalRate: ExternalRate;
  deleteFileFolder: FileFolder;
  deleteHoliday: Holiday;
  deleteInvitation: Invitation;
  deleteLeave: Leave;
  deleteLeaveType: LeaveType;
  deleteMemberBalanceAccrualMonth: MemberBalanceAccrualMonth;
  deleteMemberSkill: MemberSkill;
  deletePayroll: Payroll;
  deletePayrollEntity: PayrollEntity;
  deletePayrollEntityAddition: PayrollEntityAddition;
  deleteProjectCommission: ProjectCommission;
  deleteProjectDocument: ProjectDocument;
  deleteProjectMembership: ProjectMembership;
  deleteProjectMilestone: ProjectMilestone;
  deleteProjectNote: ProjectNote;
  deleteReport: Report;
  deleteRequests: Array<Request>;
  deleteRole: Role;
  deleteScenario: Scenario;
  deleteScenarioMembers: ScenarioMembers;
  deleteSelfAssessmentProject: SelfAssessmentProject;
  deleteSelfAssessmentSkill: SelfAssessmentSkill;
  deleteSeniority: MemberSeniority;
  deleteSkill: Skill;
  deleteSkillCategory: SkillCategory;
  deleteSpecialization: Specialization;
  deleteSpecializationSkill: Array<SpecializationSkill>;
  deleteTag: Tag;
  deleteTimeLog: Array<TimeLog>;
  deleteUser: CompanyUser;
  deleteVendor: Vendor;
  editAdvancePayment: AdvancePayment;
  editAssignment: Assignment;
  editAssignments: Array<Assignment>;
  editBillableLeaveRule: BillableLeaveRule;
  editBillingRecord: BillingRecord;
  editBudget: Budget;
  editBudgetPlan: BudgetPlan;
  editClient: Client;
  editComment: Comment;
  editCompany: Company;
  editCompanyIntegrationOptions: Array<CompanyIntegrationSync>;
  editCompanyIntegrationStatus: CompanyIntegration;
  editCompensation: Compensation;
  editCompensationType: CompensationType;
  editContract: Contract;
  editCostRate: CostRate;
  editExpense: Expense;
  editExpenseType: ExpenseType;
  editExternalRate: ExternalRate;
  editExternalRateSeniority: Array<ExternalRate>;
  editFileFolder: FileFolder;
  editHoliday: Holiday;
  editLeave: Leave;
  editLeaveType: LeaveType;
  editMember: CompanyUser;
  editMemberLeaveRuleMonthBalance: MemberLeaveRuleAccrualAmount;
  editMemberSkill: MemberSkill;
  editMemberSkills: Array<MemberSkill>;
  editPayrollEntity: PayrollEntity;
  editPayrollEntityAddition: PayrollEntityAddition;
  editPayrollStatus: Payroll;
  editProject: Project;
  editProjectCommission: ProjectCommission;
  editProjectDocumentTags: Array<ProjectDocumentTags>;
  editProjectMembership: ProjectMembership;
  editProjectMilestone: ProjectMilestone;
  editProjectNote: ProjectNote;
  editProjectPMField: Array<Project>;
  editReport: Report;
  editReportBillableDays: Array<ReportMember>;
  editReportMemberLogs: ReportMember;
  editReportMembers: Array<ReportMember>;
  editRequest: Request;
  editRequestsStatus: Array<Request>;
  editRole: Role;
  editScenario: Scenario;
  editScenarioMembers: ScenarioMembers;
  editScenarioMembersEntity: Array<ScenarioMembersEntity>;
  editSelfAssessment: Array<SelfAssessmentSkill>;
  editSelfAssessmentProject: SelfAssessmentProject;
  editSelfAssessmentSkill: SelfAssessmentSkill;
  editSeniority: MemberSeniority;
  editSkill: Skill;
  editSkillCategory: SkillCategory;
  editSpecialization: Specialization;
  editTag: Tag;
  editTeamMember: Member;
  editTeamMembersPM: Array<Member>;
  editTimeLog: TimeLog;
  editUser: CompanyUser;
  editVendor: Vendor;
  excludeReportMembers: ReportCustomization;
  importData: ImportData;
  invite: Invitation;
  login: LoginResult;
  manualCompanyIntegrationSync: Array<CompanyIntegrationSync>;
  moveFileBetweenFolders: Document;
  recalculatePayrollEntity: PayrollEntity;
  refresh: LoginResult;
  refreshInvitation: Invitation;
  refreshScenarioData: Scenario;
  refreshScenarioExchangeRate: Scenario;
  resetPassword: Scalars['Boolean'];
  restoreContract: Contract;
  restoreMember: Member;
  restoreProject: Project;
  restoreUser: CompanyUser;
  scenarioForecast: Scenario;
  setMemberBalanceAccrualMonth: MemberBalanceAccrualMonth;
  setReportCustomization: ReportCustomization;
  setUpReportExpenses: Array<Expense>;
  setUpReportMilestones: Array<ProjectMilestone>;
  uploadFeedbackFile: Scalars['String'];
};


export type MutationAcceptInvitationArgs = {
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  secret: Scalars['String'];
};


export type MutationArchiveContractArgs = {
  companyId: Scalars['ID'];
  contractId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationArchiveMemberArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationArchiveProjectArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationArchiveScenarioArgs = {
  action: ScenarioAction;
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationChangeInsightColumnOrderArgs = {
  companyId: Scalars['ID'];
  data: IInsightColumnOrder;
};


export type MutationChangePasswordAuthenticatedArgs = {
  password: Scalars['String'];
};


export type MutationChangePasswordFromEmailArgs = {
  password: Scalars['String'];
  resetId: Scalars['ID'];
  secret: Scalars['String'];
};


export type MutationChangeProjectRoleOrderArgs = {
  companyId: Scalars['ID'];
  data: IProjectRoleOrder;
};


export type MutationCreateAdvancePaymentArgs = {
  companyId: Scalars['ID'];
  data: IAdvancePayment;
};


export type MutationCreateAssignmentArgs = {
  companyId: Scalars['ID'];
  data: IAssignment;
};


export type MutationCreateBillableLeaveRuleArgs = {
  companyId: Scalars['ID'];
  data: IBillableLeaveRule;
  projectId: Scalars['ID'];
};


export type MutationCreateBillingRecordArgs = {
  companyId: Scalars['ID'];
  data: IBillingRecord;
};


export type MutationCreateBudgetArgs = {
  companyId: Scalars['ID'];
  data: IBudget;
};


export type MutationCreateBudgetAccessArgs = {
  companyId: Scalars['ID'];
  data: IBudgetAccess;
};


export type MutationCreateBudgetCategoriesArgs = {
  companyId: Scalars['ID'];
  data: ICreateBudgetCategories;
};


export type MutationCreateBudgetPlanArgs = {
  companyId: Scalars['ID'];
  data: IBudgetPlan;
};


export type MutationCreateClientArgs = {
  companyId: Scalars['ID'];
  data: IClient;
};


export type MutationCreateCommentArgs = {
  companyId: Scalars['ID'];
  data: ICreateComment;
};


export type MutationCreateCompanyArgs = {
  companyId: Scalars['ID'];
  data: ICompany;
  user?: Maybe<ICreateUser>;
};


export type MutationCreateCompensationArgs = {
  companyId: Scalars['ID'];
  data: ICompensation;
  memberId: Scalars['ID'];
};


export type MutationCreateCompensationTypeArgs = {
  companyId: Scalars['ID'];
  data: ICompensationType;
};


export type MutationCreateContractArgs = {
  companyId: Scalars['ID'];
  data: IContract;
  projectId: Scalars['ID'];
};


export type MutationCreateCostRateArgs = {
  companyId: Scalars['ID'];
  data: ICostRate;
  memberId: Scalars['ID'];
};


export type MutationCreateExpenseArgs = {
  companyId: Scalars['ID'];
  data: IExpense;
};


export type MutationCreateExpenseDocumentArgs = {
  companyId: Scalars['ID'];
  data: ICreateExpenseDocument;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateExpenseTypeArgs = {
  companyId: Scalars['ID'];
  data: IExpenseType;
};


export type MutationCreateExternalRateArgs = {
  companyId: Scalars['ID'];
  data: IExternalRate;
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  seniorityId?: Maybe<Scalars['ID']>;
};


export type MutationCreateFileFolderArgs = {
  companyId: Scalars['ID'];
  data: IFileFolder;
  projectId: Scalars['ID'];
};


export type MutationCreateHolidayArgs = {
  companyId: Scalars['ID'];
  data: IHoliday;
};


export type MutationCreateIntegrationConnectionArgs = {
  companyId: Scalars['ID'];
  data: ICompanyIntegration;
};


export type MutationCreateLeaveArgs = {
  companyId: Scalars['ID'];
  data: ILeave;
  memberId: Scalars['ID'];
};


export type MutationCreateLeaveTypeArgs = {
  companyId: Scalars['ID'];
  data: ILeaveType;
};


export type MutationCreateMemberArgs = {
  companyId: Scalars['ID'];
  data: IMember;
};


export type MutationCreateMemberProjectsMembershipArgs = {
  companyId: Scalars['ID'];
  data: IMemberProjectMembership;
  memberId: Scalars['ID'];
};


export type MutationCreatePayrollArgs = {
  companyId: Scalars['ID'];
  data: IPayroll;
};


export type MutationCreatePayrollEntityArgs = {
  companyId: Scalars['ID'];
  data: IPayrollEntity;
  memberId: Scalars['ID'];
  payrollId: Scalars['ID'];
};


export type MutationCreatePayrollEntityAdditionArgs = {
  companyId: Scalars['ID'];
  data: IPayrollEntityAddition;
};


export type MutationCreateProjectArgs = {
  companyId: Scalars['ID'];
  data: IProject;
};


export type MutationCreateProjectCommissionArgs = {
  companyId: Scalars['ID'];
  data: IProjectCommission;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectDocumentArgs = {
  companyId: Scalars['ID'];
  data: ICreateProjectDocument;
};


export type MutationCreateProjectMembershipArgs = {
  companyId: Scalars['ID'];
  data: IProjectMembership;
};


export type MutationCreateProjectMilestoneArgs = {
  companyId: Scalars['ID'];
  data: IProjectMilestone;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectNoteArgs = {
  companyId: Scalars['ID'];
  data: IProjectNote;
};


export type MutationCreateReportArgs = {
  companyId: Scalars['ID'];
  data: IReport;
};


export type MutationCreateRequestArgs = {
  companyId: Scalars['ID'];
  data: ICreateRequest;
  entityId?: Maybe<Scalars['ID']>;
  entityType: RequestDomains;
  requestAction: RequestAction;
};


export type MutationCreateRoleArgs = {
  companyId: Scalars['ID'];
  data: IRole;
};


export type MutationCreateScenarioArgs = {
  companyId: Scalars['ID'];
  data: ICreateScenario;
};


export type MutationCreateScenarioMemberEntityArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  data: ICreateScenarioMemberEntity;
};


export type MutationCreateScenarioMembersArgs = {
  companyId: Scalars['ID'];
  data: ICreateScenarioMembers;
  projectId: Scalars['ID'];
};


export type MutationCreateSelfAssessmentArgs = {
  companyId: Scalars['ID'];
  data: ICreateSelfAssessment;
};


export type MutationCreateSelfAssessmentProjectArgs = {
  data: ICreateSelfAssessmentProject;
};


export type MutationCreateSeniorityArgs = {
  companyId: Scalars['ID'];
  data: IMemberSeniority;
};


export type MutationCreateSkillArgs = {
  companyId: Scalars['ID'];
  data: ISkill;
};


export type MutationCreateSkillCategoryArgs = {
  companyId: Scalars['ID'];
  data: ISkillCategory;
};


export type MutationCreateSpecializationArgs = {
  companyId: Scalars['ID'];
  data: ISpecialization;
};


export type MutationCreateSpecializationSkillArgs = {
  companyId: Scalars['ID'];
  data: ISpecializationSkill;
};


export type MutationCreateTagArgs = {
  companyId: Scalars['ID'];
  data: ITag;
};


export type MutationCreateTimeLogArgs = {
  companyId: Scalars['ID'];
  data: ITimeLog;
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateTimeLogPeriodArgs = {
  companyId: Scalars['ID'];
  data: ITimeLogPeriod;
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  companyId: Scalars['ID'];
  data: ICreateUser;
  newCompanyId: Scalars['ID'];
};


export type MutationCreateVendorArgs = {
  companyId: Scalars['ID'];
  data: IVendor;
};


export type MutationDeactivateUserArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationDeleteAdvancePaymentArgs = {
  advancePaymentId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteAssignmentArgs = {
  assignmentsIds: Array<Scalars['ID']>;
  companyId: Scalars['ID'];
};


export type MutationDeleteBillableLeaveRuleArgs = {
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationDeleteBillingRecordArgs = {
  billingRecordId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteBudgetArgs = {
  budgetId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteBudgetAccessArgs = {
  companyId: Scalars['ID'];
  data: IBudgetAccess;
};


export type MutationDeleteBudgetCategoriesArgs = {
  companyId: Scalars['ID'];
  data: IRemoveBudgetCategories;
};


export type MutationDeleteBudgetPlanArgs = {
  budgetPlanId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteClientArgs = {
  clientId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type MutationDeleteCompanyIntegrationArgs = {
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
};


export type MutationDeleteCompensationArgs = {
  companyId: Scalars['ID'];
  compensationId: Scalars['ID'];
};


export type MutationDeleteCompensationTypeArgs = {
  companyId: Scalars['ID'];
  compensationTypeId: Scalars['ID'];
};


export type MutationDeleteCostRateArgs = {
  companyId: Scalars['ID'];
  costRateId: Scalars['ID'];
};


export type MutationDeleteExpenseArgs = {
  companyId: Scalars['ID'];
  expenseId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteExpenseDocumentArgs = {
  companyId: Scalars['ID'];
  expenseDocumentId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteExpenseTypeArgs = {
  companyId: Scalars['ID'];
  expenseTypeId: Scalars['ID'];
};


export type MutationDeleteExternalRateArgs = {
  companyId: Scalars['ID'];
  externalRateId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationDeleteFileFolderArgs = {
  companyId: Scalars['ID'];
  fileFolderId: Scalars['ID'];
  projectId: Scalars['ID'];
  removeContents?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteHolidayArgs = {
  companyId: Scalars['ID'];
  holidayId: Scalars['ID'];
};


export type MutationDeleteInvitationArgs = {
  companyId: Scalars['ID'];
  id: Scalars['String'];
};


export type MutationDeleteLeaveArgs = {
  companyId: Scalars['ID'];
  leaveId: Scalars['ID'];
};


export type MutationDeleteLeaveTypeArgs = {
  companyId: Scalars['ID'];
  leaveTypeId: Scalars['ID'];
};


export type MutationDeleteMemberBalanceAccrualMonthArgs = {
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationDeleteMemberSkillArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  skillId: Scalars['ID'];
};


export type MutationDeletePayrollArgs = {
  companyId: Scalars['ID'];
  payrollId: Scalars['ID'];
};


export type MutationDeletePayrollEntityArgs = {
  companyId: Scalars['ID'];
  payrollEntityId: Scalars['ID'];
};


export type MutationDeletePayrollEntityAdditionArgs = {
  companyId: Scalars['ID'];
  payrollEntityAdditionId: Scalars['ID'];
};


export type MutationDeleteProjectCommissionArgs = {
  companyId: Scalars['ID'];
  projectCommissionId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationDeleteProjectDocumentArgs = {
  companyId: Scalars['ID'];
  projectDocumentId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationDeleteProjectMembershipArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  projectMembershipId: Scalars['ID'];
};


export type MutationDeleteProjectMilestoneArgs = {
  companyId: Scalars['ID'];
  projectMilestoneId: Scalars['ID'];
};


export type MutationDeleteProjectNoteArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  projectNoteId: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type MutationDeleteRequestsArgs = {
  companyId: Scalars['ID'];
  requestsId: Array<Scalars['ID']>;
};


export type MutationDeleteRoleArgs = {
  companyId: Scalars['ID'];
  memberRoleId: Scalars['ID'];
};


export type MutationDeleteScenarioArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationDeleteScenarioMembersArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioMembersId: Scalars['ID'];
};


export type MutationDeleteSelfAssessmentProjectArgs = {
  selfAssessmentProjectId: Scalars['ID'];
};


export type MutationDeleteSelfAssessmentSkillArgs = {
  selfAssessmentId: Scalars['ID'];
  skillId: Scalars['ID'];
};


export type MutationDeleteSeniorityArgs = {
  companyId: Scalars['ID'];
  seniorityId: Scalars['ID'];
};


export type MutationDeleteSkillArgs = {
  companyId: Scalars['ID'];
  skillId: Scalars['ID'];
};


export type MutationDeleteSkillCategoryArgs = {
  companyId: Scalars['ID'];
  skillCategoryId: Scalars['ID'];
};


export type MutationDeleteSpecializationArgs = {
  companyId: Scalars['ID'];
  memberSpecializationId: Scalars['ID'];
};


export type MutationDeleteSpecializationSkillArgs = {
  companyId: Scalars['ID'];
  data: ISpecializationSkill;
};


export type MutationDeleteTagArgs = {
  companyId: Scalars['ID'];
  tagId: Scalars['ID'];
};


export type MutationDeleteTimeLogArgs = {
  companyId: Scalars['ID'];
  timeLogIds: Array<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationDeleteVendorArgs = {
  companyId: Scalars['ID'];
  vendorId: Scalars['ID'];
};


export type MutationEditAdvancePaymentArgs = {
  advancePaymentId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IAdvancePayment;
};


export type MutationEditAssignmentArgs = {
  assignmentId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IAssignment;
};


export type MutationEditAssignmentsArgs = {
  assignmentsIds: Array<Scalars['ID']>;
  companyId: Scalars['ID'];
  data: IEditAssignments;
};


export type MutationEditBillableLeaveRuleArgs = {
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IBillableLeaveRule;
  projectId: Scalars['ID'];
};


export type MutationEditBillingRecordArgs = {
  billingRecordId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IBillingRecord;
};


export type MutationEditBudgetArgs = {
  budgetId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IBudget;
};


export type MutationEditBudgetPlanArgs = {
  budgetPlanId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IBudgetPlan;
};


export type MutationEditClientArgs = {
  clientId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IClient;
};


export type MutationEditCommentArgs = {
  commentId: Scalars['ID'];
  companyId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationEditCompanyArgs = {
  companyId: Scalars['ID'];
  data: ICompany;
  editCompanyId: Scalars['ID'];
};


export type MutationEditCompanyIntegrationOptionsArgs = {
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
  integrationOptions: Array<IIntegrationSyncOption>;
};


export type MutationEditCompanyIntegrationStatusArgs = {
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
  dateFormat?: Maybe<Scalars['String']>;
  status?: Maybe<IntegrationStatus>;
};


export type MutationEditCompensationArgs = {
  companyId: Scalars['ID'];
  compensationId: Scalars['ID'];
  data: ICompensation;
};


export type MutationEditCompensationTypeArgs = {
  companyId: Scalars['ID'];
  compensationTypeId: Scalars['ID'];
  data: ICompensationType;
};


export type MutationEditContractArgs = {
  companyId: Scalars['ID'];
  contractId: Scalars['ID'];
  data: IContract;
  projectId: Scalars['ID'];
};


export type MutationEditCostRateArgs = {
  companyId: Scalars['ID'];
  costRateId: Scalars['ID'];
  data: ICostRate;
};


export type MutationEditExpenseArgs = {
  companyId: Scalars['ID'];
  data: IExpense;
  expenseId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationEditExpenseTypeArgs = {
  companyId: Scalars['ID'];
  data: IExpenseType;
  expenseTypeId: Scalars['ID'];
};


export type MutationEditExternalRateArgs = {
  companyId: Scalars['ID'];
  data: IExternalRate;
  externalRateId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationEditExternalRateSeniorityArgs = {
  companyId: Scalars['ID'];
  data: IEditExternalRateSeniority;
};


export type MutationEditFileFolderArgs = {
  companyId: Scalars['ID'];
  data: IFileFolder;
  fileFolderId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationEditHolidayArgs = {
  companyId: Scalars['ID'];
  data: IHoliday;
  holidayId: Scalars['ID'];
};


export type MutationEditLeaveArgs = {
  companyId: Scalars['ID'];
  data: ILeave;
  leaveId: Scalars['ID'];
};


export type MutationEditLeaveTypeArgs = {
  companyId: Scalars['ID'];
  data: ILeaveType;
  leaveTypeId: Scalars['ID'];
};


export type MutationEditMemberArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  role: EditableMemberType;
};


export type MutationEditMemberLeaveRuleMonthBalanceArgs = {
  companyId: Scalars['ID'];
  data: IMemberLeaveRuleMonthBalance;
};


export type MutationEditMemberSkillArgs = {
  companyId: Scalars['ID'];
  data: IEditMemberSkill;
  memberSkillId: Scalars['ID'];
};


export type MutationEditMemberSkillsArgs = {
  companyId: Scalars['ID'];
  data: IMemberSkill;
};


export type MutationEditPayrollEntityArgs = {
  companyId: Scalars['ID'];
  data: IPayrollEntity;
  payrollEntityId: Scalars['ID'];
};


export type MutationEditPayrollEntityAdditionArgs = {
  companyId: Scalars['ID'];
  data: IPayrollEntityAddition;
  payrollEntityAdditionId: Scalars['ID'];
};


export type MutationEditPayrollStatusArgs = {
  companyId: Scalars['ID'];
  payrollId: Scalars['ID'];
  status: PayrollStatus;
};


export type MutationEditProjectArgs = {
  companyId: Scalars['ID'];
  data: IProject;
  projectId: Scalars['ID'];
};


export type MutationEditProjectCommissionArgs = {
  companyId: Scalars['ID'];
  data: IProjectCommission;
  projectCommissionId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationEditProjectDocumentTagsArgs = {
  companyId: Scalars['ID'];
  data: IProjectDocumentTags;
};


export type MutationEditProjectMembershipArgs = {
  companyId: Scalars['ID'];
  data: IEditProjectMembership;
  projectId: Scalars['ID'];
  projectMembershipId: Scalars['ID'];
};


export type MutationEditProjectMilestoneArgs = {
  companyId: Scalars['ID'];
  data: IProjectMilestone;
  projectMilestoneId: Scalars['ID'];
};


export type MutationEditProjectNoteArgs = {
  companyId: Scalars['ID'];
  data: IProjectNote;
  projectNoteId: Scalars['ID'];
};


export type MutationEditProjectPmFieldArgs = {
  companyId: Scalars['ID'];
  data: IMemberProjectMembership;
  memberId: Scalars['ID'];
};


export type MutationEditReportArgs = {
  companyId: Scalars['ID'];
  data: IEditReport;
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type MutationEditReportBillableDaysArgs = {
  companyId: Scalars['ID'];
  data: Array<IReportBillableDays>;
  reportId: Scalars['ID'];
};


export type MutationEditReportMemberLogsArgs = {
  companyId: Scalars['ID'];
  data: IReportMemberLogs;
  reportMemberId: Scalars['ID'];
};


export type MutationEditReportMembersArgs = {
  companyId: Scalars['ID'];
  includedMembers: Array<Scalars['ID']>;
  reportId: Scalars['ID'];
};


export type MutationEditRequestArgs = {
  companyId: Scalars['ID'];
  data: ICreateRequest;
  requestId: Scalars['ID'];
};


export type MutationEditRequestsStatusArgs = {
  companyId: Scalars['ID'];
  data: IRequest;
  requestsId: Array<Scalars['ID']>;
};


export type MutationEditRoleArgs = {
  companyId: Scalars['ID'];
  data: IRole;
  memberRoleId: Scalars['ID'];
};


export type MutationEditScenarioArgs = {
  companyId: Scalars['ID'];
  data: IEditScenario;
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationEditScenarioMembersArgs = {
  companyId: Scalars['ID'];
  data: IEditScenarioMembers;
  projectId: Scalars['ID'];
  scenarioMembersId: Scalars['ID'];
};


export type MutationEditScenarioMembersEntityArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  data: IScenarioMembersEntity;
  scenarioMembersEntityId: Scalars['ID'];
};


export type MutationEditSelfAssessmentArgs = {
  companyId: Scalars['ID'];
  data: IEditSelfAssessment;
  selfAssessmentId: Scalars['ID'];
};


export type MutationEditSelfAssessmentProjectArgs = {
  data: IEditSelfAssessmentProject;
  selfAssessmentProjectId: Scalars['ID'];
};


export type MutationEditSelfAssessmentSkillArgs = {
  data: ISelfAssessmentSkill;
};


export type MutationEditSeniorityArgs = {
  companyId: Scalars['ID'];
  data: IMemberSeniority;
  seniorityId: Scalars['ID'];
};


export type MutationEditSkillArgs = {
  companyId: Scalars['ID'];
  data: ISkill;
  skillId: Scalars['ID'];
};


export type MutationEditSkillCategoryArgs = {
  companyId: Scalars['ID'];
  data: ISkillCategory;
  skillCategoryId: Scalars['ID'];
};


export type MutationEditSpecializationArgs = {
  companyId: Scalars['ID'];
  data: ISpecialization;
  memberSpecializationId: Scalars['ID'];
};


export type MutationEditTagArgs = {
  companyId: Scalars['ID'];
  data: ITag;
  tagId: Scalars['ID'];
};


export type MutationEditTeamMemberArgs = {
  companyId: Scalars['ID'];
  data: IMember;
  memberId: Scalars['ID'];
};


export type MutationEditTeamMembersPmArgs = {
  companyId: Scalars['ID'];
  data: IMembersPm;
};


export type MutationEditTimeLogArgs = {
  companyId: Scalars['ID'];
  data: ITimeLog;
  projectId: Scalars['ID'];
  timeLogId: Scalars['ID'];
};


export type MutationEditUserArgs = {
  companyId: Scalars['ID'];
  data: ICreateUser;
  memberId: Scalars['ID'];
};


export type MutationEditVendorArgs = {
  companyId: Scalars['ID'];
  data: IVendor;
  vendorId: Scalars['ID'];
};


export type MutationExcludeReportMembersArgs = {
  companyId: Scalars['ID'];
  excludedMembers: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};


export type MutationImportDataArgs = {
  companyId: Scalars['ID'];
  data: IImportData;
};


export type MutationInviteArgs = {
  companyId: Scalars['ID'];
  email: Scalars['String'];
  role: EditableMemberType;
  userCompanyId?: Maybe<Scalars['ID']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  remember?: Maybe<Scalars['Boolean']>;
};


export type MutationManualCompanyIntegrationSyncArgs = {
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
};


export type MutationMoveFileBetweenFoldersArgs = {
  companyId: Scalars['ID'];
  documentId: Scalars['ID'];
  folder?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};


export type MutationRecalculatePayrollEntityArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  payrollId: Scalars['ID'];
};


export type MutationRefreshInvitationArgs = {
  companyId: Scalars['ID'];
  id: Scalars['String'];
};


export type MutationRefreshScenarioDataArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationRefreshScenarioExchangeRateArgs = {
  companyId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationRestoreContractArgs = {
  companyId: Scalars['ID'];
  contractId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRestoreMemberArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationRestoreProjectArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationRestoreUserArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationScenarioForecastArgs = {
  companyId: Scalars['ID'];
  data: IForecastSettings;
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type MutationSetMemberBalanceAccrualMonthArgs = {
  accrualDate: Scalars['String'];
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type MutationSetReportCustomizationArgs = {
  companyId: Scalars['ID'];
  data: IReportCustomization;
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type MutationSetUpReportExpensesArgs = {
  companyId: Scalars['ID'];
  expensesId: Array<Scalars['ID']>;
  reportId: Scalars['ID'];
};


export type MutationSetUpReportMilestonesArgs = {
  companyId: Scalars['ID'];
  milestonesId: Array<Scalars['ID']>;
  reportId: Scalars['ID'];
};


export type MutationUploadFeedbackFileArgs = {
  file: Scalars['Upload'];
};

export type Payroll = {
  __typename?: 'Payroll';
  company_id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  employees: Scalars['Int'];
  end_date: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  start_date: Scalars['DateTime'];
  status: PayrollStatus;
  total: Scalars['Int'];
  updated_at: Scalars['DateTime'];
};

export type PayrollEntity = {
  __typename?: 'PayrollEntity';
  addition: Array<PayrollEntityAddition>;
  created_at: Scalars['DateTime'];
  deduction: Array<PayrollEntityAddition>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  leaves: Scalars['Int'];
  member: Member;
  member_id: Scalars['ID'];
  net_pay: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  payroll: Payroll;
  payroll_id: Scalars['ID'];
  processed: Scalars['Boolean'];
  totalNetPay: Scalars['Int'];
  updated_at: Scalars['DateTime'];
  work_days: Scalars['Int'];
};

export type PayrollEntityAddition = {
  __typename?: 'PayrollEntityAddition';
  amount: Scalars['Int'];
  component: Scalars['String'];
  created_at: Scalars['DateTime'];
  currency: Scalars['String'];
  currencyId: Scalars['ID'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  payrollEntityId: Scalars['ID'];
  recurring: Scalars['Boolean'];
  type: PayrollEntityType;
  updated_at: Scalars['DateTime'];
};

export enum PayrollEntityType {
  Addition = 'addition',
  Deduction = 'deduction'
}

export enum PayrollStatus {
  Finalized = 'finalized',
  InProgress = 'inProgress'
}

export type Permission = {
  __typename?: 'Permission';
  action: ActionsType;
  created_at: Scalars['DateTime'];
  editable?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type PmProjectMembership = {
  __typename?: 'PmProjectMembership';
  accessLevel: ProjectAccess;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type Profitability = {
  __typename?: 'Profitability';
  commission?: Maybe<Scalars['Float']>;
  compensation?: Maybe<Scalars['Float']>;
  cost: Scalars['Float'];
  nameOfMonth: Scalars['String'];
  other: Scalars['Float'];
  profit?: Maybe<Scalars['Float']>;
  profitMargin?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  salary: Scalars['Float'];
  tmBenchmark?: Maybe<Scalars['Float']>;
};

export type Project = {
  __typename?: 'Project';
  activeAssignments: Scalars['Int'];
  activeContracts: Scalars['Int'];
  archived_at?: Maybe<Scalars['DateTime']>;
  assets: Array<Asset>;
  assignments: Array<Assignment>;
  billableLeaves: Scalars['Boolean'];
  billingRecords: Array<BillingRecord>;
  client?: Maybe<Client>;
  color?: Maybe<Scalars['HexColorCode']>;
  cost_budget_amount?: Maybe<Scalars['Int']>;
  countFiles: Scalars['Int'];
  created_at: Scalars['DateTime'];
  currentCommission?: Maybe<Scalars['Float']>;
  end_date: Scalars['DateTime'];
  feeCurrencyId?: Maybe<Scalars['ID']>;
  fee_amount?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  pm: MemberLite;
  pmFinAccess?: Maybe<PmProjectMembership>;
  projectFinancialManagersIds: Array<Scalars['ID']>;
  start_date: Scalars['DateTime'];
  type: ProjectType;
  unit: RateUnit;
  updated_at: Scalars['DateTime'];
};


export type ProjectAssignmentsArgs = {
  filters?: Maybe<IAssignmentFilters>;
};


export type ProjectBillingRecordsArgs = {
  filters?: Maybe<IBillingFilters>;
};


export type ProjectCurrentCommissionArgs = {
  withCommission: Scalars['Boolean'];
};

export enum ProjectAccess {
  FullAccess = 'fullAccess',
  NonAccess = 'nonAccess',
  RatesAccess = 'ratesAccess'
}

export type ProjectAssignmentMembers = {
  __typename?: 'ProjectAssignmentMembers';
  actualCommission?: Maybe<Scalars['Float']>;
  actualPaidRevenue?: Maybe<Scalars['Float']>;
  actualProfit?: Maybe<Scalars['Float']>;
  actualProfitMargin?: Maybe<Scalars['Float']>;
  actualRevenue?: Maybe<Scalars['Float']>;
  actualTime?: Maybe<Scalars['Float']>;
  actualUnpaidRevenue?: Maybe<Scalars['Float']>;
  allocationHours?: Maybe<Scalars['Float']>;
  allocationPercent?: Maybe<Scalars['Float']>;
  assignmentEndDate: Scalars['DateTime'];
  assignmentId: Scalars['ID'];
  assignmentStartDate: Scalars['DateTime'];
  billable: Scalars['Boolean'];
  billingType: AssignmentCalculationType;
  color?: Maybe<Scalars['String']>;
  compensationCost?: Maybe<Scalars['Float']>;
  compensationCostOrigin?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  costOrigin?: Maybe<Scalars['Float']>;
  daysRanges?: Maybe<Array<CostRate>>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  plannedCommission?: Maybe<Scalars['Float']>;
  pm: Scalars['String'];
  pmColor: Scalars['String'];
  profit?: Maybe<Scalars['Float']>;
  profitMargin?: Maybe<Scalars['Float']>;
  projectColor: Scalars['String'];
  projectCurrency?: Maybe<Scalars['String']>;
  projectCurrencyId?: Maybe<Scalars['ID']>;
  projectId: Scalars['String'];
  projectName: Scalars['String'];
  projectType: ProjectType;
  rates?: Maybe<Array<ExternalRate>>;
  revenue?: Maybe<Scalars['Float']>;
  revenueOrigin?: Maybe<Scalars['Float']>;
  role: Scalars['String'];
  scheduledTime?: Maybe<Scalars['Float']>;
  seniority?: Maybe<Scalars['String']>;
  timeOffCost?: Maybe<Scalars['Float']>;
  tmBenchmark?: Maybe<Scalars['Float']>;
};

export type ProjectAssignments = {
  __typename?: 'ProjectAssignments';
  company_id: Scalars['ID'];
  cost_budget_amount?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateCurrency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  profit?: Maybe<Scalars['Float']>;
  profitMargin?: Maybe<Scalars['Float']>;
  projectAssignments: Array<ProjectAssignmentMembers>;
  projectCurrency?: Maybe<Scalars['String']>;
  totalCommission?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
};

export type ProjectCommission = {
  __typename?: 'ProjectCommission';
  commission: Scalars['Float'];
  created_at: Scalars['DateTime'];
  effectiveFrom: Scalars['DateTime'];
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type ProjectDocument = {
  __typename?: 'ProjectDocument';
  companyId: Scalars['ID'];
  createdBy?: Maybe<Scalars['ID']>;
  createdByMemberColor?: Maybe<Scalars['String']>;
  createdByMemberName?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  document: Document;
  documentId: Scalars['ID'];
  id: Scalars['ID'];
  projectColor: Scalars['String'];
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  tags: Array<Tag>;
  updated_at: Scalars['DateTime'];
};

export type ProjectDocumentTags = {
  __typename?: 'ProjectDocumentTags';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  projectDocumentId: Scalars['ID'];
  tagId: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type ProjectInsights = {
  __typename?: 'ProjectInsights';
  company_id: Scalars['ID'];
  exchangeRate?: Maybe<Scalars['Float']>;
  exchangeRateCurrency?: Maybe<Scalars['String']>;
  profitability?: Maybe<Array<Profitability>>;
  projectCurrency?: Maybe<Scalars['String']>;
  revenueChart?: Maybe<Array<RevenueChart>>;
};

export type ProjectListData = {
  __typename?: 'ProjectListData';
  activeAssignments: Scalars['Int'];
  currentCommission?: Maybe<Scalars['Float']>;
  projectId: Scalars['ID'];
};

export type ProjectLite = {
  __typename?: 'ProjectLite';
  archived_at?: Maybe<Scalars['DateTime']>;
  client?: Maybe<Client>;
  color?: Maybe<Scalars['HexColorCode']>;
  cost_budget_amount?: Maybe<Scalars['Int']>;
  created_at: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  feeCurrencyId?: Maybe<Scalars['ID']>;
  fee_amount?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pm: MemberLite;
  start_date: Scalars['DateTime'];
  type: ProjectType;
  unit: RateUnit;
  updated_at: Scalars['DateTime'];
};

export type ProjectMargins = {
  __typename?: 'ProjectMargins';
  forecastMargin: Scalars['Float'];
  margin: Scalars['Float'];
  projectId: Scalars['ID'];
};

export type ProjectMembership = {
  __typename?: 'ProjectMembership';
  accessLevel: ProjectAccess;
  color?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberFirstName?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  memberLastName?: Maybe<Scalars['String']>;
  pmId: Scalars['ID'];
  projectColor?: Maybe<Scalars['HexColorCode']>;
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  projectType: ProjectType;
  startDate?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
};

export type ProjectMilestone = {
  __typename?: 'ProjectMilestone';
  amount: Scalars['Float'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  paymentDate: Scalars['DateTime'];
  projectId: Scalars['ID'];
  reportId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type ProjectMilestoneSection = {
  __typename?: 'ProjectMilestoneSection';
  actualCommission?: Maybe<Scalars['Float']>;
  actualRevenue?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  compensationCost?: Maybe<Scalars['Float']>;
  compensationCostOrigin?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  costOrigin?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  otherCost?: Maybe<Scalars['Float']>;
  paymentDate: Scalars['DateTime'];
  plannedCommission: Scalars['Float'];
  plannedRevenue: Scalars['Float'];
  profit?: Maybe<Scalars['Float']>;
  profitMargin?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  title: Scalars['String'];
  totalCost?: Maybe<Scalars['Float']>;
};

export type ProjectNote = {
  __typename?: 'ProjectNote';
  companyId: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  notes: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type ProjectRoleOrder = {
  __typename?: 'ProjectRoleOrder';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  orderingIndex: Scalars['Int'];
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  roleName: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export enum ProjectType {
  FixedPrice = 'fixedPrice',
  NonBillable = 'nonBillable',
  Retainer = 'retainer',
  TimeAndMaterial = 'timeAndMaterial'
}

export type Query = {
  __typename?: 'Query';
  activityHistories: Array<ActivityHistory>;
  actorPermissions: Array<Permission>;
  assignment?: Maybe<Assignment>;
  assignments: Array<Assignment>;
  availableIntegrations: Array<AvailableIntegration>;
  benchReport: Array<BenchReport>;
  billableLeaveRuleBalance: Array<RuleMembersBalance>;
  billableLeaveRuleMonthBalance: Array<BillableLeaveRuleMonthBalance>;
  billableLeavesRules: Array<BillableLeaveRule>;
  budgetPlans: Array<BudgetPlan>;
  budgets: Array<Budget>;
  clients: Array<Client>;
  comments: Array<Comment>;
  companies: Array<Company>;
  companyIntegration?: Maybe<CompanyIntegration>;
  companyIntegrationSync: Array<CompanyIntegrationSync>;
  companyIntegrations: Array<CompanyIntegration>;
  compensationTypes: Array<CompensationType>;
  compensations: Array<Compensation>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  contractsListData: Array<ContractListData>;
  countActivityHistoryLogs: Scalars['Int'];
  countAllRequests: Scalars['Int'];
  countPendingRequest: Scalars['Int'];
  cronRunHistories: Array<CronRunHistory>;
  currencies: Array<Currency>;
  currency?: Maybe<Currency>;
  exchangeRate?: Maybe<ExchangeRates>;
  exchangeRates: Array<ExchangeRates>;
  expenseDocuments: Array<ExpenseDocument>;
  expenseTypes: Array<ExpenseType>;
  expenses: Array<Expense>;
  exportImportExample: Scalars['String'];
  exportMembersTimeLogs: Scalars['String'];
  exportProject: Scalars['String'];
  exportProjectReport: Scalars['String'];
  exportReport: Scalars['String'];
  externalMap?: Maybe<ExternalIdMap>;
  externalRates: Array<ExternalRate>;
  externalToken?: Maybe<ExternalIntegration>;
  finalizedPayrolls: Array<Payroll>;
  folderContents?: Maybe<FolderContent>;
  folders: Array<FileFolder>;
  generateXeroInvoice: Scalars['String'];
  getSelfAppraisal?: Maybe<SelfAssessment>;
  holidays: Array<Holiday>;
  importHistories: Array<ImportHistory>;
  insightColumnsOrder: Array<InsightColumnOrder>;
  invitations: Array<Invitation>;
  leave?: Maybe<Leave>;
  leaveTypes: Array<LeaveType>;
  leaves: Array<Leave>;
  managedProjects: Array<Project>;
  me: Array<CompanyUser>;
  member?: Maybe<Member>;
  memberAssignments: Array<ProjectAssignmentMembers>;
  memberBalance: Array<Member>;
  memberIntegrationStatuses?: Maybe<MemberIntegrationStatuses>;
  memberLeaveRuleAccrualAmounts: Array<MemberLeaveRuleAccrualAmount>;
  memberProjectsDistribution: Array<MemberProjectDistribution>;
  members: Array<Member>;
  membersHours: Scalars['String'];
  membersIntegrationStatuses?: Maybe<Array<MemberIntegrationStatuses>>;
  membersListData: Array<MemberListData>;
  payrollEntities: Array<PayrollEntity>;
  payrolls: Array<Payroll>;
  permissions: Array<Permission>;
  pmMembers: Array<Member>;
  profitability: ProjectInsights;
  project?: Maybe<Project>;
  projectAssignments: ProjectAssignments;
  projectCommissions: Array<ProjectCommission>;
  projectDocumentUrl: Scalars['String'];
  projectDocuments: Array<ProjectDocument>;
  projectMembership?: Maybe<ProjectMembership>;
  projectMemberships: Array<ProjectMembership>;
  projectMilestones: Array<ProjectMilestone>;
  projectMilestonesSection: Array<ProjectMilestoneSection>;
  projectNotes: Array<ProjectNote>;
  projectReportCustomization?: Maybe<ReportCustomization>;
  projectRolesOrder: Array<ProjectRoleOrder>;
  projects: Array<Project>;
  projectsAdminReport: Scalars['String'];
  projectsAssignments: Array<ProjectAssignments>;
  projectsListData: Array<ProjectListData>;
  projectsMargins: Array<ProjectMargins>;
  report?: Maybe<Report>;
  reportMembers: Array<ReportMember>;
  reports: Array<Report>;
  request?: Maybe<Request>;
  requests: Array<Request>;
  resourcePlanningMembers: Array<ResourcePlanningMember>;
  resourcePlanningProjects: Array<ResourcePlanningProjectAssignment>;
  revenueChart: ProjectInsights;
  roles: Array<Role>;
  scenario?: Maybe<Scenario>;
  scenarioMembers: Array<ScenarioMembers>;
  scenarios: Array<Scenario>;
  seniorities: Array<MemberSeniority>;
  skillCategories: Array<SkillCategory>;
  specializations: Array<Specialization>;
  tags: Array<Tag>;
  teamDynamics: Array<TeamDynamics>;
  timeLogs: Array<TimeLog>;
  totalTimeLogs: Array<TotalTimeLog>;
  users: Array<CompanyUser>;
  utilizationReport: Array<UtilizationReport>;
  vendors: Array<Vendor>;
  workingHoursInMonth: Array<WorkingHoursInMonth>;
};


export type QueryActivityHistoriesArgs = {
  companyId: Scalars['ID'];
  historyFilter?: Maybe<HistoryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryActorPermissionsArgs = {
  companyId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryAssignmentArgs = {
  assignmentId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type QueryAssignmentsArgs = {
  companyId: Scalars['ID'];
  data: IAssignmentQuery;
  filters?: Maybe<IAssignmentFilters>;
};


export type QueryAvailableIntegrationsArgs = {
  companyId: Scalars['ID'];
};


export type QueryBenchReportArgs = {
  companyId: Scalars['ID'];
  data: IBenchReportData;
  filters?: Maybe<InsightFilters>;
};


export type QueryBillableLeaveRuleBalanceArgs = {
  companyId: Scalars['ID'];
  data: IBillableLeaveRuleBalance;
};


export type QueryBillableLeaveRuleMonthBalanceArgs = {
  companyId: Scalars['ID'];
  data: IBillableLeaveRuleMonthBalance;
  memberId: Scalars['ID'];
};


export type QueryBillableLeavesRulesArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryBudgetPlansArgs = {
  companyId: Scalars['ID'];
};


export type QueryBudgetsArgs = {
  companyId: Scalars['ID'];
  data: IPaginationBudget;
};


export type QueryClientsArgs = {
  companyId: Scalars['ID'];
  externalRatesAccess?: Maybe<Scalars['Boolean']>;
};


export type QueryCommentsArgs = {
  companyId: Scalars['ID'];
  entityId: Scalars['String'];
  entityType: CommentDomains;
};


export type QueryCompaniesArgs = {
  companyId: Scalars['ID'];
};


export type QueryCompanyIntegrationArgs = {
  companyId: Scalars['ID'];
  integrationId: Scalars['ID'];
};


export type QueryCompanyIntegrationSyncArgs = {
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
};


export type QueryCompanyIntegrationsArgs = {
  companyId: Scalars['ID'];
};


export type QueryCompensationTypesArgs = {
  companyId: Scalars['ID'];
};


export type QueryCompensationsArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryContractArgs = {
  companyId: Scalars['ID'];
  contractId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryContractsArgs = {
  actorFullAccess?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterContractData>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryContractsListDataArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterContractData>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryCountActivityHistoryLogsArgs = {
  companyId: Scalars['ID'];
  historyFilter?: Maybe<HistoryFilter>;
};


export type QueryCountAllRequestsArgs = {
  companyId: Scalars['ID'];
  requestFilter?: Maybe<RequestFilter>;
};


export type QueryCountPendingRequestArgs = {
  companyId: Scalars['ID'];
};


export type QueryCronRunHistoriesArgs = {
  companyId: Scalars['ID'];
};


export type QueryCurrenciesArgs = {
  companyId: Scalars['ID'];
};


export type QueryCurrencyArgs = {
  companyId: Scalars['ID'];
  currencyId: Scalars['ID'];
};


export type QueryExchangeRateArgs = {
  baseCurrencyId: Scalars['ID'];
  companyId: Scalars['ID'];
  exchangeCurrencyId: Scalars['ID'];
};


export type QueryExchangeRatesArgs = {
  baseCurrencyId: Scalars['ID'];
  companyId: Scalars['ID'];
};


export type QueryExpenseDocumentsArgs = {
  companyId: Scalars['ID'];
  expenseId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryExpenseTypesArgs = {
  companyId: Scalars['ID'];
};


export type QueryExpensesArgs = {
  companyId: Scalars['ID'];
  data: IQueryExpenses;
};


export type QueryExportImportExampleArgs = {
  companyId: Scalars['ID'];
  type: ImportType;
};


export type QueryExportMembersTimeLogsArgs = {
  companyId: Scalars['ID'];
  data: IPaginationInterval;
  members: Array<Scalars['ID']>;
};


export type QueryExportProjectArgs = {
  companyId: Scalars['ID'];
  data?: Maybe<IExportProject>;
  exportType?: Maybe<ExportTarget>;
  projectId: Scalars['ID'];
};


export type QueryExportProjectReportArgs = {
  companyId: Scalars['ID'];
  data?: Maybe<IExportProject>;
  exportType?: Maybe<ExportTarget>;
  projectId: Scalars['ID'];
};


export type QueryExportReportArgs = {
  companyId: Scalars['ID'];
  exportType?: Maybe<ExportTarget>;
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type QueryExternalMapArgs = {
  companyId: Scalars['ID'];
  integrationId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryExternalRatesArgs = {
  companyId: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
};


export type QueryExternalTokenArgs = {
  companyId: Scalars['ID'];
  userCompanyId: Scalars['ID'];
};


export type QueryFinalizedPayrollsArgs = {
  companyId: Scalars['ID'];
};


export type QueryFolderContentsArgs = {
  companyId: Scalars['ID'];
  filters?: Maybe<IProjectDocumentFilters>;
  folderId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  searchByName?: Maybe<Scalars['String']>;
};


export type QueryFoldersArgs = {
  companyId: Scalars['ID'];
  filterByProject?: Maybe<Array<Scalars['ID']>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryGenerateXeroInvoiceArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type QueryGetSelfAppraisalArgs = {
  selfAssessmentId: Scalars['ID'];
};


export type QueryHolidaysArgs = {
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
};


export type QueryImportHistoriesArgs = {
  companyId: Scalars['ID'];
  importFilter?: Maybe<ImportFilter>;
};


export type QueryInsightColumnsOrderArgs = {
  companyId: Scalars['ID'];
  reportType: ReportType;
};


export type QueryInvitationsArgs = {
  companyId: Scalars['ID'];
};


export type QueryLeaveArgs = {
  companyId: Scalars['ID'];
  leaveId: Scalars['ID'];
};


export type QueryLeaveTypesArgs = {
  companyId: Scalars['ID'];
};


export type QueryLeavesArgs = {
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
  type?: Maybe<Scalars['ID']>;
};


export type QueryManagedProjectsArgs = {
  actorFullAccess?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  groupValue?: Maybe<GroupBy>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryMemberArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryMemberAssignmentsArgs = {
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
  memberId: Scalars['ID'];
};


export type QueryMemberBalanceArgs = {
  companyId: Scalars['ID'];
};


export type QueryMemberIntegrationStatusesArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type QueryMemberLeaveRuleAccrualAmountsArgs = {
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
  memberId: Scalars['ID'];
};


export type QueryMemberProjectsDistributionArgs = {
  companyId: Scalars['ID'];
  interval: IPaginationInterval;
  memberId: Scalars['ID'];
};


export type QueryMembersArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
};


export type QueryMembersHoursArgs = {
  companyId: Scalars['ID'];
  exportType?: Maybe<ExportTarget>;
  includedTabs?: Maybe<Array<MembersHoursReportTabs>>;
  interval: IPaginationInterval;
};


export type QueryMembersIntegrationStatusesArgs = {
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryMembersListDataArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryPayrollEntitiesArgs = {
  companyId: Scalars['ID'];
  payrollId: Scalars['ID'];
  sortingData?: Maybe<ISorting>;
};


export type QueryPayrollsArgs = {
  companyId: Scalars['ID'];
};


export type QueryPermissionsArgs = {
  companyId: Scalars['ID'];
};


export type QueryPmMembersArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
};


export type QueryProfitabilityArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  data?: Maybe<IPaginationDashboard>;
  projectId: Scalars['ID'];
};


export type QueryProjectArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectAssignmentsArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationDashboard>;
  projectId: Scalars['ID'];
};


export type QueryProjectCommissionsArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectDocumentUrlArgs = {
  companyId: Scalars['ID'];
  download?: Maybe<Scalars['Boolean']>;
  projectDocumentId: Scalars['ID'];
};


export type QueryProjectDocumentsArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IProjectDocumentFilters>;
  projectId?: Maybe<Scalars['ID']>;
  searchByName?: Maybe<Scalars['String']>;
};


export type QueryProjectMembershipArgs = {
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectMembershipsArgs = {
  companyId: Scalars['ID'];
  data: IQueryMembership;
};


export type QueryProjectMilestonesArgs = {
  companyId: Scalars['ID'];
  includedInReport?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
};


export type QueryProjectMilestonesSectionArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectNotesArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectReportCustomizationArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectRolesOrderArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryProjectsArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  groupValue?: Maybe<GroupBy>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryProjectsAdminReportArgs = {
  companyId: Scalars['ID'];
  data: IPaginationInterval;
  exportType?: Maybe<ExportTarget>;
};


export type QueryProjectsAssignmentsArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationDashboard>;
};


export type QueryProjectsListDataArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryProjectsMarginsArgs = {
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryReportArgs = {
  companyId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type QueryReportMembersArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
};


export type QueryReportsArgs = {
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
  projectsId?: Maybe<Array<Scalars['ID']>>;
};


export type QueryRequestArgs = {
  companyId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type QueryRequestsArgs = {
  companyId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paginationData?: Maybe<IPaginationInterval>;
  requestFilter?: Maybe<RequestFilter>;
};


export type QueryResourcePlanningMembersArgs = {
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterResourcePlanningMembers>;
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
  searchValue?: Maybe<Scalars['String']>;
};


export type QueryResourcePlanningProjectsArgs = {
  companyId: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
  searchValue?: Maybe<Scalars['String']>;
};


export type QueryRevenueChartArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  data?: Maybe<IPaginationDashboard>;
  projectId: Scalars['ID'];
  projectType: ProjectType;
};


export type QueryRolesArgs = {
  companyId: Scalars['ID'];
};


export type QueryScenarioArgs = {
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
};


export type QueryScenarioMembersArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  companyId: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  paginationData?: Maybe<IPagination>;
  scenarioId: Scalars['ID'];
};


export type QueryScenariosArgs = {
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QuerySenioritiesArgs = {
  companyId: Scalars['ID'];
};


export type QuerySkillCategoriesArgs = {
  companyId: Scalars['ID'];
};


export type QuerySpecializationsArgs = {
  companyId: Scalars['ID'];
};


export type QueryTagsArgs = {
  companyId: Scalars['ID'];
};


export type QueryTeamDynamicsArgs = {
  companyId: Scalars['ID'];
  data?: Maybe<IPaginationDashboard>;
  projectId: Scalars['ID'];
};


export type QueryTimeLogsArgs = {
  companyId: Scalars['ID'];
  data: IQueryTimeLogs;
  paginationData?: Maybe<IPaginationTimeLogs>;
};


export type QueryTotalTimeLogsArgs = {
  companyId: Scalars['ID'];
  data: IQueryTimeLogs;
  paginationData?: Maybe<IPaginationTimeLogs>;
};


export type QueryUsersArgs = {
  companyId: Scalars['ID'];
};


export type QueryUtilizationReportArgs = {
  columns: Array<UtilizationReportCustomColumns>;
  companyId: Scalars['ID'];
  data: IPaginationInterval;
  filters?: Maybe<InsightFilters>;
};


export type QueryVendorsArgs = {
  companyId: Scalars['ID'];
};


export type QueryWorkingHoursInMonthArgs = {
  companyId: Scalars['ID'];
  end: Scalars['String'];
  start: Scalars['String'];
};

export enum RateUnit {
  Day = 'day',
  Hour = 'hour',
  Month = 'month'
}

export type Report = {
  __typename?: 'Report';
  countComments: Scalars['Int'];
  createdByMemberColor: Scalars['String'];
  createdByMemberName: Scalars['String'];
  created_at: Scalars['DateTime'];
  customization?: Maybe<Scalars['JSON']>;
  endDate: Scalars['DateTime'];
  holidays: Scalars['JSON'];
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  members: Array<ReportMember>;
  milestones?: Maybe<Array<ProjectMilestone>>;
  notes?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  project: ProjectLite;
  projectId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  status: ReportStatusType;
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type ReportCustomization = {
  __typename?: 'ReportCustomization';
  billableDaysNotation: BillableDaysNotation;
  created_at: Scalars['DateTime'];
  excludedMembers?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  leaveTypesDisplay?: Maybe<Scalars['JSON']>;
  leavesNotation: LeavesNotation;
  projectId: Scalars['ID'];
  reportColumns: Scalars['JSON'];
  updated_at: Scalars['DateTime'];
};

export type ReportMember = {
  __typename?: 'ReportMember';
  allocationTimeAmount: Scalars['Float'];
  assignmentId?: Maybe<Scalars['ID']>;
  billedLeavesBalance?: Maybe<Scalars['JSON']>;
  calculationType: AssignmentCalculationType;
  created_at: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  excludedDays?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  leaves: Scalars['JSON'];
  memberColor: Scalars['String'];
  memberId: Scalars['ID'];
  memberName: Scalars['String'];
  reportId: Scalars['ID'];
  reportMemberEntity: Array<ReportMemberEntity>;
  role?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type ReportMemberEntity = {
  __typename?: 'ReportMemberEntity';
  allocationTime: Scalars['Float'];
  created_at: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  rateAmount: Scalars['Float'];
  rateUnit: RateUnit;
  reportMemberId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  total: Scalars['Float'];
  updated_at: Scalars['DateTime'];
};

export enum ReportStatusType {
  Approved = 'approved',
  InvoicePaid = 'invoicePaid',
  InvoiceSent = 'invoiceSent',
  Pending = 'pending',
  ReadyForReview = 'readyForReview'
}

export enum ReportType {
  Bench = 'bench',
  Utilization = 'utilization'
}

export type Request = {
  __typename?: 'Request';
  changedStatusAt?: Maybe<Scalars['DateTime']>;
  changedStatusMemberName?: Maybe<Scalars['String']>;
  changes?: Maybe<Scalars['JSON']>;
  companyId: Scalars['ID'];
  createdBy: Scalars['ID'];
  createdByMemberColor: Scalars['String'];
  createdByMemberName: Scalars['String'];
  created_at: Scalars['DateTime'];
  data: Scalars['JSON'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  entityType: RequestDomains;
  id: Scalars['ID'];
  memberId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  requestAction: RequestAction;
  status: RequestStatusType;
  updated_at: Scalars['DateTime'];
};

export enum RequestAction {
  Create = 'create',
  Update = 'update'
}

export enum RequestDomains {
  Assignment = 'assignment',
  Client = 'client',
  Member = 'member',
  Project = 'project',
  Skill = 'skill'
}

export type RequestFilter = {
  action?: Maybe<RequestAction>;
  memberId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  status?: Maybe<RequestStatusType>;
  type?: Maybe<RequestDomains>;
};

export enum RequestStatusType {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending'
}

export type ResourcePlanningAssignment = {
  __typename?: 'ResourcePlanningAssignment';
  allocationTimeAmount: Scalars['Float'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  member: AssignmentMember;
  project_id: Scalars['ID'];
  role: Role;
  seniority?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type ResourcePlanningMember = {
  __typename?: 'ResourcePlanningMember';
  archived_at?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  company: CompanyLite;
  company_id: Scalars['ID'];
  costRateManagers: Array<MemberLite>;
  costRateManagersIds: Array<Scalars['ID']>;
  employment_type: EmploymentType;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  member_leave: Array<Leave>;
  projects: Array<ResourcePlanningProject>;
  reportingToColor?: Maybe<Scalars['String']>;
  reportingToId?: Maybe<Scalars['ID']>;
  reportingToMemberName?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
};

export type ResourcePlanningProject = {
  __typename?: 'ResourcePlanningProject';
  assignment: Array<Assignment>;
  color: Scalars['String'];
  id: Scalars['ID'];
  member: MemberLite;
  name: Scalars['String'];
  requests: Array<Request>;
  type: Scalars['String'];
};

export type ResourcePlanningProjectAssignment = {
  __typename?: 'ResourcePlanningProjectAssignment';
  archived_at?: Maybe<Scalars['DateTime']>;
  assignments: Array<ResourcePlanningAssignment>;
  client?: Maybe<Client>;
  color: Scalars['String'];
  end_date: Scalars['DateTime'];
  fee_currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pm: MemberLite;
  start_date: Scalars['DateTime'];
  type: Scalars['String'];
};

export type RevenueChart = {
  __typename?: 'RevenueChart';
  nameOfMonth: Scalars['String'];
  totalActual: Scalars['Float'];
  totalPaid: Scalars['Float'];
  totalScheduled: Scalars['Float'];
  totalUnpaid: Scalars['Float'];
};

export type Role = {
  __typename?: 'Role';
  company_id: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type RuleMembersBalance = {
  __typename?: 'RuleMembersBalance';
  leaveTypes: Array<Scalars['String']>;
  members: Array<BalanceMember>;
  ruleId: Scalars['ID'];
};

export type Scenario = {
  __typename?: 'Scenario';
  activeMembers: Scalars['Int'];
  archived_at?: Maybe<Scalars['DateTime']>;
  bookedLeaves: Scalars['Boolean'];
  commissions?: Maybe<Scalars['JSON']>;
  createdBy: Scalars['ID'];
  createdByMemberColor?: Maybe<Scalars['String']>;
  createdByMemberName: Scalars['String'];
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  exchangeRateAmount?: Maybe<Scalars['Float']>;
  exchangeRateCurrency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leavesForecast: Scalars['Int'];
  members: Array<ScenarioMembers>;
  project: ProjectLite;
  projectId: Scalars['ID'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};


export type ScenarioMembersArgs = {
  companyCurrency?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  paginationData?: Maybe<IPagination>;
};

export enum ScenarioAction {
  Archive = 'archive',
  Restore = 'restore'
}

export type ScenarioMembers = {
  __typename?: 'ScenarioMembers';
  allocationTimeAmount: Scalars['Float'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  externalRateAmount: Scalars['Int'];
  externalRateUnit: RateUnit;
  id: Scalars['ID'];
  internalRateAmount?: Maybe<Scalars['Int']>;
  internalRateUnit?: Maybe<RateUnit>;
  member?: Maybe<MemberLite>;
  memberId?: Maybe<Scalars['ID']>;
  role: Role;
  roleId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  scenarioMemberEntity: Array<ScenarioMembersEntity>;
  seniority?: Maybe<Scalars['String']>;
  seniorityId?: Maybe<Scalars['ID']>;
  startDate: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type ScenarioMembersEntity = {
  __typename?: 'ScenarioMembersEntity';
  actualAllocationPerCent?: Maybe<Scalars['Float']>;
  allocationPerCent: Scalars['Float'];
  allocationTimeAmount?: Maybe<Scalars['Float']>;
  allocationTimeAmountOrigin: Scalars['Float'];
  commission?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Int']>;
  costOrigin: Scalars['Int'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  externalRateAmount: Scalars['Int'];
  externalRateUnit: RateUnit;
  id: Scalars['ID'];
  internalRateAmount?: Maybe<Scalars['Int']>;
  internalRateUnit?: Maybe<RateUnit>;
  leavesHours?: Maybe<Scalars['Float']>;
  leavesPercent?: Maybe<Scalars['Float']>;
  nameOfMonth: Scalars['String'];
  profit: Scalars['Int'];
  profitMargin: Scalars['Float'];
  revenue?: Maybe<Scalars['Int']>;
  revenueOrigin: Scalars['Int'];
  scenarioMembersId: Scalars['ID'];
  startDate: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  workingHoursInMonth?: Maybe<Scalars['Int']>;
};

export type SelfAssessment = {
  __typename?: 'SelfAssessment';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  projects: Array<SelfAssessmentProject>;
  skills: Array<SelfAssessmentSkill>;
  specializationId: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type SelfAssessmentProject = {
  __typename?: 'SelfAssessmentProject';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  project: Scalars['String'];
  responsibilities: Scalars['String'];
  role: Scalars['String'];
  selfAssessmentId: Scalars['ID'];
  techStack: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type SelfAssessmentSkill = {
  __typename?: 'SelfAssessmentSkill';
  assessment?: Maybe<Scalars['Int']>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  selfAssessmentId: Scalars['ID'];
  skillId: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export type Skill = {
  __typename?: 'Skill';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  skillCategoryId: Scalars['ID'];
  skillCategoryName: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type SkillCategory = {
  __typename?: 'SkillCategory';
  companyId: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  skills: Array<Skill>;
  updated_at: Scalars['DateTime'];
};

export enum SortBy {
  FirstName = 'first_name',
  JoinDate = 'join_date',
  LastName = 'last_name'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Specialization = {
  __typename?: 'Specialization';
  company_id: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  skillCategories: Array<SkillCategory>;
  updated_at: Scalars['DateTime'];
};

export type SpecializationSkill = {
  __typename?: 'SpecializationSkill';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  skillCategoryId: Scalars['ID'];
  specializationId: Scalars['ID'];
  updated_at: Scalars['DateTime'];
};

export enum SyncStatus {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success'
}

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['HexColorCode'];
  companyId: Scalars['String'];
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type TeamDynamics = {
  __typename?: 'TeamDynamics';
  nameOfMonth: Scalars['String'];
  roles: Array<TeamDynamicsRole>;
};

export type TeamDynamicsRole = {
  __typename?: 'TeamDynamicsRole';
  contractors?: Maybe<Scalars['Int']>;
  employees?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  roleName: Scalars['String'];
};

export type TimeLog = {
  __typename?: 'TimeLog';
  assignment_id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  date: Scalars['String'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member_id: Scalars['ID'];
  minutes: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  overtime?: Maybe<Scalars['Boolean']>;
  overtimeMultiplier?: Maybe<Scalars['Float']>;
  reportMemberId?: Maybe<Scalars['ID']>;
  updated_at: Scalars['DateTime'];
};

export type TotalTimeLog = {
  __typename?: 'TotalTimeLog';
  assignment_id: Scalars['ID'];
  date: Scalars['String'];
  member_id: Scalars['ID'];
  totalMinutes: Scalars['Int'];
};


export type UtilizationMember = {
  __typename?: 'UtilizationMember';
  actualCapacity: Scalars['Float'];
  billableHours: Scalars['Float'];
  billableUtilization?: Maybe<Scalars['Float']>;
  capacity: Scalars['Float'];
  leavesHours: Scalars['Float'];
  loggedHours?: Maybe<Scalars['Float']>;
  memberColor: Scalars['String'];
  memberId: Scalars['ID'];
  memberName?: Maybe<Scalars['String']>;
  nonBillableHours: Scalars['Float'];
  nonBillableUtilization?: Maybe<Scalars['Float']>;
  nonscheduledHours?: Maybe<Scalars['Float']>;
  nonscheduledPercent?: Maybe<Scalars['Float']>;
  overtimeHours?: Maybe<Scalars['Float']>;
  paidLeavesHours?: Maybe<Scalars['Float']>;
  period: Scalars['String'];
  scheduledHours?: Maybe<Scalars['Float']>;
  unpaidLeavesHours?: Maybe<Scalars['Float']>;
  utilization?: Maybe<Scalars['Float']>;
};

export type UtilizationReport = {
  __typename?: 'UtilizationReport';
  actualCapacity: Scalars['Float'];
  members: Array<UtilizationMember>;
  nameOfPeriod: Scalars['String'];
  totalBillable: Scalars['Float'];
  totalCapacity: Scalars['Float'];
  totalLeaves: Scalars['Float'];
  totalNonBillable: Scalars['Float'];
  unassignedHours: Scalars['Float'];
};

export enum UtilizationReportCustomColumns {
  ActualCapacity = 'actualCapacity',
  BillableHours = 'billableHours',
  BillableUtilization = 'billableUtilization',
  LeavesHours = 'leavesHours',
  LoggedHours = 'loggedHours',
  NonBillableHours = 'nonBillableHours',
  NonBillableUtilization = 'nonBillableUtilization',
  NonscheduledHours = 'nonscheduledHours',
  NonscheduledPercent = 'nonscheduledPercent',
  OvertimeHours = 'overtimeHours',
  PaidLeavesHours = 'paidLeavesHours',
  ScheduledHours = 'scheduledHours',
  TotalCapacity = 'totalCapacity',
  UnpaidLeavesHours = 'unpaidLeavesHours',
  Utilization = 'utilization'
}

export type Vendor = {
  __typename?: 'Vendor';
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type WorkingHoursInMonth = {
  __typename?: 'WorkingHoursInMonth';
  hours: Scalars['Int'];
  month: Scalars['String'];
};

export type LeaveQueryVariables = Exact<{
  companyId: Scalars['ID'];
  leaveId: Scalars['ID'];
}>;


export type LeaveQuery = (
  { __typename?: 'Query' }
  & { leave?: Maybe<(
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'startDate' | 'notes' | 'endDate' | 'memberFirstName' | 'memberLastName' | 'memberId'>
    & { leaveType?: Maybe<(
      { __typename?: 'LeaveType' }
      & Pick<LeaveType, 'id' | 'name'>
    )> }
  )> }
);

export type AssignmentDataFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, 'id' | 'endDate' | 'seniority' | 'seniorityId' | 'startDate' | 'allocationTimeAmount' | 'bill_amount_calculation_type' | 'billable'>
  & { member: (
    { __typename?: 'Member' }
    & MemberAssignmentDataFragment
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  ), project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'color' | 'type' | 'feeCurrencyId' | 'fee_currency' | 'unit'>
  ) }
);

export type BillableLeaveRuleDataFragment = (
  { __typename?: 'BillableLeaveRule' }
  & Pick<BillableLeaveRule, 'projectId' | 'limit' | 'id' | 'accrualDate' | 'accrualType' | 'created_at'>
  & { leaveTypes: Array<(
    { __typename?: 'LeaveType' }
    & Pick<LeaveType, 'name' | 'id'>
  )> }
);

export type ReportMemberEntityDataFragment = (
  { __typename?: 'ReportMemberEntity' }
  & Pick<ReportMemberEntity, 'allocationTime' | 'endDate' | 'id' | 'rateAmount' | 'rateUnit' | 'startDate' | 'total'>
);

export type ReportMemberDataFragment = (
  { __typename?: 'ReportMember' }
  & Pick<ReportMember, 'allocationTimeAmount' | 'assignmentId' | 'endDate' | 'excludedDays' | 'id' | 'leaves' | 'memberColor' | 'memberId' | 'memberName' | 'reportId' | 'calculationType' | 'role' | 'seniority' | 'startDate'>
  & { reportMemberEntity: Array<(
    { __typename?: 'ReportMemberEntity' }
    & ReportMemberEntityDataFragment
  )> }
);

export type ReportDataFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'endDate' | 'holidays' | 'id' | 'memberId' | 'startDate' | 'status' | 'title' | 'created_at' | 'updated_at' | 'paymentDate' | 'paidAmount' | 'customization' | 'countComments'>
  & { members: Array<(
    { __typename?: 'ReportMember' }
    & Pick<ReportMember, 'billedLeavesBalance'>
    & ReportMemberDataFragment
  )>, project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'color' | 'id' | 'name' | 'fee_currency' | 'type'>
  ) }
);

export type ReportDataWithClientFragment = (
  { __typename?: 'Report' }
  & { project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'color' | 'id' | 'name' | 'fee_currency'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
    )> }
  ) }
  & ReportDataFragment
);

export type ReportDataWithPmFragment = (
  { __typename?: 'Report' }
  & { project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'color' | 'id' | 'name' | 'fee_currency' | 'archived_at'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id'>
    )>, pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
    ) }
  ) }
  & ReportDataFragment
);

export type ReportItemDataFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'endDate' | 'id' | 'memberId' | 'startDate' | 'status' | 'title' | 'created_at' | 'updated_at' | 'paymentDate' | 'paidAmount' | 'countComments'>
  & { project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'color' | 'id' | 'name' | 'type' | 'fee_currency' | 'start_date' | 'end_date' | 'archived_at'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id'>
    )>, pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
    ) }
  ) }
);

export type CommentDataFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'companyId' | 'createdByMemberColor' | 'createdByMemberName' | 'created_at' | 'entityId' | 'entityType' | 'id' | 'note' | 'updated_at' | 'memberId'>
);

export type ExternalRateDataFragment = (
  { __typename?: 'ExternalRate' }
  & Pick<ExternalRate, 'id' | 'projectId' | 'roleId' | 'start_date' | 'end_date' | 'rate_amount' | 'unit' | 'seniorityId' | 'seniority' | 'created_at'>
  & { role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  ) }
);

export type FileFolderDataFragment = (
  { __typename?: 'FileFolder' }
  & Pick<FileFolder, 'createdByMemberColor' | 'createdByMemberName' | 'created_at' | 'id' | 'name' | 'projectColor' | 'projectId' | 'projectName' | 'parentFolder'>
);

export type CompanyIntegrationSyncDataFragment = (
  { __typename?: 'CompanyIntegrationSync' }
  & Pick<CompanyIntegrationSync, 'id' | 'companyIntegrationId' | 'integrationOptionId' | 'integrationOption' | 'lastSyncAt' | 'lastSyncStatus' | 'syncByMember'>
  & { syncOption?: Maybe<(
    { __typename?: 'IntegrationOptionSyncValues' }
    & Pick<IntegrationOptionSyncValues, 'id'>
  )> }
);

export type BalanceMemberDataFragment = (
  { __typename?: 'BalanceMember' }
  & Pick<BalanceMember, 'period' | 'limit' | 'last_name' | 'memberId' | 'first_name' | 'color' | 'balance' | 'accrualMonth'>
);

export type MemberLeaveRuleAccrualAmountDataFragment = (
  { __typename?: 'MemberLeaveRuleAccrualAmount' }
  & Pick<MemberLeaveRuleAccrualAmount, 'accrualDate' | 'notes' | 'updated_at'>
);

export type RuleMembersBalanceDataFragment = (
  { __typename?: 'RuleMembersBalance' }
  & Pick<RuleMembersBalance, 'leaveTypes' | 'ruleId'>
  & { members: Array<(
    { __typename?: 'BalanceMember' }
    & BalanceMemberDataFragment
  )> }
);

export type BillableLeaveRuleMonthBalanceDataFragment = (
  { __typename?: 'BillableLeaveRuleMonthBalance' }
  & Pick<BillableLeaveRuleMonthBalance, 'month' | 'billedLeaves' | 'balance' | 'accrualAmount'>
);

export type MemberBalanceAccrualMonthDataFragment = (
  { __typename?: 'MemberBalanceAccrualMonth' }
  & Pick<MemberBalanceAccrualMonth, 'accrualDate' | 'id' | 'memberId'>
);

export type MemberDataFragment = (
  { __typename?: 'CompanyUser' }
  & Pick<CompanyUser, 'id' | 'type'>
  & { member: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'email' | 'first_name' | 'last_name' | 'color'>
  ), company: (
    { __typename?: 'CompanyLite' }
    & Pick<CompanyLite, 'id' | 'name' | 'primaryCurrencyId'>
  ) }
);

export type ProjectCommissionDataFragment = (
  { __typename?: 'ProjectCommission' }
  & Pick<ProjectCommission, 'commission' | 'effectiveFrom' | 'id'>
);

export type ProjectDocumentDataFragment = (
  { __typename?: 'ProjectDocument' }
  & Pick<ProjectDocument, 'id' | 'projectId' | 'companyId' | 'documentId' | 'createdBy' | 'createdByMemberColor' | 'createdByMemberName' | 'projectColor' | 'projectName' | 'created_at'>
  & { document: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'companyId' | 'name' | 'url' | 'size'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & TagDataFragment
  )> }
);

export type ProjectMilestoneDataFragment = (
  { __typename?: 'ProjectMilestone' }
  & Pick<ProjectMilestone, 'amount' | 'created_at' | 'id' | 'notes' | 'paymentDate' | 'projectId' | 'reportId' | 'title'>
);

export type ProjectMilestoneSectionDataFragment = (
  { __typename?: 'ProjectMilestoneSection' }
  & Pick<ProjectMilestoneSection, 'id' | 'actualCommission' | 'actualRevenue' | 'cost' | 'otherCost' | 'totalCost' | 'notes' | 'amount' | 'paymentDate' | 'plannedCommission' | 'plannedRevenue' | 'profit' | 'profitMargin' | 'status' | 'title'>
);

export type RequestDataFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'id' | 'entityType' | 'createdBy' | 'createdByMemberName' | 'createdByMemberColor' | 'companyId' | 'status' | 'requestAction' | 'changes' | 'data' | 'entityId' | 'memberId' | 'note' | 'changedStatusAt' | 'changedStatusMemberName' | 'created_at'>
);

export type TagDataFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'color' | 'id' | 'name'>
);

export type ArchiveMemberMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type ArchiveMemberMutation = (
  { __typename?: 'Mutation' }
  & { archiveMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  ) }
);

export type ArchiveProjectMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ArchiveProjectMutation = (
  { __typename?: 'Mutation' }
  & { archiveProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type CreateAssignmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IAssignment;
}>;


export type CreateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createAssignment: (
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id' | 'seniorityId' | 'bill_amount_calculation_type' | 'billable'>
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  remember?: Maybe<Scalars['Boolean']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
    & { me: Array<(
      { __typename?: 'CompanyUserLite' }
      & Pick<CompanyUserLite, 'id' | 'type'>
      & { member: (
        { __typename?: 'MemberLite' }
        & Pick<MemberLite, 'id' | 'email' | 'first_name' | 'last_name' | 'color'>
      ), company: (
        { __typename?: 'CompanyLite' }
        & Pick<CompanyLite, 'id' | 'name' | 'primaryCurrencyId'>
      ) }
    )> }
  ) }
);

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = (
  { __typename?: 'Mutation' }
  & { refresh: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
    & { me: Array<(
      { __typename?: 'CompanyUserLite' }
      & Pick<CompanyUserLite, 'id'>
      & { member: (
        { __typename?: 'MemberLite' }
        & Pick<MemberLite, 'id' | 'email' | 'first_name' | 'last_name'>
      ), company: (
        { __typename?: 'CompanyLite' }
        & Pick<CompanyLite, 'id' | 'name' | 'primaryCurrencyId'>
      ) }
    )> }
  ) }
);

export type ChangePasswordFromEmailMutationVariables = Exact<{
  password: Scalars['String'];
  resetId: Scalars['ID'];
  secret: Scalars['String'];
}>;


export type ChangePasswordFromEmailMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordFromEmail: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
    & { me: Array<(
      { __typename?: 'CompanyUserLite' }
      & Pick<CompanyUserLite, 'id'>
      & { member: (
        { __typename?: 'MemberLite' }
        & Pick<MemberLite, 'id' | 'email' | 'first_name' | 'last_name'>
      ), company: (
        { __typename?: 'CompanyLite' }
        & Pick<CompanyLite, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateClientMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IClient;
}>;


export type CreateClientMutation = (
  { __typename?: 'Mutation' }
  & { createClient: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  ) }
);

export type CreateBillableLeaveRuleMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IBillableLeaveRule;
  projectId: Scalars['ID'];
}>;


export type CreateBillableLeaveRuleMutation = (
  { __typename?: 'Mutation' }
  & { createBillableLeaveRule: (
    { __typename?: 'BillableLeaveRule' }
    & BillableLeaveRuleDataFragment
  ) }
);

export type CreateCompensationTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICompensationType;
}>;


export type CreateCompensationTypeMutation = (
  { __typename?: 'Mutation' }
  & { createCompensationType: (
    { __typename?: 'CompensationType' }
    & Pick<CompensationType, 'id' | 'name'>
  ) }
);

export type CreateExpenseMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IExpense;
}>;


export type CreateExpenseMutation = (
  { __typename?: 'Mutation' }
  & { createExpense: (
    { __typename?: 'Expense' }
    & Pick<Expense, 'id' | 'isBillable' | 'notes'>
  ) }
);

export type CreateExpenseTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IExpenseType;
}>;


export type CreateExpenseTypeMutation = (
  { __typename?: 'Mutation' }
  & { createExpenseType: (
    { __typename?: 'ExpenseType' }
    & Pick<ExpenseType, 'id' | 'name'>
  ) }
);

export type CreateExternalRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  data: IExternalRate;
  seniorityId?: Maybe<Scalars['ID']>;
}>;


export type CreateExternalRateMutation = (
  { __typename?: 'Mutation' }
  & { createExternalRate: (
    { __typename?: 'ExternalRate' }
    & ExternalRateDataFragment
  ) }
);

export type CreateFileFolderMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IFileFolder;
  projectId: Scalars['ID'];
}>;


export type CreateFileFolderMutation = (
  { __typename?: 'Mutation' }
  & { createFileFolder: (
    { __typename?: 'FileFolder' }
    & FileFolderDataFragment
  ) }
);

export type CreateHolidayMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IHoliday;
}>;


export type CreateHolidayMutation = (
  { __typename?: 'Mutation' }
  & { createHoliday: (
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id'>
  ) }
);

export type CreateIntegrationConnectionMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICompanyIntegration;
}>;


export type CreateIntegrationConnectionMutation = (
  { __typename?: 'Mutation' }
  & { createIntegrationConnection: (
    { __typename?: 'CompanyIntegration' }
    & CompanyIntegrationDataFragment
  ) }
);

export type CreateLeaveMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  data: ILeave;
}>;


export type CreateLeaveMutation = (
  { __typename?: 'Mutation' }
  & { createLeave: (
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'notes'>
  ) }
);

export type CreateMemberProjectsMembershipMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMemberProjectMembership;
  memberId: Scalars['ID'];
}>;


export type CreateMemberProjectsMembershipMutation = (
  { __typename?: 'Mutation' }
  & { createMemberProjectsMembership: Array<(
    { __typename?: 'ProjectMembership' }
    & ProjectMembershipDataFragment
  )> }
);

export type CreateProjectCommissionMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectCommission;
  projectId: Scalars['ID'];
}>;


export type CreateProjectCommissionMutation = (
  { __typename?: 'Mutation' }
  & { createProjectCommission: (
    { __typename?: 'ProjectCommission' }
    & ProjectCommissionDataFragment
  ) }
);

export type CreateProjectDocumentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICreateProjectDocument;
}>;


export type CreateProjectDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createProjectDocument: (
    { __typename?: 'ProjectDocument' }
    & ProjectDocumentDataFragment
  ) }
);

export type CreateProjectMembershipMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectMembership;
}>;


export type CreateProjectMembershipMutation = (
  { __typename?: 'Mutation' }
  & { createProjectMembership: Array<(
    { __typename?: 'ProjectMembership' }
    & ProjectMembershipDataFragment
  )> }
);

export type CreateProjectMilestoneMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectMilestone;
  projectId: Scalars['ID'];
}>;


export type CreateProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { createProjectMilestone: (
    { __typename?: 'ProjectMilestone' }
    & ProjectMilestoneDataFragment
  ) }
);

export type CreateMemberRoleMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IRole;
}>;


export type CreateMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  ) }
);

export type CreateReportMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IReport;
}>;


export type CreateReportMutation = (
  { __typename?: 'Mutation' }
  & { createReport: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type CreateRequestMutationVariables = Exact<{
  companyId: Scalars['ID'];
  entityType: RequestDomains;
  data: ICreateRequest;
  requestAction: RequestAction;
  entityId?: Maybe<Scalars['ID']>;
}>;


export type CreateRequestMutation = (
  { __typename?: 'Mutation' }
  & { createRequest: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type CreateSeniorityMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMemberSeniority;
}>;


export type CreateSeniorityMutation = (
  { __typename?: 'Mutation' }
  & { createSeniority: (
    { __typename?: 'MemberSeniority' }
    & Pick<MemberSeniority, 'id' | 'name'>
  ) }
);

export type CreateMemberSpecializationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ISpecialization;
}>;


export type CreateMemberSpecializationMutation = (
  { __typename?: 'Mutation' }
  & { createSpecialization: (
    { __typename?: 'Specialization' }
    & Pick<Specialization, 'id' | 'name'>
  ) }
);

export type CreateTagMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ITag;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag: (
    { __typename?: 'Tag' }
    & TagDataFragment
  ) }
);

export type CreateVendorMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IVendor;
}>;


export type CreateVendorMutation = (
  { __typename?: 'Mutation' }
  & { createVendor: (
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name'>
  ) }
);

export type DeactivateUserMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type DeactivateUserMutation = (
  { __typename?: 'Mutation' }
  & { deactivateUser: (
    { __typename?: 'CompanyUser' }
    & Pick<CompanyUser, 'id' | 'deactivatedAt' | 'status'>
  ) }
);

export type DeleteAssignmentMutationVariables = Exact<{
  assignmentsIds: Array<Scalars['ID']> | Scalars['ID'];
  companyId: Scalars['ID'];
}>;


export type DeleteAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAssignment: Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id'>
  )> }
);

export type DeleteBillableLeaveRuleMutationVariables = Exact<{
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteBillableLeaveRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteBillableLeaveRule: (
    { __typename?: 'BillableLeaveRule' }
    & Pick<BillableLeaveRule, 'id'>
  ) }
);

export type DeleteCompanyIntegrationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
}>;


export type DeleteCompanyIntegrationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyIntegration: (
    { __typename?: 'CompanyIntegration' }
    & Pick<CompanyIntegration, 'id'>
  ) }
);

export type DeleteCompensationTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  compensationTypeId: Scalars['ID'];
}>;


export type DeleteCompensationTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompensationType: (
    { __typename?: 'CompensationType' }
    & Pick<CompensationType, 'id'>
  ) }
);

export type DeleteCostRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  costRateId: Scalars['ID'];
}>;


export type DeleteCostRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCostRate: (
    { __typename?: 'CostRate' }
    & Pick<CostRate, 'id'>
  ) }
);

export type DeleteExpenseMutationVariables = Exact<{
  companyId: Scalars['ID'];
  expenseId: Scalars['ID'];
}>;


export type DeleteExpenseMutation = (
  { __typename?: 'Mutation' }
  & { deleteExpense: (
    { __typename?: 'Expense' }
    & Pick<Expense, 'id'>
  ) }
);

export type DeleteExpenseTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  expenseTypeId: Scalars['ID'];
}>;


export type DeleteExpenseTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteExpenseType: (
    { __typename?: 'ExpenseType' }
    & Pick<ExpenseType, 'id'>
  ) }
);

export type DeleteExternalRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  externalRateId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteExternalRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteExternalRate: (
    { __typename?: 'ExternalRate' }
    & Pick<ExternalRate, 'id'>
  ) }
);

export type DeleteFileFolderMutationVariables = Exact<{
  companyId: Scalars['ID'];
  fileFolderId: Scalars['ID'];
  projectId: Scalars['ID'];
  removeContents?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteFileFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFileFolder: (
    { __typename?: 'FileFolder' }
    & Pick<FileFolder, 'id'>
  ) }
);

export type DeleteHolidayMutationVariables = Exact<{
  companyId: Scalars['ID'];
  holidayId: Scalars['ID'];
}>;


export type DeleteHolidayMutation = (
  { __typename?: 'Mutation' }
  & { deleteHoliday: (
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id'>
  ) }
);

export type DeleteInvitationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  deleteInvitationId: Scalars['String'];
}>;


export type DeleteInvitationMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvitation: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id'>
  ) }
);

export type DeleteLeaveMutationVariables = Exact<{
  companyId: Scalars['ID'];
  leaveId: Scalars['ID'];
}>;


export type DeleteLeaveMutation = (
  { __typename?: 'Mutation' }
  & { deleteLeave: (
    { __typename?: 'Leave' }
    & Pick<Leave, 'id'>
  ) }
);

export type DeleteProjectCommissionMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectCommissionId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteProjectCommissionMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectCommission: (
    { __typename?: 'ProjectCommission' }
    & Pick<ProjectCommission, 'id'>
  ) }
);

export type DeleteProjectDocumentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  projectDocumentId: Scalars['ID'];
}>;


export type DeleteProjectDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectDocument: (
    { __typename?: 'ProjectDocument' }
    & Pick<ProjectDocument, 'id'>
  ) }
);

export type DeleteProjectMembershipMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  projectMembershipId: Scalars['ID'];
}>;


export type DeleteProjectMembershipMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectMembership: (
    { __typename?: 'ProjectMembership' }
    & Pick<ProjectMembership, 'id'>
  ) }
);

export type DeleteProjectMilestoneMutationVariables = Exact<{
  projectMilestoneId: Scalars['ID'];
  companyId: Scalars['ID'];
}>;


export type DeleteProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectMilestone: (
    { __typename?: 'ProjectMilestone' }
    & Pick<ProjectMilestone, 'id'>
  ) }
);

export type DeleteMemberRoleMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberRoleId: Scalars['ID'];
}>;


export type DeleteMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteReport: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type DeleteRequestsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  requestsId: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteRequestsMutation = (
  { __typename?: 'Mutation' }
  & { deleteRequests: Array<(
    { __typename?: 'Request' }
    & Pick<Request, 'id' | 'status'>
  )> }
);

export type DeleteSeniorityMutationVariables = Exact<{
  companyId: Scalars['ID'];
  seniorityId: Scalars['ID'];
}>;


export type DeleteSeniorityMutation = (
  { __typename?: 'Mutation' }
  & { deleteSeniority: (
    { __typename?: 'MemberSeniority' }
    & Pick<MemberSeniority, 'id'>
  ) }
);

export type DeleteMemberSpecializationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberSpecializationId: Scalars['ID'];
}>;


export type DeleteMemberSpecializationMutation = (
  { __typename?: 'Mutation' }
  & { deleteSpecialization: (
    { __typename?: 'Specialization' }
    & Pick<Specialization, 'id'>
  ) }
);

export type DeleteTagMutationVariables = Exact<{
  companyId: Scalars['ID'];
  tagId: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteTag: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  ) }
);

export type EditAssignmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  assignmentId: Scalars['ID'];
  data: IAssignment;
}>;


export type EditAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { editAssignment: (
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id' | 'bill_amount_calculation_type' | 'startDate' | 'endDate' | 'allocationTimeAmount' | 'seniority' | 'seniorityId' | 'billable'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    ), member: (
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'first_name' | 'last_name'>
    ), role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    ) }
  ) }
);

export type EditAssignmentsMutationVariables = Exact<{
  assignmentsIds: Array<Scalars['ID']> | Scalars['ID'];
  companyId: Scalars['ID'];
  data: IEditAssignments;
}>;


export type EditAssignmentsMutation = (
  { __typename?: 'Mutation' }
  & { editAssignments: Array<(
    { __typename?: 'Assignment' }
    & AssignmentDataFragment
  )> }
);

export type EditBillableLeaveRuleMutationVariables = Exact<{
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  data: IBillableLeaveRule;
  projectId: Scalars['ID'];
}>;


export type EditBillableLeaveRuleMutation = (
  { __typename?: 'Mutation' }
  & { editBillableLeaveRule: (
    { __typename?: 'BillableLeaveRule' }
    & BillableLeaveRuleDataFragment
  ) }
);

export type EditCompanyIntegrationOptionsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
  integrationOptions: Array<IIntegrationSyncOption> | IIntegrationSyncOption;
}>;


export type EditCompanyIntegrationOptionsMutation = (
  { __typename?: 'Mutation' }
  & { editCompanyIntegrationOptions: Array<(
    { __typename?: 'CompanyIntegrationSync' }
    & CompanyIntegrationSyncDataFragment
  )> }
);

export type EditCompanyIntegrationStatusMutationVariables = Exact<{
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
  status: IntegrationStatus;
  dateFormat?: Maybe<Scalars['String']>;
}>;


export type EditCompanyIntegrationStatusMutation = (
  { __typename?: 'Mutation' }
  & { editCompanyIntegrationStatus: (
    { __typename?: 'CompanyIntegration' }
    & CompanyIntegrationDataFragment
  ) }
);

export type EditCompensationTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  compensationTypeId: Scalars['ID'];
  data: ICompensationType;
}>;


export type EditCompensationTypeMutation = (
  { __typename?: 'Mutation' }
  & { editCompensationType: (
    { __typename?: 'CompensationType' }
    & Pick<CompensationType, 'id' | 'name'>
  ) }
);

export type EditExpenseTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IExpenseType;
  expenseTypeId: Scalars['ID'];
}>;


export type EditExpenseTypeMutation = (
  { __typename?: 'Mutation' }
  & { editExpenseType: (
    { __typename?: 'ExpenseType' }
    & Pick<ExpenseType, 'id' | 'name'>
  ) }
);

export type EditExternalRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IExternalRate;
  externalRateId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type EditExternalRateMutation = (
  { __typename?: 'Mutation' }
  & { editExternalRate: (
    { __typename?: 'ExternalRate' }
    & ExternalRateDataFragment
  ) }
);

export type EditExternalRateSeniorityMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IEditExternalRateSeniority;
}>;


export type EditExternalRateSeniorityMutation = (
  { __typename?: 'Mutation' }
  & { editExternalRateSeniority: Array<(
    { __typename?: 'ExternalRate' }
    & ExternalRateDataFragment
  )> }
);

export type EditFileFolderMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IFileFolder;
  fileFolderId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type EditFileFolderMutation = (
  { __typename?: 'Mutation' }
  & { editFileFolder: (
    { __typename?: 'FileFolder' }
    & FileFolderDataFragment
  ) }
);

export type EditHolidayMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IHoliday;
  holidayId: Scalars['ID'];
}>;


export type EditHolidayMutation = (
  { __typename?: 'Mutation' }
  & { editHoliday: (
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'name' | 'date'>
  ) }
);

export type EditLeaveMutationVariables = Exact<{
  companyId: Scalars['ID'];
  leaveId: Scalars['ID'];
  data: ILeave;
}>;


export type EditLeaveMutation = (
  { __typename?: 'Mutation' }
  & { editLeave: (
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate' | 'notes'>
    & { leaveType?: Maybe<(
      { __typename?: 'LeaveType' }
      & Pick<LeaveType, 'id'>
    )> }
  ) }
);

export type EditProjectCommissionMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectCommission;
  projectCommissionId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type EditProjectCommissionMutation = (
  { __typename?: 'Mutation' }
  & { editProjectCommission: (
    { __typename?: 'ProjectCommission' }
    & ProjectCommissionDataFragment
  ) }
);

export type EditProjectDocumentTagsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectDocumentTags;
}>;


export type EditProjectDocumentTagsMutation = (
  { __typename?: 'Mutation' }
  & { editProjectDocumentTags: Array<(
    { __typename?: 'ProjectDocumentTags' }
    & Pick<ProjectDocumentTags, 'id' | 'projectDocumentId' | 'tagId' | 'created_at' | 'updated_at'>
  )> }
);

export type EditProjectMembershipMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IEditProjectMembership;
  projectId: Scalars['ID'];
  projectMembershipId: Scalars['ID'];
}>;


export type EditProjectMembershipMutation = (
  { __typename?: 'Mutation' }
  & { editProjectMembership: (
    { __typename?: 'ProjectMembership' }
    & ProjectMembershipDataFragment
  ) }
);

export type EditProjectMilestoneMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectMilestone;
  projectMilestoneId: Scalars['ID'];
}>;


export type EditProjectMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { editProjectMilestone: (
    { __typename?: 'ProjectMilestone' }
    & ProjectMilestoneDataFragment
  ) }
);

export type EditProjectPmFieldMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMemberProjectMembership;
  memberId: Scalars['ID'];
}>;


export type EditProjectPmFieldMutation = (
  { __typename?: 'Mutation' }
  & { editProjectPMField: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'color' | 'type'>
    & { pmFinAccess?: Maybe<(
      { __typename?: 'PmProjectMembership' }
      & Pick<PmProjectMembership, 'accessLevel' | 'endDate' | 'startDate'>
    )>, pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
    ) }
  )> }
);

export type EditMemberRoleMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberRoleId: Scalars['ID'];
  data: IRole;
}>;


export type EditMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { editRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  ) }
);

export type EditReportMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IEditReport;
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type EditReportMutation = (
  { __typename?: 'Mutation' }
  & { editReport: (
    { __typename?: 'Report' }
    & ReportDataFragment
  ) }
);

export type EditReportMemberLogsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IReportMemberLogs;
  reportMemberId: Scalars['ID'];
}>;


export type EditReportMemberLogsMutation = (
  { __typename?: 'Mutation' }
  & { editReportMemberLogs: (
    { __typename?: 'ReportMember' }
    & Pick<ReportMember, 'id'>
    & { reportMemberEntity: Array<(
      { __typename?: 'ReportMemberEntity' }
      & ReportMemberEntityDataFragment
    )> }
  ) }
);

export type EditReportBillableDaysMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: Array<IReportBillableDays> | IReportBillableDays;
  reportId: Scalars['ID'];
}>;


export type EditReportBillableDaysMutation = (
  { __typename?: 'Mutation' }
  & { editReportBillableDays: Array<(
    { __typename?: 'ReportMember' }
    & ReportMemberDataFragment
  )> }
);

export type EditReportMembersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  reportId: Scalars['ID'];
  includedMembers: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type EditReportMembersMutation = (
  { __typename?: 'Mutation' }
  & { editReportMembers: Array<(
    { __typename?: 'ReportMember' }
    & ReportMemberDataFragment
  )> }
);

export type EditRequestMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICreateRequest;
  requestId: Scalars['ID'];
}>;


export type EditRequestMutation = (
  { __typename?: 'Mutation' }
  & { editRequest: (
    { __typename?: 'Request' }
    & RequestDataFragment
  ) }
);

export type EditRequestsStatusMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IRequest;
  requestsId: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type EditRequestsStatusMutation = (
  { __typename?: 'Mutation' }
  & { editRequestsStatus: Array<(
    { __typename?: 'Request' }
    & RequestDataFragment
  )> }
);

export type EditSeniorityMutationVariables = Exact<{
  companyId: Scalars['ID'];
  seniorityId: Scalars['ID'];
  data: IMemberSeniority;
}>;


export type EditSeniorityMutation = (
  { __typename?: 'Mutation' }
  & { editSeniority: (
    { __typename?: 'MemberSeniority' }
    & Pick<MemberSeniority, 'id'>
  ) }
);

export type EditMemberSpecializationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberSpecializationId: Scalars['ID'];
  data: ISpecialization;
}>;


export type EditMemberSpecializationMutation = (
  { __typename?: 'Mutation' }
  & { editSpecialization: (
    { __typename?: 'Specialization' }
    & Pick<Specialization, 'id'>
  ) }
);

export type EditTagMutationVariables = Exact<{
  companyId: Scalars['ID'];
  tagId: Scalars['ID'];
  data: ITag;
}>;


export type EditTagMutation = (
  { __typename?: 'Mutation' }
  & { editTag: (
    { __typename?: 'Tag' }
    & TagDataFragment
  ) }
);

export type EditTeamMemberMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  data: IMember;
}>;


export type EditTeamMemberMutation = (
  { __typename?: 'Mutation' }
  & { editTeamMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'email' | 'employment_type' | 'job_title' | 'memberSpecialization' | 'memberSpecializationId' | 'capacity' | 'reportingToId' | 'join_date' | 'exit_date' | 'created_at' | 'updated_at'>
    & { member_role: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>, member_seniority?: Maybe<(
      { __typename?: 'MemberSeniority' }
      & Pick<MemberSeniority, 'id' | 'name'>
    )>, costRateManagers: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'first_name' | 'last_name'>
    )>, reportingTo?: Maybe<(
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name'>
    )> }
  ) }
);

export type EditTeamMembersPmMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMembersPm;
}>;


export type EditTeamMembersPmMutation = (
  { __typename?: 'Mutation' }
  & { editTeamMembersPM: Array<(
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  )> }
);

export type ExcludeReportMembersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  excludedMembers: Array<Scalars['ID']> | Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ExcludeReportMembersMutation = (
  { __typename?: 'Mutation' }
  & { excludeReportMembers: (
    { __typename?: 'ReportCustomization' }
    & Pick<ReportCustomization, 'excludedMembers'>
  ) }
);

export type ImportDataMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IImportData;
}>;


export type ImportDataMutation = (
  { __typename?: 'Mutation' }
  & { importData: (
    { __typename?: 'ImportData' }
    & Pick<ImportData, 'file' | 'message' | 'success'>
  ) }
);

export type InviteUserMutationVariables = Exact<{
  companyId: Scalars['ID'];
  email: Scalars['String'];
  role: EditableMemberType;
}>;


export type InviteUserMutation = (
  { __typename?: 'Mutation' }
  & { invite: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'email' | 'status' | 'created_at' | 'updated_at'>
  ) }
);

export type AcceptInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  secret: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitation: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
    & { me: Array<(
      { __typename?: 'CompanyUserLite' }
      & Pick<CompanyUserLite, 'id'>
      & { member: (
        { __typename?: 'MemberLite' }
        & Pick<MemberLite, 'id' | 'email' | 'first_name' | 'last_name'>
      ), company: (
        { __typename?: 'CompanyLite' }
        & Pick<CompanyLite, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ManualCompanyIntegrationSyncMutationVariables = Exact<{
  companyId: Scalars['ID'];
  companyIntegrationId: Scalars['ID'];
}>;


export type ManualCompanyIntegrationSyncMutation = (
  { __typename?: 'Mutation' }
  & { manualCompanyIntegrationSync: Array<(
    { __typename?: 'CompanyIntegrationSync' }
    & CompanyIntegrationSyncDataFragment
  )> }
);

export type EditMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
  companyId: Scalars['ID'];
  role: EditableMemberType;
}>;


export type EditMemberMutation = (
  { __typename?: 'Mutation' }
  & { editMember: (
    { __typename?: 'CompanyUser' }
    & MemberDataFragment
  ) }
);

export type MoveFileBetweenFoldersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  documentId: Scalars['ID'];
  projectId: Scalars['ID'];
  folder?: Maybe<Scalars['ID']>;
}>;


export type MoveFileBetweenFoldersMutation = (
  { __typename?: 'Mutation' }
  & { moveFileBetweenFolders: (
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
  ) }
);

export type CreateProjectMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProject;
  withCommission: Scalars['Boolean'];
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'type' | 'unit' | 'cost_budget_amount' | 'start_date' | 'end_date' | 'created_at' | 'updated_at' | 'currentCommission'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
    )>, pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name'>
    ), pmFinAccess?: Maybe<(
      { __typename?: 'PmProjectMembership' }
      & PmProjectMembershipDataFragment
    )> }
  ) }
);

export type RefreshInvitationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['String'];
}>;


export type RefreshInvitationMutation = (
  { __typename?: 'Mutation' }
  & { refreshInvitation: (
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type RestoreMemberMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type RestoreMemberMutation = (
  { __typename?: 'Mutation' }
  & { restoreMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  ) }
);

export type RestoreProjectMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type RestoreProjectMutation = (
  { __typename?: 'Mutation' }
  & { restoreProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type RestoreUserMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type RestoreUserMutation = (
  { __typename?: 'Mutation' }
  & { restoreUser: (
    { __typename?: 'CompanyUser' }
    & Pick<CompanyUser, 'id' | 'deactivatedAt' | 'status'>
  ) }
);

export type SetReportCustomizationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IReportCustomization;
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type SetReportCustomizationMutation = (
  { __typename?: 'Mutation' }
  & { setReportCustomization: (
    { __typename?: 'ReportCustomization' }
    & Pick<ReportCustomization, 'billableDaysNotation' | 'created_at' | 'id' | 'leavesNotation' | 'leaveTypesDisplay' | 'projectId' | 'reportColumns'>
  ) }
);

export type SetUpReportExpensesMutationVariables = Exact<{
  companyId: Scalars['ID'];
  expensesId: Array<Scalars['ID']> | Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type SetUpReportExpensesMutation = (
  { __typename?: 'Mutation' }
  & { setUpReportExpenses: Array<(
    { __typename?: 'Expense' }
    & ExpenseDataFragment
  )> }
);

export type SetUpReportMilestonesMutationVariables = Exact<{
  companyId: Scalars['ID'];
  milestonesId: Array<Scalars['ID']> | Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type SetUpReportMilestonesMutation = (
  { __typename?: 'Mutation' }
  & { setUpReportMilestones: Array<(
    { __typename?: 'ProjectMilestone' }
    & ProjectMilestoneDataFragment
  )> }
);

export type CreateMemberMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMember;
}>;


export type CreateMemberMutation = (
  { __typename?: 'Mutation' }
  & { createMember: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'email' | 'first_name' | 'last_name' | 'status' | 'employment_type' | 'job_title' | 'memberSpecializationId' | 'reportingToId' | 'join_date' | 'created_at' | 'updated_at'>
    & { member_seniority?: Maybe<(
      { __typename?: 'MemberSeniority' }
      & Pick<MemberSeniority, 'id'>
    )> }
  ) }
);

export type UploadFeedbackFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFeedbackFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFeedbackFile'>
);

export type ActivityHistoriesDataFragment = (
  { __typename?: 'ActivityHistory' }
  & Pick<ActivityHistory, 'action' | 'changes' | 'companyId' | 'created_at' | 'data' | 'id' | 'integration' | 'memberId' | 'type' | 'createdByMemberName' | 'createdByMemberColor'>
);

export type ActivityHistoriesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  historyFilter?: Maybe<HistoryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ActivityHistoriesQuery = (
  { __typename?: 'Query' }
  & { activityHistories: Array<(
    { __typename?: 'ActivityHistory' }
    & ActivityHistoriesDataFragment
  )> }
);

export type CountActivityHistoryLogsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  historyFilter?: Maybe<HistoryFilter>;
}>;


export type CountActivityHistoryLogsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countActivityHistoryLogs'>
);

export type MemberAssignmentDataFragment = (
  { __typename?: 'Member' }
  & Pick<Member, 'id' | 'first_name' | 'last_name' | 'reportingToId' | 'archived_at' | 'costRateManagersIds' | 'color'>
  & { leaves: Array<(
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate'>
  )> }
);

export type MembersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
}>;


export type MembersQuery = (
  { __typename?: 'Query' }
  & { members: Array<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'job_title' | 'reportingToId' | 'archived_at' | 'color' | 'costRateManagersIds'>
    & { leaves: Array<(
      { __typename?: 'Leave' }
      & Pick<Leave, 'id' | 'memberId' | 'memberFirstName' | 'memberLastName' | 'startDate' | 'endDate' | 'notes'>
      & { leaveType?: Maybe<(
        { __typename?: 'LeaveType' }
        & Pick<LeaveType, 'id'>
      )> }
    )>, assignments: Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, 'id' | 'startDate' | 'endDate' | 'allocationTimeAmount'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name' | 'type'>
        & { pm: (
          { __typename?: 'MemberLite' }
          & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
        ) }
      ), member: (
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'first_name' | 'last_name' | 'job_title' | 'color'>
        & { leaves: Array<(
          { __typename?: 'Leave' }
          & Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate'>
        )> }
      ), role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type AssignmentQueryVariables = Exact<{
  companyId: Scalars['ID'];
  assignmentId: Scalars['ID'];
}>;


export type AssignmentQuery = (
  { __typename?: 'Query' }
  & { assignment?: Maybe<(
    { __typename?: 'Assignment' }
    & AssignmentDataFragment
  )> }
);

export type AssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IAssignmentQuery;
  filters?: Maybe<IAssignmentFilters>;
  withProjects?: Scalars['Boolean'];
}>;


export type AssignmentsQuery = (
  { __typename?: 'Query' }
  & { assignments: Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id' | 'seniority' | 'seniorityId' | 'endDate'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ), member: (
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'first_name' | 'last_name' | 'avatar_url' | 'color'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'color' | 'name' | 'id'>
    )> }
  )> }
);

export type BenchMemberDataFragment = (
  { __typename?: 'BenchMember' }
  & Pick<BenchMember, 'memberId' | 'memberName' | 'memberColor' | 'specialization' | 'jobTitle' | 'period' | 'nonscheduledHours' | 'nonscheduledDays' | 'totalCost'>
);

export type BenchReportDataFragment = (
  { __typename?: 'BenchReport' }
  & Pick<BenchReport, 'nameOfPeriod'>
  & { totals: Array<(
    { __typename?: 'BenchReportTotal' }
    & Pick<BenchReportTotal, 'groupBy' | 'groupByValue' | 'totalType' | 'totalSum'>
  )>, members: Array<(
    { __typename?: 'BenchMember' }
    & BenchMemberDataFragment
  )> }
);

export type BenchReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IBenchReportData;
  filters?: Maybe<InsightFilters>;
}>;


export type BenchReportQuery = (
  { __typename?: 'Query' }
  & { benchReport: Array<(
    { __typename?: 'BenchReport' }
    & BenchReportDataFragment
  )> }
);

export type BillableLeavesRulesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type BillableLeavesRulesQuery = (
  { __typename?: 'Query' }
  & { billableLeavesRules: Array<(
    { __typename?: 'BillableLeaveRule' }
    & BillableLeaveRuleDataFragment
  )> }
);

export type BillableLeaveRuleBalanceQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IBillableLeaveRuleBalance;
}>;


export type BillableLeaveRuleBalanceQuery = (
  { __typename?: 'Query' }
  & { billableLeaveRuleBalance: Array<(
    { __typename?: 'RuleMembersBalance' }
    & RuleMembersBalanceDataFragment
  )> }
);

export type BillableLeaveRuleMonthBalanceQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  data: IBillableLeaveRuleMonthBalance;
}>;


export type BillableLeaveRuleMonthBalanceQuery = (
  { __typename?: 'Query' }
  & { billableLeaveRuleMonthBalance: Array<(
    { __typename?: 'BillableLeaveRuleMonthBalance' }
    & BillableLeaveRuleMonthBalanceDataFragment
  )> }
);

export type MemberLeaveRuleAccrualAmountsQueryVariables = Exact<{
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
}>;


export type MemberLeaveRuleAccrualAmountsQuery = (
  { __typename?: 'Query' }
  & { memberLeaveRuleAccrualAmounts: Array<(
    { __typename?: 'MemberLeaveRuleAccrualAmount' }
    & MemberLeaveRuleAccrualAmountDataFragment
  )> }
);

export type SetMemberBalanceAccrualMonthMutationVariables = Exact<{
  accrualDate: Scalars['String'];
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type SetMemberBalanceAccrualMonthMutation = (
  { __typename?: 'Mutation' }
  & { setMemberBalanceAccrualMonth: (
    { __typename?: 'MemberBalanceAccrualMonth' }
    & MemberBalanceAccrualMonthDataFragment
  ) }
);

export type EditMemberLeaveRuleMonthBalanceMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMemberLeaveRuleMonthBalance;
}>;


export type EditMemberLeaveRuleMonthBalanceMutation = (
  { __typename?: 'Mutation' }
  & { editMemberLeaveRuleMonthBalance: (
    { __typename?: 'MemberLeaveRuleAccrualAmount' }
    & Pick<MemberLeaveRuleAccrualAmount, 'id'>
  ) }
);

export type DeleteMemberBalanceAccrualMonthMutationVariables = Exact<{
  billableLeaveRuleId: Scalars['ID'];
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type DeleteMemberBalanceAccrualMonthMutation = (
  { __typename?: 'Mutation' }
  & { deleteMemberBalanceAccrualMonth: (
    { __typename?: 'MemberBalanceAccrualMonth' }
    & MemberBalanceAccrualMonthDataFragment
  ) }
);

export type CommentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  entityId: Scalars['String'];
  entityType: CommentDomains;
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & CommentDataFragment
  )> }
);

export type EditCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  companyId: Scalars['ID'];
  note: Scalars['String'];
}>;


export type EditCommentMutation = (
  { __typename?: 'Mutation' }
  & { editComment: (
    { __typename?: 'Comment' }
    & CommentDataFragment
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICreateComment;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & CommentDataFragment
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  companyId: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'entityId'>
  ) }
);

export type CompensationTypesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompensationTypesQuery = (
  { __typename?: 'Query' }
  & { compensationTypes: Array<(
    { __typename?: 'CompensationType' }
    & Pick<CompensationType, 'id' | 'name'>
  )> }
);

export type CountPendingRequestQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CountPendingRequestQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countPendingRequest'>
);

export type CurrenciesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CurrenciesQuery = (
  { __typename?: 'Query' }
  & { currencies: Array<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'code'>
  )> }
);

export type ExchangeRateDataFragment = (
  { __typename?: 'ExchangeRates' }
  & Pick<ExchangeRates, 'id' | 'exchangeRate'>
  & { exchangeCurrency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'code'>
  ), baseCurrency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'code'>
  ) }
);

export type ExchangeRateQueryVariables = Exact<{
  companyId: Scalars['ID'];
  baseCurrencyId: Scalars['ID'];
  exchangeCurrencyId: Scalars['ID'];
}>;


export type ExchangeRateQuery = (
  { __typename?: 'Query' }
  & { exchangeRate?: Maybe<(
    { __typename?: 'ExchangeRates' }
    & ExchangeRateDataFragment
  )> }
);

export type ExchangeRatesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  baseCurrencyId: Scalars['ID'];
}>;


export type ExchangeRatesQuery = (
  { __typename?: 'Query' }
  & { exchangeRates: Array<(
    { __typename?: 'ExchangeRates' }
    & ExchangeRateDataFragment
  )> }
);

export type ExpenseTypesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type ExpenseTypesQuery = (
  { __typename?: 'Query' }
  & { expenseTypes: Array<(
    { __typename?: 'ExpenseType' }
    & Pick<ExpenseType, 'id' | 'name'>
  )> }
);

export type ExportImportExampleQueryVariables = Exact<{
  companyId: Scalars['ID'];
  type: ImportType;
}>;


export type ExportImportExampleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportImportExample'>
);

export type ExportMembersHoursQueryVariables = Exact<{
  companyId: Scalars['ID'];
  interval: IPaginationInterval;
  includedTabs?: Maybe<Array<MembersHoursReportTabs> | MembersHoursReportTabs>;
}>;


export type ExportMembersHoursQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'membersHours'>
);

export type ExportMembersTimeLogsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IPaginationInterval;
  members: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ExportMembersTimeLogsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportMembersTimeLogs'>
);

export type ExportProjectQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data?: Maybe<IExportProject>;
}>;


export type ExportProjectQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportProject'>
);

export type ExportProjectReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data?: Maybe<IExportProject>;
}>;


export type ExportProjectReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportProjectReport'>
);

export type ExportProjectsReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IPaginationInterval;
}>;


export type ExportProjectsReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectsAdminReport'>
);

export type ExternalRatesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  isPast?: Maybe<Scalars['Boolean']>;
}>;


export type ExternalRatesQuery = (
  { __typename?: 'Query' }
  & { externalRates: Array<(
    { __typename?: 'ExternalRate' }
    & ExternalRateDataFragment
  )> }
);

export type FoldersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FoldersQuery = (
  { __typename?: 'Query' }
  & { folders: Array<(
    { __typename?: 'FileFolder' }
    & FileFolderDataFragment
  )> }
);

export type FolderContentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  folderId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  filters?: Maybe<IProjectDocumentFilters>;
  searchByName?: Maybe<Scalars['String']>;
}>;


export type FolderContentsQuery = (
  { __typename?: 'Query' }
  & { folderContents?: Maybe<(
    { __typename?: 'FolderContent' }
    & { foldersPosition?: Maybe<Array<(
      { __typename?: 'FolderPosition' }
      & Pick<FolderPosition, 'id' | 'name' | 'position'>
    )>>, fileFolder: Array<(
      { __typename?: 'FileFolder' }
      & FileFolderDataFragment
    )>, projectDocument: Array<(
      { __typename?: 'ProjectDocument' }
      & ProjectDocumentDataFragment
    )> }
  )> }
);

export type HolidaysQueryVariables = Exact<{
  companyId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
}>;


export type HolidaysQuery = (
  { __typename?: 'Query' }
  & { holidays: Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'date' | 'name'>
  )> }
);

export type CompanyIntegrationDataFragment = (
  { __typename?: 'CompanyIntegration' }
  & Pick<CompanyIntegration, 'id' | 'integrationId' | 'integration' | 'status' | 'connectedByMemberName' | 'connectedByMemberColor' | 'dateFormat' | 'created_at'>
  & { integrationOptions: Array<(
    { __typename?: 'CompanyIntegrationSync' }
    & Pick<CompanyIntegrationSync, 'id' | 'companyIntegrationId' | 'integrationOptionId' | 'integrationOption' | 'lastSyncAt' | 'lastSyncStatus' | 'syncByMember'>
    & { syncOption?: Maybe<(
      { __typename?: 'IntegrationOptionSyncValues' }
      & Pick<IntegrationOptionSyncValues, 'id'>
    )> }
  )> }
);

export type IntegrationOptionDataFragment = (
  { __typename?: 'IntegrationOption' }
  & Pick<IntegrationOption, 'id' | 'name'>
  & { syncOptions: Array<(
    { __typename?: 'IntegrationOptionSyncValues' }
    & Pick<IntegrationOptionSyncValues, 'id' | 'integrationOptionId' | 'name'>
  )> }
);

export type AvailableIntegrationDataFragment = (
  { __typename?: 'AvailableIntegration' }
  & Pick<AvailableIntegration, 'id' | 'name' | 'status'>
  & { options: Array<(
    { __typename?: 'IntegrationOption' }
    & IntegrationOptionDataFragment
  )> }
);

export type CompanyIntegrationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type CompanyIntegrationsQuery = (
  { __typename?: 'Query' }
  & { companyIntegrations: Array<(
    { __typename?: 'CompanyIntegration' }
    & CompanyIntegrationDataFragment
  )> }
);

export type CompanyIntegrationQueryVariables = Exact<{
  companyId: Scalars['ID'];
  integrationId: Scalars['ID'];
}>;


export type CompanyIntegrationQuery = (
  { __typename?: 'Query' }
  & { companyIntegration?: Maybe<(
    { __typename?: 'CompanyIntegration' }
    & CompanyIntegrationDataFragment
  )> }
);

export type AvailableIntegrationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type AvailableIntegrationsQuery = (
  { __typename?: 'Query' }
  & { availableIntegrations: Array<(
    { __typename?: 'AvailableIntegration' }
    & AvailableIntegrationDataFragment
  )> }
);

export type GenerateXeroInvoiceQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type GenerateXeroInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateXeroInvoice'>
);

export type InvitationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type InvitationsQuery = (
  { __typename?: 'Query' }
  & { invitations: Array<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'email' | 'status' | 'created_at'>
  )> }
);

export type LeaveTypesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type LeaveTypesQuery = (
  { __typename?: 'Query' }
  & { leaveTypes: Array<(
    { __typename?: 'LeaveType' }
    & LeaveTypeDataFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: Array<(
    { __typename?: 'CompanyUser' }
    & MemberDataFragment
  )> }
);

export type MemberAssignmentsDataFragment = (
  { __typename?: 'ProjectAssignmentMembers' }
  & Pick<ProjectAssignmentMembers, 'actualCommission' | 'actualPaidRevenue' | 'actualProfit' | 'actualProfitMargin' | 'actualRevenue' | 'actualUnpaidRevenue' | 'allocationHours' | 'allocationPercent' | 'assignmentEndDate' | 'assignmentId' | 'assignmentStartDate' | 'billable' | 'billingType' | 'compensationCost' | 'cost' | 'costOrigin' | 'plannedCommission' | 'pm' | 'pmColor' | 'profit' | 'profitMargin' | 'projectColor' | 'projectId' | 'projectName' | 'projectCurrencyId' | 'projectCurrency' | 'projectType' | 'first_name' | 'last_name' | 'revenue' | 'role' | 'seniority' | 'tmBenchmark'>
  & { rates?: Maybe<Array<(
    { __typename?: 'ExternalRate' }
    & Pick<ExternalRate, 'end_date' | 'rate_amount' | 'start_date' | 'unit'>
  )>>, daysRanges?: Maybe<Array<(
    { __typename?: 'CostRate' }
    & Pick<CostRate, 'rate_amount' | 'rate_currency' | 'start_date' | 'end_date' | 'unit'>
  )>> }
);

export type MemberAssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  interval?: Maybe<IPaginationInterval>;
}>;


export type MemberAssignmentsQuery = (
  { __typename?: 'Query' }
  & { memberAssignments: Array<(
    { __typename?: 'ProjectAssignmentMembers' }
    & MemberAssignmentsDataFragment
  )> }
);

export type MemberProjectDistributionDataFragment = (
  { __typename?: 'MemberProjectDistribution' }
  & Pick<MemberProjectDistribution, 'percentage' | 'projectColor' | 'projectId' | 'projectName' | 'totalHours'>
);

export type MemberProjectsDistributionQueryVariables = Exact<{
  companyId: Scalars['ID'];
  interval: IPaginationInterval;
  memberId: Scalars['ID'];
}>;


export type MemberProjectsDistributionQuery = (
  { __typename?: 'Query' }
  & { memberProjectsDistribution: Array<(
    { __typename?: 'MemberProjectDistribution' }
    & MemberProjectDistributionDataFragment
  )> }
);

export type MemberIntegrationStatusesDataFragment = (
  { __typename?: 'MemberIntegrationStatuses' }
  & Pick<MemberIntegrationStatuses, 'memberId'>
  & { statuses: Array<(
    { __typename?: 'MemberIntegrationStatus' }
    & Pick<MemberIntegrationStatus, 'integrationName' | 'status'>
  )> }
);

export type MembersIntegrationStatusesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type MembersIntegrationStatusesQuery = (
  { __typename?: 'Query' }
  & { membersIntegrationStatuses?: Maybe<Array<(
    { __typename?: 'MemberIntegrationStatuses' }
    & MemberIntegrationStatusesDataFragment
  )>> }
);

export type MemberIntegrationStatusesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type MemberIntegrationStatusesQuery = (
  { __typename?: 'Query' }
  & { memberIntegrationStatuses?: Maybe<(
    { __typename?: 'MemberIntegrationStatuses' }
    & MemberIntegrationStatusesDataFragment
  )> }
);

export type MemberListDetailsDataFragment = (
  { __typename?: 'MemberListData' }
  & Pick<MemberListData, 'activeAssignments' | 'currentSalary' | 'memberId'>
);

export type MembersListDataQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type MembersListDataQuery = (
  { __typename?: 'Query' }
  & { membersListData: Array<(
    { __typename?: 'MemberListData' }
    & MemberListDetailsDataFragment
  )> }
);

export type PermissionDataFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'action'>
);

export type ActorPermissionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type ActorPermissionsQuery = (
  { __typename?: 'Query' }
  & { actorPermissions: Array<(
    { __typename?: 'Permission' }
    & PermissionDataFragment
  )> }
);

export type ProjectAssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectAssignmentsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'unit' | 'type' | 'fee_amount'>
    & { assignments: Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, 'id' | 'startDate' | 'endDate' | 'allocationTimeAmount'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name'>
      ), project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'unit'>
      ), member: (
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'first_name' | 'last_name' | 'job_title' | 'color'>
        & { cost_rate?: Maybe<Array<(
          { __typename?: 'CostRate' }
          & Pick<CostRate, 'id' | 'start_date' | 'end_date' | 'rate_amount' | 'rate_currency'>
        )>>, leaves: Array<(
          { __typename?: 'Leave' }
          & Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate'>
        )> }
      ) }
    )> }
  )> }
);

export type ClientDataFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'name'>
);

export type ProjectClientQueryVariables = Exact<{
  companyId: Scalars['ID'];
  externalRatesAccess?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectClientQuery = (
  { __typename?: 'Query' }
  & { clients: Array<(
    { __typename?: 'Client' }
    & ClientDataFragment
  )> }
);

export type ProjectCommissionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectCommissionsQuery = (
  { __typename?: 'Query' }
  & { projectCommissions: Array<(
    { __typename?: 'ProjectCommission' }
    & ProjectCommissionDataFragment
  )> }
);

export type ProjectDocumentUrlQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectDocumentId: Scalars['ID'];
  download?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectDocumentUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectDocumentUrl'>
);

export type ProjectDocumentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  searchByName?: Maybe<Scalars['String']>;
  filterData?: Maybe<IProjectDocumentFilters>;
}>;


export type ProjectDocumentsQuery = (
  { __typename?: 'Query' }
  & { projectDocuments: Array<(
    { __typename?: 'ProjectDocument' }
    & ProjectDocumentDataFragment
  )> }
);

export type ProjectManagersQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type ProjectManagersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'CompanyUser' }
    & Pick<CompanyUser, 'id' | 'status' | 'type' | 'deactivatedAt'>
    & { member: (
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'first_name' | 'last_name' | 'is_pending' | 'archived_at' | 'color'>
    ) }
  )> }
);

export type ProjectMembershipDataFragment = (
  { __typename?: 'ProjectMembership' }
  & Pick<ProjectMembership, 'accessLevel' | 'color' | 'endDate' | 'id' | 'memberFirstName' | 'memberId' | 'memberLastName' | 'projectId' | 'startDate' | 'projectName' | 'projectColor' | 'projectType' | 'pmId'>
);

export type ProjectMembershipsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IQueryMembership;
}>;


export type ProjectMembershipsQuery = (
  { __typename?: 'Query' }
  & { projectMemberships: Array<(
    { __typename?: 'ProjectMembership' }
    & ProjectMembershipDataFragment
  )> }
);

export type ProjectMembershipQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type ProjectMembershipQuery = (
  { __typename?: 'Query' }
  & { projectMembership?: Maybe<(
    { __typename?: 'ProjectMembership' }
    & Pick<ProjectMembership, 'endDate' | 'startDate'>
  )> }
);

export type ProjectMilestonesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectMilestonesQuery = (
  { __typename?: 'Query' }
  & { projectMilestones: Array<(
    { __typename?: 'ProjectMilestone' }
    & ProjectMilestoneDataFragment
  )> }
);

export type ProjectMilestonesSectionQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectMilestonesSectionQuery = (
  { __typename?: 'Query' }
  & { projectMilestonesSection: Array<(
    { __typename?: 'ProjectMilestoneSection' }
    & ProjectMilestoneSectionDataFragment
  )> }
);

export type ProjectRoleOrderDataFragment = (
  { __typename?: 'ProjectRoleOrder' }
  & Pick<ProjectRoleOrder, 'orderingIndex' | 'roleName' | 'roleId'>
);

export type ProjectRolesOrderQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectRolesOrderQuery = (
  { __typename?: 'Query' }
  & { projectRolesOrder: Array<(
    { __typename?: 'ProjectRoleOrder' }
    & ProjectRoleOrderDataFragment
  )> }
);

export type ChangeProjectRoleOrderMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IProjectRoleOrder;
}>;


export type ChangeProjectRoleOrderMutation = (
  { __typename?: 'Mutation' }
  & { changeProjectRoleOrder: Array<(
    { __typename?: 'ProjectRoleOrder' }
    & ProjectRoleOrderDataFragment
  )> }
);

export type ProjectsForAssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectsForAssignmentsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'type' | 'archived_at' | 'unit' | 'fee_currency' | 'color'>
    & { pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id'>
    ) }
  )> }
);

export type ProjectsForAssignmentEditQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type ProjectsForAssignmentEditQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'type' | 'fee_currency'>
  )> }
);

export type ProjectListDetailsDataFragment = (
  { __typename?: 'ProjectListData' }
  & Pick<ProjectListData, 'activeAssignments' | 'currentCommission' | 'projectId'>
);

export type ProjectsListDataQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectsListDataQuery = (
  { __typename?: 'Query' }
  & { projectsListData: Array<(
    { __typename?: 'ProjectListData' }
    & ProjectListDetailsDataFragment
  )> }
);

export type ProjectMarginsDataFragment = (
  { __typename?: 'ProjectMargins' }
  & Pick<ProjectMargins, 'projectId' | 'margin' | 'forecastMargin'>
);

export type ProjectsMarginsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectsMarginsQuery = (
  { __typename?: 'Query' }
  & { projectsMargins: Array<(
    { __typename?: 'ProjectMargins' }
    & ProjectMarginsDataFragment
  )> }
);

export type ReportsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectsId?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  interval?: Maybe<IPaginationInterval>;
}>;


export type ReportsQuery = (
  { __typename?: 'Query' }
  & { reports: Array<(
    { __typename?: 'Report' }
    & ReportItemDataFragment
  )> }
);

export type ReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report?: Maybe<(
    { __typename?: 'Report' }
    & { milestones?: Maybe<Array<(
      { __typename?: 'ProjectMilestone' }
      & ProjectMilestoneDataFragment
    )>> }
    & ReportDataWithClientFragment
  )> }
);

export type ExportReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  reportId: Scalars['ID'];
  projectId: Scalars['ID'];
  exportType?: Maybe<ExportTarget>;
}>;


export type ExportReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportReport'>
);

export type ProjectReportCustomizationQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectReportCustomizationQuery = (
  { __typename?: 'Query' }
  & { projectReportCustomization?: Maybe<(
    { __typename?: 'ReportCustomization' }
    & Pick<ReportCustomization, 'excludedMembers'>
  )> }
);

export type RequestQueryVariables = Exact<{
  companyId: Scalars['ID'];
  requestId: Scalars['ID'];
}>;


export type RequestQuery = (
  { __typename?: 'Query' }
  & { request?: Maybe<(
    { __typename?: 'Request' }
    & RequestDataFragment
  )> }
);

export type RequestsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  requestFilter?: Maybe<RequestFilter>;
  paginationData?: Maybe<IPaginationInterval>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type RequestsQuery = (
  { __typename?: 'Query' }
  & { requests: Array<(
    { __typename?: 'Request' }
    & RequestDataFragment
  )> }
);

export type CountAllRequestsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  requestFilter?: Maybe<RequestFilter>;
}>;


export type CountAllRequestsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countAllRequests'>
);

export type RevenueChartDataFragment = (
  { __typename?: 'RevenueChart' }
  & Pick<RevenueChart, 'totalUnpaid' | 'totalScheduled' | 'totalPaid' | 'totalActual' | 'nameOfMonth'>
);

export type RevenueChartQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  projectType: ProjectType;
  companyCurrency?: Maybe<Scalars['Boolean']>;
  data?: Maybe<IPaginationDashboard>;
}>;


export type RevenueChartQuery = (
  { __typename?: 'Query' }
  & { revenueChart: (
    { __typename?: 'ProjectInsights' }
    & Pick<ProjectInsights, 'exchangeRate' | 'exchangeRateCurrency' | 'projectCurrency'>
    & { revenueChart?: Maybe<Array<(
      { __typename?: 'RevenueChart' }
      & RevenueChartDataFragment
    )>> }
  ) }
);

export type RolesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )> }
);

export type SenioritiesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type SenioritiesQuery = (
  { __typename?: 'Query' }
  & { seniorities: Array<(
    { __typename?: 'MemberSeniority' }
    & Pick<MemberSeniority, 'id' | 'name'>
  )> }
);

export type SpecializationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type SpecializationsQuery = (
  { __typename?: 'Query' }
  & { specializations: Array<(
    { __typename?: 'Specialization' }
    & Pick<Specialization, 'id' | 'name'>
  )> }
);

export type TagsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & TagDataFragment
  )> }
);

export type TeamMemberDataFragment = (
  { __typename?: 'Member' }
  & Pick<Member, 'id' | 'first_name' | 'last_name' | 'email' | 'is_pending' | 'employment_type' | 'job_title' | 'reportingToId' | 'memberSpecialization' | 'archived_at' | 'companyUserId' | 'color' | 'join_date' | 'exit_date'>
  & { reportingTo?: Maybe<(
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
  )>, member_seniority?: Maybe<(
    { __typename?: 'MemberSeniority' }
    & Pick<MemberSeniority, 'id' | 'name'>
  )> }
);

export type TeamMembersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type TeamMembersQuery = (
  { __typename?: 'Query' }
  & { members: Array<(
    { __typename?: 'Member' }
    & TeamMemberDataFragment
  )> }
);

export type TeamMembersWithAssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filters?: Maybe<IAssignmentFilters>;
  filterData?: Maybe<IFilterData>;
  isArchived?: Maybe<Scalars['Boolean']>;
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
}>;


export type TeamMembersWithAssignmentsQuery = (
  { __typename?: 'Query' }
  & { members: Array<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'color' | 'employment_type' | 'memberSpecialization'>
    & { assignments: Array<(
      { __typename?: 'Assignment' }
      & Pick<Assignment, 'projectId'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'name'>
      ) }
    )> }
  )> }
);

export type TeamMembersForAssignmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type TeamMembersForAssignmentsQuery = (
  { __typename?: 'Query' }
  & { members: Array<(
    { __typename?: 'Member' }
    & MemberAssignmentDataFragment
  )> }
);

export type MemberOptionDataFragment = (
  { __typename?: 'Member' }
  & Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>
);

export type TeamMembersOptionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type TeamMembersOptionsQuery = (
  { __typename?: 'Query' }
  & { members: Array<(
    { __typename?: 'Member' }
    & MemberOptionDataFragment
  )> }
);

export type CompanyUserDataFragment = (
  { __typename?: 'CompanyUser' }
  & Pick<CompanyUser, 'id' | 'type' | 'deactivatedAt' | 'createdAt' | 'reportingToCount' | 'status' | 'memberId' | 'projectsAccessCount'>
  & { member: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'email' | 'is_pending' | 'color'>
    & { member_role: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  ) }
);

export type UsersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  withAccessCount?: Maybe<Scalars['Boolean']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'CompanyUser' }
    & CompanyUserDataFragment
  )> }
);

export type UtilizationMemberDataFragment = (
  { __typename?: 'UtilizationMember' }
  & Pick<UtilizationMember, 'memberId' | 'memberName' | 'memberColor' | 'period' | 'capacity' | 'scheduledHours' | 'billableHours' | 'nonBillableHours' | 'nonscheduledHours' | 'leavesHours' | 'actualCapacity' | 'utilization' | 'billableUtilization' | 'nonBillableUtilization' | 'nonscheduledPercent' | 'paidLeavesHours' | 'unpaidLeavesHours' | 'loggedHours' | 'overtimeHours'>
);

export type UtilizationReportDataFragment = (
  { __typename?: 'UtilizationReport' }
  & Pick<UtilizationReport, 'nameOfPeriod' | 'totalCapacity' | 'totalBillable' | 'totalNonBillable' | 'totalLeaves' | 'unassignedHours' | 'actualCapacity'>
  & { members: Array<(
    { __typename?: 'UtilizationMember' }
    & UtilizationMemberDataFragment
  )> }
);

export type UtilizationReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IPaginationInterval;
  columns: Array<UtilizationReportCustomColumns> | UtilizationReportCustomColumns;
  filters?: Maybe<InsightFilters>;
}>;


export type UtilizationReportQuery = (
  { __typename?: 'Query' }
  & { utilizationReport: Array<(
    { __typename?: 'UtilizationReport' }
    & UtilizationReportDataFragment
  )> }
);

export type VendorsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type VendorsQuery = (
  { __typename?: 'Query' }
  & { vendors: Array<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id' | 'name'>
  )> }
);

export type WorkingHoursInMonthQueryVariables = Exact<{
  companyId: Scalars['ID'];
  start: Scalars['String'];
  end: Scalars['String'];
}>;


export type WorkingHoursInMonthQuery = (
  { __typename?: 'Query' }
  & { workingHoursInMonth: Array<(
    { __typename?: 'WorkingHoursInMonth' }
    & Pick<WorkingHoursInMonth, 'month' | 'hours'>
  )> }
);

export type PureSkillCategoryDataFragment = (
  { __typename?: 'SkillCategory' }
  & Pick<SkillCategory, 'id' | 'name'>
);

export type SkillCategoryDataFragment = (
  { __typename?: 'SkillCategory' }
  & { skills?: Maybe<Array<(
    { __typename?: 'Skill' }
    & Pick<Skill, 'name' | 'id'>
  )>> }
  & PureSkillCategoryDataFragment
);

export type SkillCategoriesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  withSkills?: Maybe<Scalars['Boolean']>;
}>;


export type SkillCategoriesQuery = (
  { __typename?: 'Query' }
  & { skillCategories: Array<(
    { __typename?: 'SkillCategory' }
    & SkillCategoryDataFragment
  )> }
);

export type CreateSkillCategoryMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ISkillCategory;
  withSkills?: Maybe<Scalars['Boolean']>;
}>;


export type CreateSkillCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createSkillCategory: (
    { __typename?: 'SkillCategory' }
    & SkillCategoryDataFragment
  ) }
);

export type EditSkillCategoryMutationVariables = Exact<{
  companyId: Scalars['ID'];
  skillCategoryId: Scalars['ID'];
  data: ISkillCategory;
  withSkills?: Maybe<Scalars['Boolean']>;
}>;


export type EditSkillCategoryMutation = (
  { __typename?: 'Mutation' }
  & { editSkillCategory: (
    { __typename?: 'SkillCategory' }
    & SkillCategoryDataFragment
  ) }
);

export type DeleteSkillCategoryMutationVariables = Exact<{
  companyId: Scalars['ID'];
  skillCategoryId: Scalars['ID'];
}>;


export type DeleteSkillCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteSkillCategory: (
    { __typename?: 'SkillCategory' }
    & Pick<SkillCategory, 'id'>
  ) }
);

export type TimeLogDataFragment = (
  { __typename?: 'TimeLog' }
  & Pick<TimeLog, 'id' | 'date' | 'minutes' | 'notes' | 'assignment_id' | 'overtime' | 'overtimeMultiplier'>
);

export type TotalTimeLogDataFragment = (
  { __typename?: 'TotalTimeLog' }
  & Pick<TotalTimeLog, 'totalMinutes' | 'member_id' | 'date' | 'assignment_id'>
);

export type TimeLogsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IQueryTimeLogs;
  paginationData?: Maybe<IPaginationTimeLogs>;
}>;


export type TimeLogsQuery = (
  { __typename?: 'Query' }
  & { timeLogs: Array<(
    { __typename?: 'TimeLog' }
    & Pick<TimeLog, 'reportMemberId' | 'member_id'>
    & TimeLogDataFragment
  )> }
);

export type TotalTimeLogsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IQueryTimeLogs;
  paginationData?: Maybe<IPaginationTimeLogs>;
}>;


export type TotalTimeLogsQuery = (
  { __typename?: 'Query' }
  & { totalTimeLogs: Array<(
    { __typename?: 'TotalTimeLog' }
    & TotalTimeLogDataFragment
  )> }
);

export type CreateTimeLogMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: ITimeLog;
}>;


export type CreateTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { createTimeLog: (
    { __typename?: 'TimeLog' }
    & Pick<TimeLog, 'member_id'>
    & TimeLogDataFragment
  ) }
);

export type EditTimeLogMutationVariables = Exact<{
  companyId: Scalars['ID'];
  timeLogId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: ITimeLog;
}>;


export type EditTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { editTimeLog: (
    { __typename?: 'TimeLog' }
    & TimeLogDataFragment
  ) }
);

export type DeleteTimeLogMutationVariables = Exact<{
  companyId: Scalars['ID'];
  timeLogIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeLog: Array<(
    { __typename?: 'TimeLog' }
    & Pick<TimeLog, 'id' | 'date' | 'minutes' | 'assignment_id'>
  )> }
);

export type CreateTimeLogPeriodMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: ITimeLogPeriod;
}>;


export type CreateTimeLogPeriodMutation = (
  { __typename?: 'Mutation' }
  & { createTimeLogPeriod: Array<(
    { __typename?: 'TimeLog' }
    & TimeLogDataFragment
  )> }
);

export type ExpenseDataFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id' | 'type' | 'typeName' | 'date' | 'amount' | 'isBillable' | 'notes' | 'billableAmount' | 'reportId' | 'created_at'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>, budget?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id' | 'name'>
  )>, member: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'color'>
  ) }
);

export type ExpensesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  data: IQueryExpenses;
}>;


export type ExpensesQuery = (
  { __typename?: 'Query' }
  & { expenses: Array<(
    { __typename?: 'Expense' }
    & ExpenseDataFragment
  )> }
);

export type EditExpenseMutationVariables = Exact<{
  companyId: Scalars['ID'];
  expenseId: Scalars['ID'];
  data: IExpense;
}>;


export type EditExpenseMutation = (
  { __typename?: 'Mutation' }
  & { editExpense: (
    { __typename?: 'Expense' }
    & ExpenseDataFragment
  ) }
);

export type LeaveTimeLogDataFragment = (
  { __typename?: 'Leave' }
  & Pick<Leave, 'startDate' | 'endDate'>
  & { leaveType?: Maybe<(
    { __typename?: 'LeaveType' }
    & Pick<LeaveType, 'name'>
  )> }
);

export type ProjectAssignmentTimeLogDataFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, 'id' | 'startDate' | 'endDate' | 'bill_amount_calculation_type' | 'projectId' | 'seniority'>
  & { member: (
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'employment_type' | 'color'>
    & { leaves: Array<(
      { __typename?: 'Leave' }
      & LeaveTimeLogDataFragment
    )> }
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'id'>
  ) }
);

export type TimeLogsAssignmentsByProjectQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  filters?: Maybe<IAssignmentFilters>;
}>;


export type TimeLogsAssignmentsByProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'overtimeMultiplier'>
    & { assignments: Array<(
      { __typename?: 'Assignment' }
      & ProjectAssignmentTimeLogDataFragment
    )> }
  )> }
);

export type ProfitabilityDataFragment = (
  { __typename?: 'Profitability' }
  & Pick<Profitability, 'commission' | 'cost' | 'nameOfMonth' | 'other' | 'profit' | 'profitMargin' | 'revenue' | 'salary' | 'tmBenchmark'>
);

export type TeamDynamicsDataFragment = (
  { __typename?: 'TeamDynamics' }
  & Pick<TeamDynamics, 'nameOfMonth'>
  & { roles: Array<(
    { __typename?: 'TeamDynamicsRole' }
    & Pick<TeamDynamicsRole, 'roleName' | 'employees' | 'contractors'>
  )> }
);

export type ProjectAssignmentsDataFragment = (
  { __typename?: 'ProjectAssignmentMembers' }
  & Pick<ProjectAssignmentMembers, 'memberId' | 'assignmentId' | 'assignmentStartDate' | 'assignmentEndDate' | 'first_name' | 'last_name' | 'role' | 'scheduledTime' | 'actualTime' | 'cost' | 'compensationCost' | 'color' | 'costOrigin' | 'revenue' | 'allocationHours' | 'actualUnpaidRevenue' | 'actualRevenue' | 'actualPaidRevenue' | 'tmBenchmark' | 'billingType' | 'billable' | 'seniority' | 'actualCommission' | 'actualProfit' | 'actualProfitMargin' | 'plannedCommission' | 'profit' | 'profitMargin'>
  & { rates?: Maybe<Array<(
    { __typename?: 'ExternalRate' }
    & Pick<ExternalRate, 'end_date' | 'rate_amount' | 'start_date' | 'unit'>
  )>>, daysRanges?: Maybe<Array<(
    { __typename?: 'CostRate' }
    & Pick<CostRate, 'start_date' | 'end_date' | 'rate_amount' | 'rate_currency' | 'unit'>
  )>> }
);

export type PmProjectMembershipDataFragment = (
  { __typename?: 'PmProjectMembership' }
  & Pick<PmProjectMembership, 'accessLevel' | 'startDate' | 'endDate'>
);

export type ProjectDataFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'unit' | 'type' | 'fee_amount' | 'fee_currency' | 'feeCurrencyId' | 'color' | 'start_date' | 'end_date' | 'projectFinancialManagersIds' | 'archived_at' | 'cost_budget_amount' | 'billableLeaves' | 'activeAssignments' | 'overtimeMultiplier'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  )>, pm: (
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
  ), pmFinAccess?: Maybe<(
    { __typename?: 'PmProjectMembership' }
    & PmProjectMembershipDataFragment
  )> }
);

export type ProjectByIdQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  withCommission?: Scalars['Boolean'];
}>;


export type ProjectByIdQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'currentCommission'>
    & ProjectDataFragment
  )> }
);

export type ProjectAssignmentsListQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationDashboard>;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectAssignmentsListQuery = (
  { __typename?: 'Query' }
  & { projectAssignments: (
    { __typename?: 'ProjectAssignments' }
    & Pick<ProjectAssignments, 'totalRevenue' | 'totalCost' | 'totalCommission' | 'profit' | 'profitMargin' | 'cost_budget_amount' | 'exchangeRate' | 'exchangeRateCurrency' | 'projectCurrency'>
    & { projectAssignments: Array<(
      { __typename?: 'ProjectAssignmentMembers' }
      & ProjectAssignmentsDataFragment
    )> }
  ) }
);

export type ProjectAssignmentsListTotalsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationDashboard>;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectAssignmentsListTotalsQuery = (
  { __typename?: 'Query' }
  & { projectAssignments: (
    { __typename?: 'ProjectAssignments' }
    & Pick<ProjectAssignments, 'totalRevenue' | 'totalCost' | 'totalCommission' | 'profit' | 'profitMargin' | 'cost_budget_amount'>
  ) }
);

export type ProfitabilityQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  companyCurrency?: Maybe<Scalars['Boolean']>;
  data?: Maybe<IPaginationDashboard>;
}>;


export type ProfitabilityQuery = (
  { __typename?: 'Query' }
  & { profitability: (
    { __typename?: 'ProjectInsights' }
    & Pick<ProjectInsights, 'exchangeRate' | 'exchangeRateCurrency' | 'projectCurrency'>
    & { profitability?: Maybe<Array<(
      { __typename?: 'Profitability' }
      & ProfitabilityDataFragment
    )>> }
  ) }
);

export type TeamDynamicsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data?: Maybe<IPaginationDashboard>;
}>;


export type TeamDynamicsQuery = (
  { __typename?: 'Query' }
  & { teamDynamics: Array<(
    { __typename?: 'TeamDynamics' }
    & TeamDynamicsDataFragment
  )> }
);

export type EditProjectMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: IProject;
}>;


export type EditProjectMutation = (
  { __typename?: 'Mutation' }
  & { editProject: (
    { __typename?: 'Project' }
    & ProjectDataFragment
  ) }
);

export type ManagedProjectDataFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'type' | 'start_date' | 'end_date' | 'fee_currency' | 'feeCurrencyId' | 'archived_at' | 'color' | 'projectFinancialManagersIds' | 'countFiles'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  )>, pm: (
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
  ) }
);

export type ManagedProjectsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  groupValue?: Maybe<GroupBy>;
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ManagedProjectsQuery = (
  { __typename?: 'Query' }
  & { managedProjects: Array<(
    { __typename?: 'Project' }
    & ManagedProjectDataFragment
  )> }
);

export type ManagedProjectsOptionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ManagedProjectsOptionsQuery = (
  { __typename?: 'Query' }
  & { managedProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'color'>
  )> }
);

export type ResourcePlanningRequestDataFragment = (
  { __typename?: 'Request' }
  & Pick<Request, 'changes' | 'status' | 'created_at' | 'data' | 'id' | 'note'>
);

export type ResourcePlanningMembersDataFragment = (
  { __typename?: 'ResourcePlanningMember' }
  & MakeOptional<Pick<ResourcePlanningMember, 'id' | 'first_name' | 'last_name' | 'job_title' | 'color' | 'capacity' | 'archived_at' | 'reportingToId' | 'reportingToColor' | 'reportingToMemberName' | 'employment_type' | 'specialization'>, 'specialization'>
  & { costRateManagers: Array<(
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name'>
  )>, member_leave?: Maybe<Array<(
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'memberId' | 'startDate' | 'endDate' | 'notes'>
    & { leaveType?: Maybe<(
      { __typename?: 'LeaveType' }
      & Pick<LeaveType, 'id' | 'name'>
    )> }
  )>>, company: (
    { __typename?: 'CompanyLite' }
    & Pick<CompanyLite, 'id' | 'name'>
  ), projects: Array<(
    { __typename?: 'ResourcePlanningProject' }
    & ResourcePlanningProjectsDataFragment
  )> }
);

export type ResourcePlanningProjectsDataFragment = (
  { __typename?: 'ResourcePlanningProject' }
  & Pick<ResourcePlanningProject, 'id' | 'name' | 'color'>
  & { assignment: Array<(
    { __typename?: 'Assignment' }
    & Pick<Assignment, 'id' | 'startDate' | 'endDate' | 'allocationTimeAmount' | 'billable' | 'created_at'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    ) }
  )>, requests?: Maybe<Array<(
    { __typename?: 'Request' }
    & ResourcePlanningRequestDataFragment
  )>>, member: (
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id'>
  ) }
);

export type ResourcePlanningMembersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
  filterData?: Maybe<IFilterResourcePlanningMembers>;
  searchValue?: Maybe<Scalars['String']>;
  withSpecialization?: Scalars['Boolean'];
  withLeaves?: Scalars['Boolean'];
  withRequests?: Scalars['Boolean'];
}>;


export type ResourcePlanningMembersQuery = (
  { __typename?: 'Query' }
  & { resourcePlanningMembers: Array<(
    { __typename?: 'ResourcePlanningMember' }
    & ResourcePlanningMembersDataFragment
  )> }
);

export type ResourcePlanningAssignmentMemberDataFragment = (
  { __typename?: 'AssignmentMember' }
  & Pick<AssignmentMember, 'id' | 'first_name' | 'last_name' | 'job_title' | 'reportingTo' | 'color' | 'capacity'>
  & { member_leave?: Maybe<Array<(
    { __typename?: 'Leave' }
    & Pick<Leave, 'id' | 'startDate' | 'endDate'>
  )>> }
);

export type ResourcePlanningProjectAssignmentDataFragment = (
  { __typename?: 'ResourcePlanningAssignment' }
  & Pick<ResourcePlanningAssignment, 'id' | 'project_id' | 'startDate' | 'endDate' | 'allocationTimeAmount'>
  & { role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  ), member: (
    { __typename?: 'AssignmentMember' }
    & ResourcePlanningAssignmentMemberDataFragment
  ) }
);

export type ResourcePlanningProjectDataFragment = (
  { __typename?: 'ResourcePlanningProjectAssignment' }
  & Pick<ResourcePlanningProjectAssignment, 'id' | 'name' | 'archived_at' | 'color' | 'end_date' | 'fee_currency' | 'type' | 'start_date'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'name' | 'id'>
  )>, assignments: Array<(
    { __typename?: 'ResourcePlanningAssignment' }
    & ResourcePlanningProjectAssignmentDataFragment
  )>, pm: (
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'last_name' | 'first_name' | 'color'>
  ) }
);

export type ResourcePlanningProjectsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  paginationAssignmentData?: Maybe<IPaginationAssignment>;
  isArchived?: Scalars['Boolean'];
  searchValue?: Maybe<Scalars['String']>;
  withLeaves?: Scalars['Boolean'];
}>;


export type ResourcePlanningProjectsQuery = (
  { __typename?: 'Query' }
  & { resourcePlanningProjects: Array<(
    { __typename?: 'ResourcePlanningProjectAssignment' }
    & ResourcePlanningProjectDataFragment
  )> }
);

export type CreateScenarioMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICreateScenario;
}>;


export type CreateScenarioMutation = (
  { __typename?: 'Mutation' }
  & { createScenario: (
    { __typename?: 'Scenario' }
    & ScenarioDataFragment
  ) }
);

export type CreateScenarioMemberMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: ICreateScenarioMembers;
}>;


export type CreateScenarioMemberMutation = (
  { __typename?: 'Mutation' }
  & { createScenarioMembers: (
    { __typename?: 'ScenarioMembers' }
    & Pick<ScenarioMembers, 'id'>
  ) }
);

export type RefreshScenarioDataMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}>;


export type RefreshScenarioDataMutation = (
  { __typename?: 'Mutation' }
  & { refreshScenarioData: (
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'id'>
  ) }
);

export type RefreshScenarioExchangeRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}>;


export type RefreshScenarioExchangeRateMutation = (
  { __typename?: 'Mutation' }
  & { refreshScenarioExchangeRate: (
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'id'>
  ) }
);

export type ScenarioMemberEntityDataFragment = (
  { __typename?: 'ScenarioMembersEntity' }
  & Pick<ScenarioMembersEntity, 'startDate' | 'endDate' | 'allocationPerCent' | 'actualAllocationPerCent' | 'allocationTimeAmountOrigin' | 'allocationTimeAmount' | 'costOrigin' | 'cost' | 'revenueOrigin' | 'revenue' | 'profit' | 'profitMargin' | 'id' | 'externalRateUnit' | 'externalRateAmount' | 'internalRateUnit' | 'internalRateAmount' | 'leavesHours' | 'leavesPercent' | 'commission' | 'workingHoursInMonth'>
);

export type ScenarioMembersDataFragment = (
  { __typename?: 'ScenarioMembers' }
  & Pick<ScenarioMembers, 'id' | 'allocationTimeAmount' | 'seniority'>
  & { member?: Maybe<(
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'job_title' | 'color'>
  )>, role: (
    { __typename?: 'Role' }
    & Pick<Role, 'name'>
  ), scenarioMemberEntity: Array<(
    { __typename?: 'ScenarioMembersEntity' }
    & ScenarioMemberEntityDataFragment
  )> }
);

export type ScenarioDetailsDataFragment = (
  { __typename?: 'Scenario' }
  & Pick<Scenario, 'id' | 'title' | 'exchangeRateAmount' | 'exchangeRateCurrency' | 'description' | 'leavesForecast' | 'bookedLeaves' | 'commissions'>
  & { project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'id' | 'name' | 'type' | 'fee_amount' | 'fee_currency'>
  ) }
);

export type ScenarioQueryVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  projectId: Scalars['ID'];
  pagination?: Maybe<IPagination>;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type ScenarioQuery = (
  { __typename?: 'Query' }
  & { scenario?: Maybe<(
    { __typename?: 'Scenario' }
    & { members: Array<(
      { __typename?: 'ScenarioMembers' }
      & ScenarioMembersDataFragment
    )> }
    & ScenarioDetailsDataFragment
  )> }
);

export type ScenarioForecastMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  data: IForecastSettings;
  scenarioId: Scalars['ID'];
}>;


export type ScenarioForecastMutation = (
  { __typename?: 'Mutation' }
  & { scenarioForecast: (
    { __typename?: 'Scenario' }
    & { members: Array<(
      { __typename?: 'ScenarioMembers' }
      & ScenarioMembersDataFragment
    )> }
    & ScenarioDetailsDataFragment
  ) }
);

export type ScenarioDataFragment = (
  { __typename?: 'Scenario' }
  & Pick<Scenario, 'id' | 'title' | 'created_at' | 'description' | 'activeMembers' | 'createdByMemberName' | 'createdByMemberColor' | 'archived_at'>
  & { project: (
    { __typename?: 'ProjectLite' }
    & Pick<ProjectLite, 'id' | 'name' | 'start_date' | 'end_date' | 'color' | 'type'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
    )> }
  ) }
);

export type ScenariosQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type ScenariosQuery = (
  { __typename?: 'Query' }
  & { scenarios: Array<(
    { __typename?: 'Scenario' }
    & ScenarioDataFragment
  )> }
);

export type EditScenarioMemberAllocationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioMembersEntityId: Scalars['ID'];
  data: IScenarioMembersEntity;
}>;


export type EditScenarioMemberAllocationMutation = (
  { __typename?: 'Mutation' }
  & { editScenarioMembersEntity: Array<(
    { __typename?: 'ScenarioMembersEntity' }
    & Pick<ScenarioMembersEntity, 'scenarioMembersId' | 'allocationPerCent' | 'actualAllocationPerCent' | 'allocationTimeAmount' | 'cost' | 'revenue' | 'id' | 'profit' | 'profitMargin' | 'commission'>
  )> }
);

export type EditScenarioMembersRevenueMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioMembersEntityId: Scalars['ID'];
  data: IScenarioMembersEntity;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type EditScenarioMembersRevenueMutation = (
  { __typename?: 'Mutation' }
  & { editScenarioMembersEntity: Array<(
    { __typename?: 'ScenarioMembersEntity' }
    & Pick<ScenarioMembersEntity, 'scenarioMembersId' | 'id' | 'revenue' | 'profit' | 'profitMargin' | 'externalRateUnit' | 'externalRateAmount' | 'commission'>
  )> }
);

export type EditScenarioMembersCostMutationVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioMembersEntityId: Scalars['ID'];
  data: IScenarioMembersEntity;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type EditScenarioMembersCostMutation = (
  { __typename?: 'Mutation' }
  & { editScenarioMembersEntity: Array<(
    { __typename?: 'ScenarioMembersEntity' }
    & Pick<ScenarioMembersEntity, 'scenarioMembersId' | 'id' | 'profit' | 'profitMargin' | 'cost' | 'internalRateUnit' | 'internalRateAmount'>
  )> }
);

export type DeleteScenarioMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}>;


export type DeleteScenarioMutation = (
  { __typename?: 'Mutation' }
  & { deleteScenario: (
    { __typename?: 'Scenario' }
    & Pick<Scenario, 'id'>
  ) }
);

export type DeleteScenarioMembersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioMembersId: Scalars['ID'];
}>;


export type DeleteScenarioMembersMutation = (
  { __typename?: 'Mutation' }
  & { deleteScenarioMembers: (
    { __typename?: 'ScenarioMembers' }
    & Pick<ScenarioMembers, 'id'>
  ) }
);

export type ArchiveScenarioMutationVariables = Exact<{
  action: ScenarioAction;
  companyId: Scalars['ID'];
  projectId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}>;


export type ArchiveScenarioMutation = (
  { __typename?: 'Mutation' }
  & { archiveScenario: (
    { __typename?: 'Scenario' }
    & ScenarioDataFragment
  ) }
);

export type DropdownProjectsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filterData?: Maybe<IFilterProjectData>;
  isArchived?: Maybe<Scalars['Boolean']>;
  actorFullAccess?: Maybe<Scalars['Boolean']>;
}>;


export type DropdownProjectsQuery = (
  { __typename?: 'Query' }
  & { managedProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { pm: (
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id'>
    ) }
  )> }
);

export type CreateScenarioMemberEntityMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICreateScenarioMemberEntity;
}>;


export type CreateScenarioMemberEntityMutation = (
  { __typename?: 'Mutation' }
  & { createScenarioMemberEntity: (
    { __typename?: 'ScenarioMembersEntity' }
    & ScenarioMemberEntityDataFragment
  ) }
);

export type ScenarioMembersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  paginationData?: Maybe<IPagination>;
  isActive?: Maybe<Scalars['Boolean']>;
  companyCurrency?: Maybe<Scalars['Boolean']>;
}>;


export type ScenarioMembersQuery = (
  { __typename?: 'Query' }
  & { scenarioMembers: Array<(
    { __typename?: 'ScenarioMembers' }
    & Pick<ScenarioMembers, 'id'>
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    ), member?: Maybe<(
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'avatar_url' | 'color'>
    )> }
  )> }
);

export type LeaveTypeDataFragment = (
  { __typename?: 'LeaveType' }
  & Pick<LeaveType, 'id' | 'name' | 'paid' | 'color'>
);

export type CreateLeaveTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ILeaveType;
}>;


export type CreateLeaveTypeMutation = (
  { __typename?: 'Mutation' }
  & { createLeaveType: (
    { __typename?: 'LeaveType' }
    & LeaveTypeDataFragment
  ) }
);

export type DeleteLeaveTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  leaveTypeId: Scalars['ID'];
}>;


export type DeleteLeaveTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteLeaveType: (
    { __typename?: 'LeaveType' }
    & Pick<LeaveType, 'id'>
  ) }
);

export type EditLeaveTypeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ILeaveType;
  leaveTypeId: Scalars['ID'];
}>;


export type EditLeaveTypeMutation = (
  { __typename?: 'Mutation' }
  & { editLeaveType: (
    { __typename?: 'LeaveType' }
    & LeaveTypeDataFragment
  ) }
);

export type SkillDataFragment = (
  { __typename?: 'Skill' }
  & Pick<Skill, 'id' | 'name' | 'skillCategoryId'>
);

export type CreateSkillMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ISkill;
}>;


export type CreateSkillMutation = (
  { __typename?: 'Mutation' }
  & { createSkill: (
    { __typename?: 'Skill' }
    & SkillDataFragment
  ) }
);

export type DeleteSkillMutationVariables = Exact<{
  companyId: Scalars['ID'];
  skillId: Scalars['ID'];
}>;


export type DeleteSkillMutation = (
  { __typename?: 'Mutation' }
  & { deleteSkill: (
    { __typename?: 'Skill' }
    & Pick<Skill, 'id'>
  ) }
);

export type EditSkillMutationVariables = Exact<{
  companyId: Scalars['ID'];
  skillId: Scalars['ID'];
  data: ISkill;
}>;


export type EditSkillMutation = (
  { __typename?: 'Mutation' }
  & { editSkill: (
    { __typename?: 'Skill' }
    & SkillDataFragment
  ) }
);

export type CompensationDataFragment = (
  { __typename?: 'Compensation' }
  & Pick<Compensation, 'amount' | 'assignmentId' | 'created_at' | 'currency' | 'currencyId' | 'date' | 'id' | 'notes' | 'type' | 'typeName'>
);

export type CompensationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type CompensationsQuery = (
  { __typename?: 'Query' }
  & { compensations: Array<(
    { __typename?: 'Compensation' }
    & CompensationDataFragment
  )> }
);

export type CreateCompensationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: ICompensation;
  memberId: Scalars['ID'];
}>;


export type CreateCompensationMutation = (
  { __typename?: 'Mutation' }
  & { createCompensation: (
    { __typename?: 'Compensation' }
    & CompensationDataFragment
  ) }
);

export type EditCompensationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  compensationId: Scalars['ID'];
  data: ICompensation;
}>;


export type EditCompensationMutation = (
  { __typename?: 'Mutation' }
  & { editCompensation: (
    { __typename?: 'Compensation' }
    & CompensationDataFragment
  ) }
);

export type DeleteCompensationMutationVariables = Exact<{
  companyId: Scalars['ID'];
  compensationId: Scalars['ID'];
}>;


export type DeleteCompensationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompensation: (
    { __typename?: 'Compensation' }
    & Pick<Compensation, 'id'>
  ) }
);

export type CostRateDataFragment = (
  { __typename?: 'CostRate' }
  & Pick<CostRate, 'id' | 'start_date' | 'end_date' | 'rate_amount' | 'rate_currency' | 'rateCurrencyId' | 'unit' | 'created_at'>
);

export type MemberCostRatesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type MemberCostRatesQuery = (
  { __typename?: 'Query' }
  & { member?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'reportingToId' | 'costRateManagersIds' | 'color'>
    & { company: (
      { __typename?: 'CompanyLite' }
      & Pick<CompanyLite, 'id' | 'primaryCurrencyId'>
    ), cost_rate?: Maybe<Array<(
      { __typename?: 'CostRate' }
      & CostRateDataFragment
    )>> }
  )> }
);

export type CreateCostRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  data: ICostRate;
}>;


export type CreateCostRateMutation = (
  { __typename?: 'Mutation' }
  & { createCostRate: (
    { __typename?: 'CostRate' }
    & Pick<CostRate, 'member_id'>
    & CostRateDataFragment
  ) }
);

export type EditCostRateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  costRateId: Scalars['ID'];
  data: ICostRate;
}>;


export type EditCostRateMutation = (
  { __typename?: 'Mutation' }
  & { editCostRate: (
    { __typename?: 'CostRate' }
    & CostRateDataFragment
  ) }
);

export type MemberProjectDataFragment = (
  { __typename?: 'Project' }
  & MakeOptional<Pick<Project, 'id' | 'name' | 'color' | 'type' | 'overtimeMultiplier'>, 'overtimeMultiplier'>
  & { pm: (
    { __typename?: 'MemberLite' }
    & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
  ) }
);

export type MemberProjectAssignmentDataFragment = (
  { __typename?: 'Assignment' }
  & Pick<Assignment, 'id' | 'projectId' | 'seniority' | 'startDate' | 'endDate' | 'allocationTimeAmount' | 'bill_amount_calculation_type' | 'billable'>
  & { member: (
    { __typename?: 'Member' }
    & Pick<Member, 'id'>
  ), project: (
    { __typename?: 'Project' }
    & MemberProjectDataFragment
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  ) }
);

export type MemberByIdQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  withAssignments?: Maybe<Scalars['Boolean']>;
  withOvertimeMultiplier?: Maybe<Scalars['Boolean']>;
}>;


export type MemberByIdQuery = (
  { __typename?: 'Query' }
  & { member?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'email' | 'employment_type' | 'job_title' | 'color' | 'status' | 'memberSpecialization' | 'memberSpecializationId' | 'costRateManagersIds' | 'reportingToId' | 'capacity' | 'join_date' | 'exit_date'>
    & { member_role: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>, vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & Pick<Vendor, 'id' | 'name'>
    )>, member_seniority?: Maybe<(
      { __typename?: 'MemberSeniority' }
      & Pick<MemberSeniority, 'id' | 'name'>
    )>, cost_rate?: Maybe<Array<(
      { __typename?: 'CostRate' }
      & Pick<CostRate, 'id' | 'start_date' | 'end_date'>
    )>>, costRateManagers: Array<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'first_name' | 'last_name'>
    )>, reportingTo?: Maybe<(
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
    )>, assignments?: Maybe<Array<(
      { __typename?: 'Assignment' }
      & MemberProjectAssignmentDataFragment
    )>>, leaves: Array<(
      { __typename?: 'Leave' }
      & Pick<Leave, 'id' | 'startDate' | 'endDate'>
      & { leaveType?: Maybe<(
        { __typename?: 'LeaveType' }
        & Pick<LeaveType, 'name'>
      )> }
    )>, skills: Array<(
      { __typename?: 'MemberSkill' }
      & Pick<MemberSkill, 'id' | 'level'>
      & { skill: (
        { __typename?: 'Skill' }
        & Pick<Skill, 'id' | 'skillCategoryName' | 'skillCategoryId' | 'name'>
      ) }
    )> }
  )> }
);

export type MemberEmployeeByIdQueryVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  withAssignments?: Maybe<Scalars['Boolean']>;
  withOvertimeMultiplier?: Maybe<Scalars['Boolean']>;
}>;


export type MemberEmployeeByIdQuery = (
  { __typename?: 'Query' }
  & { member?: Maybe<(
    { __typename?: 'Member' }
    & Pick<Member, 'id' | 'first_name' | 'last_name' | 'email' | 'employment_type' | 'color'>
    & { member_role: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>, reportingTo?: Maybe<(
      { __typename?: 'MemberLite' }
      & Pick<MemberLite, 'id' | 'first_name' | 'last_name' | 'color'>
    )>, assignments?: Maybe<Array<(
      { __typename?: 'Assignment' }
      & MemberProjectAssignmentDataFragment
    )>>, leaves: Array<(
      { __typename?: 'Leave' }
      & Pick<Leave, 'id' | 'startDate' | 'endDate'>
      & { leaveType?: Maybe<(
        { __typename?: 'LeaveType' }
        & Pick<LeaveType, 'name'>
      )> }
    )> }
  )> }
);

export type DeleteMemberSkillMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberId: Scalars['ID'];
  skillId: Scalars['ID'];
}>;


export type DeleteMemberSkillMutation = (
  { __typename?: 'Mutation' }
  & { deleteMemberSkill: (
    { __typename?: 'MemberSkill' }
    & Pick<MemberSkill, 'id'>
  ) }
);

export type EditMemberSkillMutationVariables = Exact<{
  companyId: Scalars['ID'];
  memberSkillId: Scalars['ID'];
  data: IEditMemberSkill;
}>;


export type EditMemberSkillMutation = (
  { __typename?: 'Mutation' }
  & { editMemberSkill: (
    { __typename?: 'MemberSkill' }
    & Pick<MemberSkill, 'level' | 'id'>
  ) }
);

export type EditMemberSkillsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  data: IMemberSkill;
}>;


export type EditMemberSkillsMutation = (
  { __typename?: 'Mutation' }
  & { editMemberSkills: Array<(
    { __typename?: 'MemberSkill' }
    & Pick<MemberSkill, 'id'>
  )> }
);
