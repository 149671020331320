import React, { FC, useMemo } from 'react';
import { Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  AbsoluteSpinner,
  EmptyState,
  NewProject,
  ProjectFormValues,
  RightDrawer,
  TabItem,
  Tabs,
  ViewHeading,
} from 'components';
import { links } from 'App';
import { useAuth } from 'contexts';
import { addTimezoneOffset, currencyToValue, graphqlOnError, valueToCurrency } from 'utils';
import { ActionsType, Client, Member, ProjectType, RateUnit } from 'generated/types';
import {
  ProjectMembershipsDocument,
  useEditProjectMutation,
  useExchangeRateQuery,
  useProjectByIdQuery,
} from 'generated/graphql';
import { useDeviceTypeByWidth, useErrorMsgBuilder, usePermissions, useRestoreProject } from 'hooks';
import { ProjectDetailRoute, projectLinks, SharedProjects, useRedirectWithoutPermissions } from 'views/ProjectDetail';
import { TimeTracking } from './TimeTracking';
import { Redirect } from 'react-router-dom';
import { GetRouteProps } from 'types/utils/router';
import { client } from 'graphql-client';

import { useSetAppTitle } from 'hooks/useSetAppTitle';
import { RateCard } from 'views/ProjectDetail/RateCard';
import { DrawerQueries, InsightsTabs } from 'types';
import { ActivityHistory } from '../ActivityHistory';
import { Assignments } from './Assignments';
import { Expenses } from './Expenses';
import { Scenarios } from './Scenarios';
import { Files } from './Files';
import { Billing } from './Billing';
import { LeavesBalance } from './LeavesBalance';
import { Milestones } from './Milestones';
import { HeaderButtons, Heading } from './components';

import styles from './ProjectDetail.module.scss';

type Props = GetRouteProps<typeof ProjectDetailRoute>;

export const ProjectDetail: FC<Props> = ({
  match: {
    params: { id },
    params,
    query: { mode, leaveRule },
    query,
  },
  history: { push },
  link,
}) => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { userData } = useAuth();
  const { hasAccess, isPermissionsLoading } = usePermissions({ projectId: id });
  const { startAccessDate, endAccessDate, loadingProjectMembership } = useRedirectWithoutPermissions(id, push);
  const { isMobileDevice } = useDeviceTypeByWidth();
  const { data: { project } = {}, loading } = useProjectByIdQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      projectId: id,
      withCommission: true,
    },
    skip: isPermissionsLoading,
  });
  useSetAppTitle(project && `${project?.name}`);

  const isFixedPriceProject = project?.type === ProjectType.FixedPrice;
  const isProjectCurrencySameAsCompany = useMemo(() => {
    if (!project?.feeCurrencyId) return false;

    return project?.feeCurrencyId === userData?.company.primaryCurrencyId;
  }, [project?.feeCurrencyId, userData?.company.primaryCurrencyId]);

  const { data: { exchangeRate } = {} } = useExchangeRateQuery({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    variables: {
      companyId: userData!.company.id,
      baseCurrencyId: userData!.company.primaryCurrencyId || '',
      exchangeCurrencyId: project?.feeCurrencyId || '',
    },
    skip: isProjectCurrencySameAsCompany || loading || !project?.feeCurrencyId,
  });

  const [editProject] = useEditProjectMutation({
    onCompleted() {
      toast.success(t('projects.projectEditSuccessfully'));
      handleDialogClose();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    update(_, { data }) {
      if (data?.editProject.pm.id !== project?.pm.id) {
        client.refetchQueries({ include: [ProjectMembershipsDocument] });
      }
    },
    refetchQueries: ['projectAssignmentsList', 'profitability'],
  });

  const handleRestoreProject = useRestoreProject(id);

  const handleDialogOpen = (
    mode:
      | DrawerQueries.rateCardMode
      | DrawerQueries.activityHistoryMode
      | DrawerQueries.leavesBalanceMode
      | DrawerQueries.editMode
      | DrawerQueries.shareProjectsMode,
  ) => push(link({ ...params, ...query, mode }));
  const handleDialogClose = () => push(link({ ...params, ...query, mode: undefined, leaveRule: undefined }));

  const updateProject = async ({
    type,
    fee_amount,
    unit,
    fee_currency,
    start_date,
    end_date,
    pm,
    client,
    pmFinAccess,
    pmFinAccessStart,
    pmFinAccessEnd,
    costBudgetAmount,
    billableLeaves,
    overtimeMultiplier,
    ...values
  }: ProjectFormValues) => {
    await editProject({
      variables: {
        data: {
          ...(type.id === ProjectType.FixedPrice
            ? {
                fee_amount: currencyToValue(fee_amount),
                feeCurrencyId: fee_currency.id,
              }
            : { costBudgetAmount: !!costBudgetAmount ? currencyToValue(costBudgetAmount) : null }),
          ...((type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && {
            feeCurrencyId: fee_currency.id,
          }),
          ...values,
          client: client?.id ?? '',
          unit: type.id !== ProjectType.TimeAndMaterial && type.id !== ProjectType.Retainer ? RateUnit.Hour : unit.id,
          pm: pm?.id ?? '',
          type: type.id,
          start_date: addTimezoneOffset(new Date(start_date)),
          end_date: addTimezoneOffset(new Date(end_date)),
          pmFinAccess: pmFinAccess.id,
          pmAccessStart: pmFinAccessStart ? addTimezoneOffset(new Date(pmFinAccessStart)) : null,
          pmAccessEnd: pmFinAccessEnd ? addTimezoneOffset(new Date(pmFinAccessEnd)) : null,
          overtimeMultiplier:
            (type.id === ProjectType.TimeAndMaterial || type.id === ProjectType.Retainer) && overtimeMultiplier
              ? Number(overtimeMultiplier)
              : undefined,
          billableLeaves:
            type.id === ProjectType.Retainer || type.id === ProjectType.TimeAndMaterial ? billableLeaves : false,
        },
        projectId: id,
        companyId: userData!.company.id,
      },
    });
  };

  if (loading || isPermissionsLoading || loadingProjectMembership) {
    return <AbsoluteSpinner />;
  }

  if (!project) {
    return <EmptyState />;
  }

  return (
    <>
      <ViewHeading
        headingClassName={styles.heading}
        className={styles.headingBox}
        label={<Heading project={project} />}
      >
        <HeaderButtons
          project={project}
          projectId={id}
          handleDialogOpen={handleDialogOpen}
          handleRestoreProject={handleRestoreProject}
          onInsightsRedirect={() => push(links.Insights({ tab: InsightsTabs.projects, projectId: id }))}
        />
      </ViewHeading>

      <Tabs className="tabs-box">
        {isFixedPriceProject && hasAccess(ActionsType.ProjectMilestones) && (
          <TabItem route={projectLinks.Milestones({ id })}>{t('viewProjectDetail.milestones.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewProjectAssignments) && (
          <TabItem route={projectLinks.Assignments({ projectId: id })}>
            {t('viewProjectDetail.assignments.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewExpenses) && (
          <TabItem route={projectLinks.Expenses({ id })}>{t('viewProjectDetail.expenses.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewTimelogs) && (
          <TabItem exact={false} route={projectLinks.TimeTracking({ id })}>
            {t('timeTracking.label')}
          </TabItem>
        )}
        {hasAccess(ActionsType.ViewDocuments) && (
          <TabItem route={projectLinks.Files({ id })}>{t('projectFile.label')}</TabItem>
        )}
        {hasAccess(ActionsType.BillingReport) && (
          <TabItem route={projectLinks.Billing({ id })}>{t('billing.label')}</TabItem>
        )}
        {hasAccess(ActionsType.ViewScenarios) && !isMobileDevice && (
          <TabItem route={projectLinks.Scenarios({ id })}>{t('scenarios.title')}</TabItem>
        )}
      </Tabs>

      <Switch>
        <Milestones
          project={project}
          isProjectCurrencySameAsCompany={isProjectCurrencySameAsCompany}
          exchangeRate={exchangeRate}
        />
        <Assignments
          startAccessDate={startAccessDate}
          endAccessDate={endAccessDate}
          exact
          project={project}
          isProjectCurrencySameAsCompany={isProjectCurrencySameAsCompany}
          exchangeRate={exchangeRate}
        />
        <Expenses exact project={project} />
        <TimeTracking />
        <Files />
        <Scenarios project={project} />
        <Billing project={project} />
        <Redirect
          to={
            isFixedPriceProject && hasAccess(ActionsType.ProjectMilestones)
              ? projectLinks.Milestones({ id })
              : projectLinks.Assignments({ projectId: id })
          }
        />
      </Switch>

      <RightDrawer
        direction="right"
        open={mode === DrawerQueries.editMode}
        onClose={handleDialogClose}
        title={t('projects.editProject')}
        paperClassName={styles.editProjectPaper}
      >
        <NewProject
          id={project.id}
          client={project.client as Client}
          name={project.name}
          type={project.type}
          pm={project.pm as Member}
          unit={project?.unit}
          fee_amount={valueToCurrency(Number(project.fee_amount))}
          feeCurrencyId={project.feeCurrencyId}
          start_date={project.start_date}
          end_date={project.end_date}
          color={project.color}
          pmFinAccessId={project.pmFinAccess?.accessLevel}
          pmFinAccessStart={project.pmFinAccess?.startDate}
          pmFinAccessEnd={project.pmFinAccess?.endDate}
          commission={project.currentCommission}
          overtimeMultiplier={project.overtimeMultiplier}
          billableLeaves={project.billableLeaves}
          onSubmit={updateProject}
          onCancel={handleDialogClose}
          submitLabel={t('actions.save')}
          costBudgetAmount={project.cost_budget_amount && valueToCurrency(project.cost_budget_amount)}
        />
      </RightDrawer>
      <RateCard isOpen={mode === DrawerQueries.rateCardMode} onClose={handleDialogClose} project={project} />
      <LeavesBalance
        isOpen={mode === DrawerQueries.leavesBalanceMode || !!leaveRule}
        onClose={handleDialogClose}
        projectId={id}
        defaultLeaveRule={leaveRule}
      />
      <ActivityHistory
        isOpen={mode === DrawerQueries.activityHistoryMode}
        onClose={handleDialogClose}
        projectId={project.id}
        currencyCode={project.fee_currency || ''}
        isShowMemberField
      />
      <SharedProjects
        isOpen={mode === DrawerQueries.shareProjectsMode}
        onClose={handleDialogClose}
        projectId={project.id}
        pmId={project.pm.id}
        isNonBillableProject={project.type === ProjectType.NonBillable}
      />
    </>
  );
};
