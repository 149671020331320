import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'utils';
import { DashIcon } from 'icons';
import { MemberSeniority, RateUnit, Role } from 'generated/types';
import { Autocomplete, DatePicker, LoadingButton, NumberTextField, Portal } from 'components/index';
import { Button, InputAdornment } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';

import styles from './styles.module.scss';

export interface FormValues {
  unit: Unit;
  amount: number | null;
  start_date: Date | string;
  end_date?: Date | string | null;
  new_start_date?: Date | string;
}

interface Unit {
  id: RateUnit;
  name: string;
}

interface Props {
  role: Pick<Role, 'id' | 'name'> | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  currencyCode?: string;
  initialValue?: FormValues;
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  loading?: boolean;
}

export const AssignmentRateForm: FC<Props> = ({
  initialValue,
  onSubmit,
  onCancel,
  currencyCode,
  seniority,
  role,
  loading,
}) => {
  const { t } = useTranslation();
  const unitOptions = useMemo(
    () => [
      { id: RateUnit.Hour, name: t('rateCard.unit.hour') },
      { id: RateUnit.Day, name: t('rateCard.unit.day') },
      { id: RateUnit.Month, name: t('rateCard.unit.month') },
    ],
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      amount: Yup.number()
        .typeError(t('rateCard.form.costDecimal'))
        .test('maxDigitsAfterDecimal', t('rateCard.form.costDecimal'), (number) =>
          /^\d+(\.\d{1,2})?$/.test(`${number}`),
        )
        .test('maxDigits', t('rateCard.form.maxDigits'), (number) => /^\d{1,6}(\.?)+(\.\d{1,2})?$/.test(`${number}`))
        .positive(t('rateCard.form.costPositiveError')),
      unit: Yup.object().nullable().required(t('rateCard.form.unitRequiredError')),
      start_date: Yup.string().nullable().required(t('rateCard.form.startDateRequired')),
      end_date: Yup.string().nullable(),
      new_start_date: Yup.string().nullable(),
    });
  }, []);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    setValues,
    setSubmitting,
    touched,
    errors,
    submitCount,
  } = useFormik<FormValues>({
    initialValues: initialValue || {
      unit: { id: RateUnit.Hour, name: t(`rateCard.unit.${RateUnit.Hour}`) },
      amount: null,
      start_date: '',
      end_date: null,
      new_start_date: '',
    },
    validationSchema,
    onSubmit,
    validateOnChange: true,
  });

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
        setSubmitting(false);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.twoItemsBox}>
        <div className="flex-1">
          <InputLabel required>{t('rateCard.form.role')}</InputLabel>
          <Autocomplete className="w-100" value={role} options={[]} disabled />
        </div>
        <div className="flex-1">
          <InputLabel>{t('rateCard.form.seniority')}</InputLabel>
          <Autocomplete
            className="w-100"
            placeholder={t('rateCard.form.seniority')}
            value={seniority}
            options={[]}
            disabled
          />
        </div>
      </div>

      <div className={styles.twoItemsBox}>
        <div className="flex-1">
          <InputLabel required>{t('rateCard.form.amount')}</InputLabel>
          <NumberTextField
            name="amount"
            placeholder="0"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount?.toString()}
            error={Boolean((submitCount || touched.amount) && errors.amount)}
            helperText={Boolean(submitCount || touched.amount) ? errors.amount : ''}
            InputProps={{
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currencyCode)}</InputAdornment>,
            }}
          />
        </div>
        <div className="flex-1">
          <InputLabel required>{t('rateCard.form.unit')}</InputLabel>
          <Autocomplete
            name="unit"
            onBlur={handleBlur}
            getOptionLabel={({ name }: Unit) => name}
            value={values.unit}
            disableClearable
            options={unitOptions}
            onChange={(unit: FormValues['unit']) => setValues({ ...values, unit })}
          />
        </div>
      </div>

      <div className="flex mb-24">
        <div className="flex-1">
          <InputLabel required>{t('rateCard.form.startDate')}</InputLabel>
          <DatePicker<false>
            placeholder={t('rateCard.form.startDate')}
            name="start_date"
            value={values.start_date}
            error={Boolean((submitCount || touched.start_date) && errors.start_date)}
            helperText={Boolean(submitCount || touched.start_date) && errors.start_date}
            onChange={(start_date?: FormValues['start_date'] | null) =>
              setValues({ ...values, start_date: start_date || '' })
            }
            popperProps={{ strategy: 'fixed' }}
          />
        </div>
        <div className={styles.datePickersDash}>
          <DashIcon />
        </div>
        <div className="flex-1">
          <InputLabel>{t('rateCard.form.endDate')}</InputLabel>
          <DatePicker<false>
            placeholder={t('rateCard.form.endDate')}
            name="end_date"
            value={values.end_date}
            error={Boolean((submitCount || touched.end_date) && errors.end_date)}
            helperText={Boolean(submitCount || touched.end_date) && errors.end_date}
            onChange={(end_date: FormValues['end_date']) => setValues({ ...values, end_date })}
            popperProps={{ strategy: 'fixed' }}
          />
        </div>
      </div>

      <Portal wrapperId="dialog-actions">
        <Button variant="text" color="secondary" onClick={onCancel}>
          {t('forms.cancel')}
        </Button>
        <LoadingButton onClick={() => handleSubmit()} loading={loading}>
          {t('forms.create')}
        </LoadingButton>
      </Portal>
    </form>
  );
};
