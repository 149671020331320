import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addTimezoneOffset, currencyToValue } from 'utils';
import { useAuth } from 'contexts';
import { useCreateExternalRate, useIsOpen } from 'hooks';
import { format, parseISO } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'consts';
import { ExternalRateDataFragment, MemberSeniority, RateUnit, Role } from 'generated/types';
import { ConfirmModal, DialogWrapper } from 'components/index';
import { AssignmentRateForm, FormValues } from '../AssignmentRateForm';
import { isOverlapped } from 'views/ProjectDetail/RateCard/helpers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  rates: ExternalRateDataFragment[];
  projectId: string;
  role: Pick<Role, 'id' | 'name'> | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  currency: string;
  start_date?: Date;
  end_date?: Date;
  unit?: RateUnit;
}

export const CreateAssignmentRateModal: FC<Props> = ({
  isOpen,
  onClose,
  rates,
  projectId,
  role,
  seniority,
  currency,
  start_date,
  end_date,
  unit = RateUnit.Hour,
}) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = useIsOpen(false);
  const [rate, setRate] = useState<FormValues>();

  const { createExternalRate, loading } = useCreateExternalRate({
    onCompleted: onClose,
    projectId,
    companyId: userData!.company.id,
  });

  const onCreate = useCallback(async (values?: FormValues) => {
    const data = rate ?? values;
    if (!data) return;

    const { start_date, end_date, unit, amount } = data;
    await createExternalRate({
      variables: {
        companyId: userData!.company.id,
        projectId: projectId,
        roleId: role?.id || '',
        seniorityId: seniority?.id || null,
        data: {
          start_date: format(new Date(start_date), DEFAULT_DATE_FORMAT),
          end_date: end_date ? format(new Date(end_date), DEFAULT_DATE_FORMAT) : '',
          rate_amount: Math.round(currencyToValue(amount || 0)),
          unit: unit.id,
        },
      },
    });
  }, []);

  const onSubmit = (values: FormValues) => {
    setRate(values);
    const { start_date, end_date } = values;

    const typedStart: Date =
      typeof start_date === 'string' ? addTimezoneOffset(parseISO(start_date)) : addTimezoneOffset(start_date as Date);

    const typedEnd: Date | undefined =
      typeof end_date === 'string' ? parseISO(end_date) : !!end_date ? (end_date as Date) : undefined;

    const confirmIsNeeded = isOverlapped(typedStart, rates ?? [], typedEnd);

    confirmIsNeeded ? onOpenConfirm() : onCreate(values);
  };

  return (
    <>
      <DialogWrapper
        className={isOpenConfirm ? 'd-none' : ''}
        open={isOpen}
        onClose={onClose}
        title={t('rateCard.form.createNewRate')}
      >
        <AssignmentRateForm
          onSubmit={onSubmit}
          onCancel={onClose}
          loading={loading}
          currencyCode={currency}
          seniority={seniority}
          role={role}
          initialValue={{
            unit: { id: unit, name: t(`rateCard.unit.${unit}`) },
            amount: 0,
            start_date: start_date || new Date(),
            end_date,
          }}
        />
      </DialogWrapper>

      <ConfirmModal
        title={t('financial.headsUp')}
        isOpen={isOpenConfirm}
        onSubmit={() => {
          onCreate();
          onCloseConfirm();
        }}
        onClose={() => onCloseConfirm()}
        submitButtonTitle={t('actions.create')}
      >
        {t('financial.confirmMessage')}
      </ConfirmModal>
    </>
  );
};
