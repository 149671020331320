import { ExternalRatesDocument, useCreateExternalRateMutation } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { graphqlOnError } from 'utils';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import mixpanel from 'mixpanel-browser';

interface Props {
  onCompleted: () => void;
  companyId: string;
  projectId: string;
}

export const useCreateExternalRate = ({ onCompleted, projectId, companyId }: Props) => {
  const tls = useErrorMsgBuilder();
  const { t } = useTranslation();

  const [createExternalRate, { loading }] = useCreateExternalRateMutation({
    onCompleted(data) {
      toast.success(t('rateCard.rate.createSuccessfully'));
      mixpanel.track('Rate created', {
        Unit: data.createExternalRate.unit,
        'Seniority set': !!data.createExternalRate.seniorityId,
        'End date set': !!data.createExternalRate.end_date,
      });
      onCompleted();
    },
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    refetchQueries: [
      {
        query: ExternalRatesDocument,
        variables: {
          companyId,
          projectId,
          isPast: true,
        },
      },
      'projectAssignmentsList',
      'profitability',
    ],
  });

  return { createExternalRate, loading };
};
